import * as UpdateBaseActions from '../actions/update.base.actions';

const initialState = {
	data: {},
	loading: false
};

const updateBase = (state = initialState, action) => {
	switch (action.type) {
		case UpdateBaseActions.GET_DRUG:
			return { ...state, loading: true };
		case UpdateBaseActions.GET_DRUG_SUCCESS:
			return {
				...initialState,
				data: {
					drugId: action.payload.data.drugId,
					drugCode: action.payload.data.drugCode,
					genericCode: action.payload.data.genericCode || '',
					drugName: action.payload.data.drugName,
					imageId: action.payload.data.imageId || '', // prevent warning when there's no drug image
					imageName: action.payload.data.imageName || '',
					supplier: action.payload.data.supplier || '',
					strength: action.payload.data.strength || '',
					description: action.payload.data.description || '',
					type: action.payload.data.type || '',
					genericName: action.payload.data.genericName || '',
					altBrand: action.payload.data.altBrand || '',
					schedule: action.payload.data.schedule || '',
					isCytotoxic: action.payload.data.isCytotoxic.toString(),
					packSize: action.payload.data.packSize || '',
					shelfLife: action.payload.data.shelfLife || '',
					drugGroupId: action.payload.data.drugGroup?.drugGroupId,
					authorized: action.payload.data.authorized || '',
					usePrintingDrugName: action.payload.data.usePrintingDrugName || '',
					printingDrugName: action.payload.data.printingDrugName || '',
					presentation: action.payload.data.presentation || '',
					warning: action.payload.data.warning || '',
					direction: action.payload.data.direction || '',
					preferredBrand: action.payload.data.preferredBrand
				}
			};
		case UpdateBaseActions.GET_DRUG_FAIL:
			return { ...state, loading: false };
		case UpdateBaseActions.CLEAR_DRUG_DATA:
			return initialState;
		case UpdateBaseActions.UPDATE_BASE_ID:
			return {
				...state,
				data: {
					...state.data,
					drugId: action.payload.data
				}
			};
		default:
			return state;
	}
};

export default updateBase;
