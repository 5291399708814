import * as MedicationHistoryActions from '../actions/update.medicationHistory.actions';

const defaultFilter = {
	startDate: '',
	endDate: '',
	drugName: '',
	genericName: ''
};

const defaultDetail = {
	oldValue: '',
	newValue: ''
};

const initialState = {
	data: [],
	error: null,
	filter: defaultFilter,
	loading: false,
	message: '',
	detail: defaultDetail,
	fileName: '',
	fileUrl: null
};

const medicationHistory = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case MedicationHistoryActions.SET_MEDICATION_HISTORY_FILTER:
			return { ...state, filter: payload.filter };
		case MedicationHistoryActions.GET_MEDICATION_HISTORIES:
			return { ...state, loading: true };
		case MedicationHistoryActions.GET_MEDICATION_HISTORIES_SUCCESS:
			return { ...state, loading: false, data: payload.data };
		case MedicationHistoryActions.GET_MEDICATION_HISTORIES_FAIL:
			return { ...state, loading: false, message: payload.message, error: payload.error };
		case MedicationHistoryActions.GET_MEDICATION_HISTORY_DETAIL:
			return { ...state, loading: true };
		case MedicationHistoryActions.GET_MEDICATION_HISTORY_DETAIL_SUCCESS:
			return { ...state, loading: false, detail: payload.detail };
		case MedicationHistoryActions.GET_MEDICATION_HISTORY_DETAIL_FAIL:
			return { ...state, loading: false, message: payload.message, error: payload.error };
		case MedicationHistoryActions.CLEAR_HISTORY_DETAIL:
			return { ...state, detail: defaultDetail };
		case MedicationHistoryActions.CLEAR_HISTORY_FILTER:
			return { ...state, filter: defaultFilter };
		case MedicationHistoryActions.PRINT_HISTORY_REPORT:
			return { ...state, loading: true, error: null, message: '', fileName: '', fileUrl: null };
		case MedicationHistoryActions.PRINT_HISTORY_REPORT_SUCCESS:
			return { ...state, loading: false, fileName: payload.fileName, fileUrl: payload.fileUrl };
		case MedicationHistoryActions.PRINT_HISTORY_REPORT_FAIL:
			return { ...state, loading: false, message: payload.message, error: payload.error };
		case MedicationHistoryActions.CLEAR_HISTORY_FILE:
			return { ...state, fileName: '', fileUrl: null };
		default:
			return state;
	}
};

export default medicationHistory;
