import * as Actions from '../actions';

const initialState = {
	success: false,
	error: null,
	loggingIn: false,
	gettingProfile: false
};

const login = (state = initialState, action) => {
	switch (action.type) {
		case Actions.LOGIN_START: {
			return {
				...initialState,
				loggingIn: true
			};
		}
		case Actions.LOGIN_END: {
			return {
				...state,
				loggingIn: false
			};
		}
		case Actions.LOGIN_SUCCESS: {
			return {
				...state,
				success: true
			};
		}
		case Actions.LOGIN_ERROR: {
			return {
				...state,
				success: false,
				error: action.payload
			};
		}
		case Actions.GET_PROFILE:
			return {
				...state,
				gettingProfile: true
			};
		case Actions.GET_PROFILE_SUCCESS:
			return {
				...state,
				gettingProfile: false
			};
		case Actions.GET_PROFILE_FAIL:
			return {
				...state,
				gettingProfile: false,
				error: action.payload
			};
		default: {
			return state;
		}
	}
};

export default login;
