import { combineReducers } from 'redux';
import list from './list.reducer';
import create from './create.reducer';
import update from './update.reducer';
import migrate from './migrate.reducers';
import printerSetting from './printersetting.reducer';
import configuration from './configuration.reducers';

const pharmacyReducers = combineReducers({
  list,
  create,
  update,
  migrate,
  printerSetting,
  configuration
});

export default pharmacyReducers;
