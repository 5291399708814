import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import FuseUtils from '@fuse/utils';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import * as Actions from 'app/store/actions';
import { PERMISSION } from 'app/auth';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseNavBadge from '../FuseNavBadge';

const useStyles = makeStyles((theme) => ({
  item: () => ({
    height: 50,
    width: 'calc(100% - 16px)',
    borderRadius: '25px',
    // paddingRight: 12,
    // paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
    // width: 50,
    // borderRadius: '50%',
    padding: 15,
    marginLeft: 6,
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      color: `${theme.palette.primary.contrastText}!important`,
      transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
      '& .list-item-text-primary': {
        color: 'inherit',
      },
      '& .list-item-icon': {
        color: 'inherit',
      },
    },
    '& .list-item-icon': {
      marginRight: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    '& .list-item-text': {
      color: '#ffffff',
    },
    '& .syncStatusContainer': {
      '& .syncStatus': {
        left: 0,
        height: 0,
        width: 0,
        border: '4px solid',
        borderRadius: 4,
      },
    },
    color: theme.palette.text.primary,
    cursor: 'pointer',
    textDecoration: 'none!important',
  }),
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    backgroundColor: theme.palette.primary.main,
    border: 0,
    marginLeft: theme.spacing(2),
  },
  popoverText: {},
}));

function FuseNavVerticalItem(props) {
	const dispatch = useDispatch();
	const { role, permission } = useSelector(({ auth }) => auth.user);

  const { item, nestedLevel, isActive } = props;
  const classes = useStyles({
    itemPadding: nestedLevel > 0 ? 40 + nestedLevel * 16 : 21,
  });
  const { t } = useTranslation('navigation');
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const onMouseEnter = e => setAnchorEl(e.currentTarget);
  // const onMouseLeave = e => setAnchorEl(null);
  // const open = Boolean(anchorEl);

	if (!FuseUtils.hasPermission(item.auth, role, item.id, PERMISSION.READ, permission)) {
		return null;
	}

	return (
		<ListItem
			button
			component={NavLinkAdapter}
			to={item.url}
			activeClassName="active"
			className={clsx(classes.item, 'list-item')}
			onClick={() => dispatch(Actions.navbarCloseMobile())}
			exact={item.exact}
			// onMouseEnter={onMouseEnter}
			// onMouseLeave={onMouseLeave}
		>
			{item.icon && typeof item.icon === 'string' && (
				<Icon className="list-item-icon text-17 flex-shrink-0" color="action">
					{item.icon}
				</Icon>
			)}

      {item.icon && typeof item.icon !== 'string' && <div className="list-item-icon">{item.icon}</div>}
      <div className="flex flex-row items-center justify-center gap-8">
        <ListItemText
          className={clsx('list-item-text')}
          primary={item.translate ? t(item.translate) : item.title}
          classes={{ primary: 'text-15 list-item-text-primary' }}
        />
        {/* {(item.id === 'noSync' || item.id === 'sync') && isActive && (
          <div className={clsx('flex-1 items-center syncStatusContainer')}>
            <div className={clsx('syncStatus')} style={{ color: `${item.id === 'sync' ? '#92c353' : 'red'}` }} />
          </div>
        )} */}
      </div>

			{/* <Popover
				open={open}
				anchorEl={anchorEl}
				onClose={onMouseLeave}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				className={classes.popover}
				disableRestoreFocus
				classes={{
					paper: classes.popoverPaper
				}}
			>
				<Typography className={classes.popoverText}>{item.translate ? t(item.translate) : item.title}</Typography>
			</Popover> */}

			{item.badge && <FuseNavBadge badge={item.badge} />}
		</ListItem>
	);
}

FuseNavVerticalItem.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string,
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
		url: PropTypes.string
	})
};

FuseNavVerticalItem.defaultProps = {};

const NavVerticalItem = withRouter(React.memo(FuseNavVerticalItem));

export default NavVerticalItem;
