import * as PharmacyUpdateActions from '../actions/update.detail.actions';

const initialState = {
	id: '',
	data: {
		pharmacyCode: '',
		name: '',
		phoneNumber: [],
		fax: [],
		email: [],
		pharmacyGuid: '',
		approvalNumber: '',
		street: '',
		suburb: '',
		state: '',
		postalCode: '',
		isDoseAdd: 'true',
		timezone: 'AUS Eastern Standard Time',
		isIntegrator: 'false',
		integratorGuids: [],
		logoId: null
	},
	loading: false,
	submitSuccess: false,
	error: null,
	message: '',
	resendEmailLoading: false,
	requestSyncLoading: false
};

const detail = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case PharmacyUpdateActions.GET_PHARMACY_DETAIL:
			return {
				...state,
				loading: true
			};
		case PharmacyUpdateActions.GET_PHARMACY_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload.data,
				id: payload.id
			};
		case PharmacyUpdateActions.GET_PHARMACY_DETAIL_FAIL:
			return {
				...state,
				loading: false,
				message: payload.message,
				error: payload.error
			};
		case PharmacyUpdateActions.UPDATE_PHARMACY:
			return {
				...state,
				loading: true,
				submitSuccess: false,
				error: null,
				message: ''
			};
		case PharmacyUpdateActions.UPDATE_PHARMACY_SUCCESS:
			return {
				...state,
				data: payload.data,
				loading: false,
				submitSuccess: true
			};
		case PharmacyUpdateActions.UPDATE_PHARMACY_FAIL:
			return {
				...state,
				loading: false,
				message: payload.message,
				error: payload.error
			};
		case PharmacyUpdateActions.CHANGE_PHARMACY_UPDATE_FORM:
			return {
				...state,
				data: {
					...state.data,
					...payload.data
				}
			};
		case PharmacyUpdateActions.RESEND_EMAIL_START:
			return {
				...state,
				resendEmailLoading: true
			};
		case PharmacyUpdateActions.RESEND_EMAIL_DONE:
			return {
				...state,
				resendEmailLoading: false
			};
		case PharmacyUpdateActions.DELETE_PHARMACY:
			return { ...state, loading: true, error: null, message: '' };
		case PharmacyUpdateActions.DELETE_PHARMACY_SUCCESS:
			return { ...state, loading: false };
		case PharmacyUpdateActions.DELETE_PHARMACY_FAIL:
			return { ...state, loading: false, error: payload.error, message: payload.message };
		case PharmacyUpdateActions.CLEAR_UPDATE_DETAIL_FORM:
			return initialState;
		default:
			return state;
	}
};

export default detail;
