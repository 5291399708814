import * as UpdateBaseActions from '../actions/update.base.actions';
import * as UpdateDetailsActions from '../actions/update.details.actions';

const initialState = {
	data: {},
	__loading: false,
	checking: false
};

const updateBase = (state = initialState, action) => {
  switch (action.type) {
    case UpdateBaseActions.GET_PATIENT_DETAIL:
      return { ...state, __loading: true };
    case UpdateBaseActions.GET_PATIENT_DETAIL_SUCCESS:
    case UpdateDetailsActions.UPDATE_PATIENT_DETAILS_SUCCESS:
      return {
        ...initialState,
        data: {
          patientId: action.payload.data.patientId || state.data.patientId,
          patientGroupId: action.payload.data.patientGroupId,
          area: action.payload.data.area,
          facilityWard: action.payload.data.facilityWard,
          room: action.payload.data.room,
          urn: action.payload.data.urn,
          ihi: action.payload.data.ihi,
          photoTakenDate: action.payload.data.photoTakenDate,
          suspendFromDate: action.payload.data.suspendFromDate,
          suspendToDate: action.payload.data.suspendToDate,
          medicareNumber: action.payload.data.medicareNumber,
          dvaNum: action.payload.data.dvaNum,
          diagnosis: action.payload.data.diagnosis,
          gender: action.payload.data.gender,
          daaFunding: action.payload.data.daaFunding,
          isAnySimilarNamePatient: action.payload.data.isAnySimilarNamePatient,
        },
      };
    case UpdateBaseActions.GET_PATIENT_DETAIL_FAIL:
      return { ...state, __loading: false };
    // case UpdateBaseActions.CHECK_PROFILE_START:
    // 	return { ...state, checking: true };
    // case UpdateBaseActions.CHECK_PROFILE_SUCCESS:
    // 	return { ...state, checking: false };
    // case UpdateBaseActions.CHECK_PROFILE_FAILED:
    // 	return { ...state, checking: false, error: action.payload.error, message: action.payload.message };
    case UpdateBaseActions.CLEAR_PATIENT_DATA:
      return initialState;
    default:
      return state;
  }
};

export default updateBase;
