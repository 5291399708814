import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	container: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		background: 'rgba(232,232,232,1.0)',
		zIndex: 199,
		opacity: '0.6'
	},
	progress: {
		position: 'absolute',
		top: '50%',
		right: '50%'
	}
});

const OverlayLoading = () => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<CircularProgress color="secondary" className={classes.progress} />
		</div>
	);
};

export default OverlayLoading;
