import React from 'react';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import { map } from 'lodash';

export default function renderCacheRoutes(routes) {
	return (
		<CacheSwitch>
			{map(routes, route => (
				<CacheRoute {...route} />
			))}
		</CacheSwitch>
	);
}
