import split from 'lodash/split';

const parseFractionNumber = number => {
	const splitNumber = split(number, '/');

	if (splitNumber.length < 2) return number;
	if (Number(splitNumber[1]) === 0) return NaN;
	return (Number(splitNumber[0]) / Number(splitNumber[1])).toFixed(2);
};

const formatInteger = number => number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

// eslint-disable-next-line import/prefer-default-export
export { parseFractionNumber, formatInteger };
