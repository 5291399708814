const locale = {
  LIST_TITLE: 'Virtual Pill Balance Management',
  SEARCH_PLACEHOLDER: 'Search virtual pill balance...',
  TABLE_FIRST_NAME: 'First Name',
  TABLE_LAST_NAME: 'Last Name',
  TABLE_MEDICATION: 'Medication',
  TABLE_CURRENT_BALANCE: 'Current Balance',
  TABLE_TABLET_PACKED_WEEK: '<p>Required Balance</p><p>Per Week</p>',
  TABLE_HISTORY: 'History',
  TABLE_LAST_DISPENCE: 'Last Dispense Import',
  TABLE_DRUG_STATUS: 'Drug Status',
  BUTTON_HISTORY: 'Log',
  BUTTON_PRINT: 'Print',
  BUTTON_INITIAL_PILL_BALANCE: 'Initial Pill Balance',
  FILTER_FORECAST_REPORT: 'Dispense Forecast',
  FILTER_PACK_CYCLE: 'Pack Cycle',
  FILTER_PACK_START_DATE: 'Pack Start Date{{required}}',
  FILTER_NEGATIVE_ONLY: 'Negative Balance Only',
  DISPLAY_INSUFFICIENT_PILL_BALANCE: 'Display Insufficient Balance Alert',
  UPDATE_INSUFFICIENT_PILL_BALANCE_SUCCESS: 'Display Insufficient Balance has been updated',
  VPB_TOOLTIP_INSUFFICENT_BALANCE: 'Insufficient Balance',
  FILTER_DATE_FROM: 'From',
  FILTER_DATE_TO: 'To',
  BUTTON_MANUAL_ADJUST: 'Adjust Balance',
  BUTTON_SEARCH: 'Search',
  BUTTON_RETURN: 'Return to Virtual Pill Balance',
  CURRENT_BALANCE: 'Current balance',
  PATIENT: 'Patient',
  DRUG: 'Drug',
  HISTORY_BUTTON_REVERT: 'Revert',
  HISTORY_TABLE_REVERT: 'Revert',
  HISTORY_TABLE_RECORD_DATE: 'Date',
  HISTORY_TABLE_ACTION: 'Action',
  HISTORY_TABLE_QTY: 'Qty',
  HISTORY_TABLE_BALANCE: 'Balance',
  HISTORY_TABLE_NOTE: 'Note',
  HISTORY_TABLE_USER: 'User',
  HISTORY_TITLE: 'Virtual Pill Balance History',
  HISTORY_REVERT_MESSAGE_SUCCESS: 'Virtual Pill Balance has been successfully reverted',
  HISTORY_REVERT_CONFIRM_TITLE: 'Revert history',
  HISTORY_REVERT_CONFIRM_MESSAGE: 'Are you sure you want to revert this transaction?',
  MANUAL_ADJUST_MODAL_TITLE: 'Adjust Balance',
  MANUAL_ADJUST_MODAL_DATE: 'Date',
  MANUAL_ADJUST_MODAL_ADJUST_TYPE: 'Adjust Type',
  MANUAL_ADJUST_MODAL_QTY: 'Qty',
  MANUAL_ADJUST_MODAL_NOTE: 'Note',
  MANUAL_ADJUST_MODAL_SCRIPT_NUM: 'Script #',
  MANUAL_ADJUST_MODAL_MESSAGE_SUCCESS: 'Balance has been adjusted successfully',
  VALIDATE_POSITIVE: 'Must contain numbers',
  BUTTON_IMPORT: 'Import Balance',
  BUTTON_EXPORT: 'Export Balance',
  IMPORT_CSV_MESSAGE_SUCCESS: 'VPB file has been imported successfully',
  BALANCE_REPORT: 'Balance Report',
  BALANCE_REPORT_FROM: 'From',
  BALANCE_REPORT_TO: 'To',
  BALANCE_REPORT_BY_PATIENT: 'By Patient',
  BALANCE_REPORT_BY_MEDICATION: 'By Medication',
  DRUG_STATUS_PACKED: 'Packed',
  DRUG_STATUS_PACKED_PRN: 'Packed, Packed PRN',
  DRUG_STATUS_PRN: 'Packed PRN',
  DRUG_STATUS_CEASED: 'Ceased/Deleted',
  FILTER_PATIENT_STATUS_PLACEHOLDER: 'All Statuses',
  FILTER_PATIENT_STATUS_LABEL: 'Patient Status',
  GENERATOR_REPORT: 'Generate report',
  FROM_DATE_OPTION: 'From Date',
  TO_DATE_OPTION: 'To Date',
  BACK_TO_PROFILE: 'Back to Profile',
};

export default locale;
