import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const IMPORT_GET_DOCTOR = '@DOCTOR/IMPORT_GET_DOCTOR';
export const IMPORT_GET_DOCTOR_SUCCESS = '@DOCTOR/IMPORT_GET_DOCTOR_SUCCESS';
export const IMPORT_GET_DOCTOR_FAIL = '@DOCTOR/IMPORT_GET_DOCTOR_FAIL';

export const IMPORT_CREATE_DOCTOR = '@DOCTOR/IMPORT_CREATE_DOCTOR';
export const IMPORT_CREATE_DOCTOR_SUCCESS = '@DOCTOR/IMPORT_CREATE_DOCTOR_SUCCESS';
export const IMPORT_CREATE_DOCTOR_FAIL = '@DOCTOR/IMPORT_CREATE_DOCTOR_FAIL';

export const IMPORT_CHANGE_DOCTOR_FORM = '@DOCTOR/IMPORT_CHANGE_DOCTOR_FORM';

export const IMPORT_CLEAR_DOCTOR_FORM = '@DOCTOR/IMPORT_CLEAR_DOCTOR_FORM';

export const importGetDoctor = doctorId => {
	return dispatch => {
		dispatch({ type: IMPORT_GET_DOCTOR });
		HttpService.get(`/api/dispense/doctors/${doctorId}`)
			.then(resp => {
				dispatch({ type: IMPORT_GET_DOCTOR_SUCCESS, payload: { dispenseStagingId: resp.data.doctorId, ...resp.data } });
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch({ type: IMPORT_GET_DOCTOR_FAIL });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

export const importCreateDoctor = () => {
	return (dispatch, getState) => {
		const { doctorForm } = getState().doctor.importDoctor;
		dispatch({ type: IMPORT_CREATE_DOCTOR });
		HttpService.post(`api/doctors?IsFromDispense=true`, doctorForm)
			.then(resp => {
				dispatch(
					AppActions.showMessage({
						message: 'doctor:IMPORT_DOCTOR_SUCCESS'
					})
				);
				dispatch({ type: IMPORT_CREATE_DOCTOR_SUCCESS, payload: { doctorId: resp.data.doctorId } });
			})
			.catch(err => {
				const { errorMessages, errorTitle, errorFields } = parseError(err);
				dispatch({
					type: IMPORT_CREATE_DOCTOR_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields }
				});
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

export const importClearForm = () => {
	return dispatch => {
		dispatch({ type: IMPORT_CLEAR_DOCTOR_FORM });
	};
};

export const importChangeForm = data => {
	return dispatch => {
		dispatch({ type: IMPORT_CHANGE_DOCTOR_FORM, payload: { data } });
	};
};
