import * as DialogActions from '../actions/dialog-message.actions';

export const defDialogValue = {
	open: false,
	type: '',
	title: '',
	messages: null,
	action: '',
	options: {},
	callback: null
};

const initialSate = {
	dialogs: {}
};

const dialogMessage = (state = initialSate, action) => {
	switch (action.type) {
		case DialogActions.DIALOG_MESSAGE_CREATE:
			return {
				...state,
				dialogs: {
					...state.dialogs,
					[action.payload.dialogId]: {
						...defDialogValue,
						...action.payload.dialog,
						open: true
					}
				}
			};
		case DialogActions.DIALOG_MESSAGE_NEW_INSTANCE:
			return {
				...state,
				dialogs: {
					...state.dialogs,
					[action.payload.dialogId]: {
						...defDialogValue,
						...action.payload.dialog
					}
				}
			};
		case DialogActions.DIALOG_MESSAGE_OPEN:
			return {
				...state,
				dialogs: {
					...state.dialogs,
					[action.payload.dialogId]: {
						...state.dialogs[action.payload.dialogId],
						open: true,
						...action.payload.dialog
					}
				}
			};
		case DialogActions.DIALOG_MESSAGE_SET_CALLBACK:
			return {
				...state,
				dialogs: {
					...state.dialogs,
					[action.payload.dialogId]: {
						...state.dialogs[action.payload.dialogId],
						callback: action.payload.callback
					}
				}
			};
		// case DialogActions.DIALOG_MESSAGE_CLOSE:
		// 	{
		// 		const dialog = state.dialogs[action.payload.dialogId];

		// 		const newState = {
		// 			...state,
		// 			dialogs: {
		// 				...state.dialogs,
		// 				[action.payload.dialogId]: {
		// 					...defDialogValue,
		// 					callback: state.dialogs[action.payload.dialogId].callback,
		// 					action: action.payload.action
		// 				}
		// 			}
		// 		}
		// 		if(!dialog.hook) {
		// 			// We take care of dialog which created outside of hook to remove the instance
		// 			// Dialog created by hook will remove itself
		// 			delete newState.dialogs[action.payload.dialogId];
		// 		}
		// 		return newState;
		// 	}
		case DialogActions.DIALOG_MESSAGE_CLOSE:
		case DialogActions.DIALOG_MESSAGE_CLEAR:
			delete state.dialogs[action.payload.dialogId];
			return {
				...state
			};
		default:
			return state;
	}
};

export default dialogMessage;
