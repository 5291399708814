import * as NextStateAction from '../actions/nextState.action';

const initialState = {
	data: {},
};

const nextState = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case NextStateAction.NEXT_STATE_ADD:
			return {
				...state,
				data: { ...state.data, [payload.key]: payload.data },
			};
		case NextStateAction.NEXT_STATE_REMOVE:
			return {
				...state,
				data: { ...state.data, [payload.key]: undefined },
			};

		default:
			return state;
	}
};
export default nextState;
