const locale = {
  TITLE: 'Pharmacy management',
  HEADER_SEARCH_TEXT_PLACEHOLDER: 'Search Pharmacy...',
  HEADER_BUTTON_NEW_FULL: 'Add A Pharmacy',
  HEADER_BUTTON_NEW_SHORT: 'New',
  HEADER_BUTTON_MIGRATE_PATIENT: 'Migrate Patients',
  PHARMACY_DETAIL: 'Pharmacy Details',
  PHARMACY_NAME: 'Pharmacy Name',
  PHARMACY_CONFIGURATION: 'Pharmacy Configuration',
  PHARMACY_DATA_UPLOAD: 'Data Sync {{ isSa }}',
  PHARMACY_DATA_SYNC_MIGRATION: 'Data Synchronisation {{ isSa }}',
  PHARMACY_DATA_CONFIG: 'Datalake Configuration',
  NAME: 'Name',
  CODE: 'Pharmacy ID',
  APPROVAL_NUMBER: 'Approval Number',
  STATE: 'State',
  EMAIL: 'Email',
  GUID: 'Guid',
  PHONE: 'Phone',
  FAX: 'Fax',
  STREET: 'Address',
  SUBURB: 'Suburb',
  POSTAL_CODE: 'Postal Code',
  DOSE_ADD: 'DoseAid',
  TIMEZONE: 'Time zone',
  LOGO: 'Pharmacy Logo',
  IS_INTERGRATOR: 'Integration Partner',
  INTEGRATION_GUID: 'Integration GUID',
  ADD_PHARMACY_SUCCESS: 'Pharmacy has been added successfully',
  UPDATE_PHARMACY_SUCCESS: 'Pharmacy has been updated successfully',
  UPDATE_PHARMACY_CONFIGURATION_SUCCESS: "Pharmacy's configuration has been updated successfully",
  BUTTON_ADD_EMAIL: 'Add new email',
  BUTTON_ADD_GUID: 'Add new GUID',
  BUTTON_ADD_PHONE: 'Add new phone',
  BUTTON_ADD_FAX: 'Add new fax',
  MIGRATE_PATIENT_MESSAGE: 'Do you want to execute this action ?',
  MIGRATE_PATIENT_TITLE: 'Migrate Patients',
  MIGRATE_PATIENT_SUCCESS: 'Migrate Patients successfully',
  RESEND_INVITE_EMAIL_BUTTON: 'Resend Invite Email',
  RESEND_INVITE_EMAIL_MESSAGE: 'Send email successfully',
  REQUEST_SYNC_MESSAGE: 'Request sync data successfully',
  MIGRATE_BUTTON_IMPORT: 'Import',
  MIGRATE_FACILITY_IMPORTED_LABEL: 'Facility to be imported',
  MIGRATE_PATIENT_IMPORTED_LABEL: 'Patient to be imported',
  MIGRATE_FACILITY: 'Facility',
  MIGRATE_PATIENT_COUNT: 'Patient Count',
  MIGRATE_LAST_UPDATED: 'Last Updated',
  CONFIGURATION_DISPENSING_SYSTEM: 'Dispensing System',
  CONFIGURATION_FRED_PAK_STATUS_ACTIVE: 'Fred Pak Status Active',
  CONFIGURATION_MINFOS_SERVER_URL: 'WebService URL',
  CONFIGURATION_MINFOS_BUSINESS_CODE: 'Business Code',
  CONFIGURATION_MINFOS_USER_NAME: 'Username',
  CONFIGURATION_MINFOS_USER_PASSWORD: 'Password',
  CONFIGURATION_LOTS_SQL_HOST: 'SQL Hostname',
  CONFIGURATION_LOTS_SQL_DATABASE: 'SQL Database',
  CONFIGURATION_LOTS_SQL_USER_NAME: 'SQL Username',
  CONFIGURATION_LOTS_SQL_USER_PASSWORD: 'SQL Password',
  CONFIGURATION_FRED_DATABASE_FOLDER: 'FRED DBFS Folder',
  CONFIGURATION_FRED_PATH_FOR_DORPTS: 'Path to create DORPTS',
  CONFIGURATION_FRED_PAK_PATH: 'FREDPak DBFS Folder',
  CONFIGURATION_PHARMACY_GUID: 'Pharmacy Guid',
  CONFIGURATION_SYNC_COLLECTION: 'Sync Collection',
  CONFIGURATION_MAX_DAYS_TO_CHECK_SCRIPT: 'Max Days To Check Script',
  CONFIGURATION_ALPACA_FILE_EXPORT_FOLDER: 'Alpaca File Export Folder',
  CONFIGURATION_PACK_MANAGEMENT_STAGES: 'Pack Management Stages',
  CONFIGURATION_CORUM_CLEAR_PATH: 'Path to Corum ClearConnect Utility',
  CONFIGURATION_CORUM_IDENTIFIER: 'Corum ClearConnect Unique Identifier',
  CONFIGURATION_Z_API_URL: 'Api URL',
  CONFIGURATION_Z_VENDOR_ACCESS_KEY: 'Vendor Access Key',
  CONFIGURATION_Z_STORE_ACCESS_KEY: 'Store Access Key',
  CONFIGURATION_Z_BATCH_ID: 'Batch Id',
  CONFIGURATION_Z_USER_NAME: 'User Name',
  CONFIGURATION_FRED_NXT_API: 'FredNXT API URL',
  CONFIGURATION_FRED_NXT_ACCESS_KEY: 'FredNXT Access Key',
  CONFIGURATION_FRED_NXT_SUBSCRIPTION_KEY: 'FredNXT Subscription Key',
  BUTTON_ADD_SYNC_COLLECTION: 'Add collection',
  BUTTON_START_DATA_MIGRATION: 'Start Desktop Migration',
  BUTTON_START_DISPENSE_UPLOAD: 'Start Dispense Sync',
  BUTTON_REQUEST_SYNC_DATA: 'Request Data Sync',
  BUTTON_DOWNLOAD_DATALAKE: 'Download configuration',
  BUTTON_UPLOAD_DRUG: 'Upload Drug',
  DATA_UPLOAD_DATA_MIGRATION_MESSAGE: 'Migrate data is not finished',
  DATA_UPLOAD_DISPENSE_UPLOAD_MESSAGE: 'Dispense upload is not finished',
  PRINTER_SETTING: 'Printer Settings',
  BLISTER_TYPE: 'Blister Type',
  DEFAULT_PRINTER: 'Default Printer',
  REMOVE_PRINTER_SETTING_CONFIRM_TITLE: 'Remove Printer Setting',
  REMOVE_PRINTER_SETTING_CONFIRM_CONTENT: 'Are you sure you want to remove this setting',
  ADD_PRINTER_SETTING_BUTTON: 'Add Printer Setting',
  REMOVE_PRINTER_SETTING_SUCCESS: 'The settings for this Blister Type is successfully removed',
  UPDATE_PRINTER_SETTING_SUCCESS: 'The settings for this Blister Type is successfully updated',
  CREATE_PRINTER_SETTING_SUCCESS: 'The settings for this Blister Type is successfully created',
  REQUEST_SYNC_TOOLTIP: 'Request to sync data from myPak.Desktop',
  REQUEST_SYNC_TOOLTIP_DISABLED: 'Please enter Pharmacy GUID',
  START_DATA_MIGRATION_TOOLTIP: 'Start migrate staging data to live data',
  START_DISPENSE_UPLOAD_TOOLTIP: 'Start upload dispense data to staging database',
  DESKTOP_SYNC_DATA_TITLE: 'myPak Desktop Data Migration',
  DISPENSE_INTEGRATED_DATA_TITLE: 'Dispense Data Sync',
  START_DATA_MIGRATION_NOTE: 'Migrate data from myPak Desktop to myPak NG',
  DELETE_PHARMACY_SUCCESS: 'Pharmacy has been deleted successfully',
  BUTTON_DELETE_PHARMACY: 'Delete pharmacy',
  DELETE_PHARMACY_CONFIRM_CONTENT: 'Are you sure you want to delete pharmacy {{ name }} ?',
  DELETE_PHARMACY_CONFIRM_TITLE: 'Delete Pharmacy',
  BUTTON_SELECT_IMAGE: 'Select Image',
  BUTTON_SAVE_IMAGE: 'Save Image',
  CONFIGURATION_STAGES_PRINTING: 'Printing',
  CONFIGURATION_STAGES_PACKING: 'Packing',
  CONFIGURATION_STAGES_CHECKING: 'Checking',
  CONFIGURATION_STAGES_DISTRIBUTION: 'Distribution',
  VIRTUAL_PILL_BALANCE: 'Virtual Pill Balance',
  QSCRIPT_FEATURE: 'QScript Feature',
  DISPENSE_TRACKER: 'Dispense Tracker',
  MIGRATION_PROCESS_COMPLETED:
    'The migration was completed before. Would you like to reset the pharmacy database and start again.',
  CONFIGURATION_DW_HOST: 'Host / Server',
  CONFIGURATION_DW_DB_NAME: 'Database',
  CONFIGURATION_DW_TRUSTED_CONNECTION: 'Trusted Connection',
  CONFIGURATION_DW_USER_NAME: 'User Name',
  CONFIGURATION_DW_USER_PASSWORD: 'Password',
  CONFIGURATION_DW_QSCRIPT_EXPORT_FOLDER: 'QScript Export Folder',
  CONFIGURATION_FRED_DISPENSE_PLUS_API_URL: 'Fred Dispense Plus API URL',
  CONFIGURATION_FRED_DISPENSE_PLUS_API_KEY: 'Fred Dispense Plus API Key',
  CONFIGURATION_FRED_DISPENSE_PLUS_SUBSCRIPTION_KEY: 'Fred Dispense Plus Subscription Key',
  CONFIGURATION_LOG_OUT_TIME: 'Log out due to inactivity (Mins)',
};

export default locale;
