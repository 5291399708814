const locale = {
  TITLE: 'Patient Group Management',
  HEADER_TITLE: 'Patient Groups',
  HEADER_SEARCH_TEXT_PLACEHOLDER: 'Search patient groups...',
  HEADER_ADD_PATIENT_GROUP_BUTTON_FULL: 'Add Patient Group',
  HEADER_ADD_PATIENT_GROUP_BUTTON_SHORT: 'New',
  ARCHIVE_PATIENT_GROUP_CONFIRM_TITLE: 'Archive Patient Group?',
  ARCHIVE_PATIENT_GROUP_CONFIRM_CONTENT: 'Are you sure you want to archive patient group {{patientGroupName}}?',
  PATIENT_GROUP_TABLE_HEADER_CODE: 'Code',
  PATIENT_GROUP_TABLE_HEADER_DISPENSE_CODE: 'Dispense Code',
  PATIENT_GROUP_TABLE_HEADER_NAME: 'Name',
  PATIENT_GROUP_TABLE_HEADER_TYPE: 'Type',
  PATIENT_GROUP_TABLE_HEADER_DISTRIBUTION: 'Distribution',
  PATIENT_GROUP_TABLE_HEADER_AUTOMATION: 'Automation',
  PATIENT_GROUP_TABLE_HEADER_PHONE: 'Phone',
  PATIENT_GROUP_TABLE_HEADER_ADDRESS: 'Address',
  PATIENT_GROUP_TABLE_HEADER_STATUS: 'Status',
  PATIENT_GROUP_LIST_NO_SEARCH_RESULT: 'No search results',
  PATIENT_GROUP_LIST_NO_DATA: 'No data to display',
  ARCHIVE_PATIENT_GROUP_SUCCESS: 'Patient Group has been archived successfully!',
  ARCHIVE_PATIENT_GROUP_FAIL: 'Failed to archive Patient Group!',
  PATIENT_GROUP_SECTION_DETAIL: 'Patient Group Details',
  PATIENT_GROUP_SECTION_ADDRESS: 'Address Information',
  PATIENT_GROUP_SECTION_WARD: 'Ward',
  CODE: 'Code',
  DISPENSE_CODE: 'Dispense Code',
  RACFID: 'RACF ID',
  NAME: 'Name',
  STATUS: 'Group Status',
  PATIENT_GROUP_TYPE: 'Type',
  MANIFEST: 'Distribution',
  STREET: 'Street',
  SUBURB: 'Suburb',
  STATE: 'State',
  POSTCODE: 'Postcode',
  COUNTRY: 'Country',
  EMAIL: 'Email',
  PACK_LOGO: 'Pack Logo',
  FAX: 'Fax',
  VALIDATE_FAX_NUMBER: 'Must provide a valid fax number',
  BEDS: 'Beds',
  VALIDATE_BED: 'Must provide a valid value between {{ min }} and {{ max }}',
  CONTACT_NAME: 'Contact Name',
  PHONE: 'Phone',
  VALIDATE_PHONE: 'Must provide a valid phone number',
  PRINT_LIST_BY_ROOM: 'List Patients by Room Number',
  ICARE: 'Display Drug Name as Generic (iCare)',
  AUTOMATION: 'Automation',
  DIALOG_TIMESLOT_TITLE: 'Default Timeslots',
  DIALOG_TIMESLOT_BLISTER: 'Blister Timeslots',
  DIALOG_TIMESLOT_SHORTCOURSE_SINEMET: 'Short Course/Parkinson’s Timeslots',
  DIALOG_TIMESLOT_SACHET: 'Sachet Timeslots',
  DIALOG_TIMESLOT_BUTTON: 'Default Timeslots',
  CUSTOM_OPTION_LABEL: 'Custom',
  DIALOG_DELETE_MESSAGE: 'Are you sure you want to delete {{name}}?',
  DIALOG_DELETE_TITLE: 'Delete Patient Group',
  DELETE_SUCCESS_MESSAGE: 'Patient group has been deleted successfully',
  DIALOG_DELETE_WARD_TITLE: 'Delete Ward',
  DIALOG_CONFIRM_DELETE_WARD: 'There are patients assigned to this ward.\nAre you sure you want to delete {{ name }}',
  GROUP_CHANGE_STATUS_TITLE: 'Warning',
  GROUP_CHANGE_STATUS_MESSAGE: `Are you sure you want to make Patient Group {{ name }} inactive? All patients will be marked discharged and all medications will be ceased.`,
  WARD_INPUT: 'Ward Name',
  WARD_LIST_TITLE: 'Ward List',
  PATIENT_GROUP_PLACEHOLTER: 'All Patient Groups',
  PATIENT_GROUP_SELECTED: 'Selected Patient Groups',
  PATIENT_GROUP_DETAIL: 'Patient Group Details',
  PACK_OPTION: 'Pack Options',
  MYPAK_SOLID: 'myPak Solid',
  MYPAK_PERF: 'myPak Perf',
  MYPAK_TRIFOLD: 'myPak Trifold',
  MEDICO: 'Medico Pak',
  DATE: 'Dates',
  START_DATE: 'Start Dates',
  DAY_DATE_OF_THE_WEEK: 'Day Dates of the week',
  DOSE_TIME_HEADINGS: 'Dose Time Headings',
  REVERSE_START_DAY_ON_THE_WEEK: 'Reverse Start Day On The Week',
  REVERSE_DISPLAY_OFF_TIME_SLOTS: 'Reverse display of time slots',
  DRUG_INFORMATION: 'Drug Information',
  DRUG_WARNING: 'Drug Warning',
  SAME_AS: 'Same As',
  PRINT_ON_POTTLES: 'Print on Pottles',
  PATIENT_NAME_ON_POTTLES: 'Patient Name on Pottles',
  NON_PACKED_AND_PRN: 'Non-Packed and PRN',
  NON_DAILY_MEDICATION_SCHEDULE: 'Non-Daily Medication Schedule',
  DO_NOT_CRUSH: 'Do Not Crush',
  TOTAL_QTY: 'Total QTY displayed on pack',
  TOTAL_PIECES: 'Total pieces per timeslot',
  TOTAL_TABLET: 'Total tablets per timeslot',
  DRUG_PRINT_FORMAT: 'Drug Printing Format',
  TRADE_STRENGTH_GENERIC: 'Trade, Strength, Generic',
  GENERIC_STRENGTH_TRADE: 'Generic, Strength, Trade',
  UPDATE_PACK_OPTIONS_SUCCESS_MESSAGE: 'Pack options has been updated successfully',
  DIALOG_UPDATED_AUTOMATION_MESSAGE:
    "{{status}} Automation will update Patient's Pack Status for all patients in this group. Are you sure want to continue?  ",
  WARD_TABLE_HEADER: 'Ward',
  WARD_TABLE_HEADER_DATE_RECORD: 'Date Recorded',
  WARD_TABLE_EMPTY_MESSAGE: 'Create a patient group to add Ward',
  WARD_DIALOG_ADD_WARD: 'Add Ward',
  WARD_IALOG_EDIT_WARD: 'Edit Ward',
  GUIDED_FILL_PACKING: 'Guided Fill Packing',
  PORTRAITPACKINGDISPLAYGRID: 'Portrait Packing Display Grid',
  LANDSCAPEPACKINGDISPLAYGRID: 'Landscape Packing Display Grid',
  ACTIVE_PATIENTS: 'Active Patients',
  DEFAULT_PACK_TYPE: 'Default Pack Type',
  MEDICATION_SIGNING_CHART: 'Medication Signing Chart',
  WARNIGN_SIGNING_CHART_MESSAGE:
    'Due to space limitation, the Medication Signing Chart for \n myPak Trifold will only be displayed when there are:\t\t',
};

export default locale;
