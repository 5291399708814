import { HttpService } from 'app/services';
import { parseError } from 'helpers/Error';
import * as AppActions from 'app/store/actions';

export const GET_MIGRATE = '@PHARMACY/GET_MIGRATE';
export const GET_MIGRATE_SUCCESS = '@PHARMACY/GET_MIGRATE_SUCCESS';
export const GET_MIGRATE_FAIL = '@PHARMACY/GET_MIGRATE_FAIL';

export const MIGRATE_PATIENT = '@PHARMACY/MIGRATE_PATIENT';
export const MIGRATE_PATIENT_SUCCESS = '@PHARMACY/MIGRATE_PATIENT_SUCCESS';

export function getMigrateStatus() {
	return dispatch => {
		dispatch({ type: GET_MIGRATE });
		HttpService.get('/external/api/status')
			.then(resp => {
				dispatch({
					type: GET_MIGRATE_SUCCESS,
					payload: { data: resp }
				});
			})
			.catch(err => {
				const { errorMessages } = parseError(err);
				dispatch({ type: GET_MIGRATE_FAIL, payload: { message: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
}

export const migratePatient = () => {
	return dispatch => {
		dispatch({ type: MIGRATE_PATIENT });
		HttpService.get('api/helpdesk/datamigrate').then(() => {
			dispatch({ type: MIGRATE_PATIENT_SUCCESS });
			dispatch(
				AppActions.showMessage({
					message: 'pharmacy:MIGRATE_PATIENT_SUCCESS',
					autoHideDuration: 4000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right'
					},
					variant: 'success'
				})
			);
			dispatch(getMigrateStatus());
		});
	};
};
