import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import i18next from 'i18next';
import { MENU_ENUM } from 'app/main/role/constants';
import en from '../i18n/en';

i18next.addResourceBundle('en', 'homePage', en);

const LogsListConfig = {
	settings: {
		layout: {
			config: {}
		},
		nav: {
			title: 'Logs'
		}
	},
	auth: authRoles.user,
	key: MENU_ENUM.HOME,
	permission: PERMISSION.READ,
	routes: [
		{
			path: '/home/logs',
			component: React.lazy(() => import('./HomeLogs'))
		}
	]
};

export default LogsListConfig;
