/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, TableHead, Table, TableBody, TablePagination, Tooltip } from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import OverlayLoading from 'components/OverlayLoading';
import Checkbox from 'components/Checkbox';
import Radio from 'components/Radio';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import MemorizedTableRow from './MemorizedTableRow';

const useStyles = makeStyles((theme) => ({
	datatable: {},
	message: {
		textAlign: 'center',
		color: 'rgba(191,191,191,1.0)',
		paddingTop: 15,
		position: 'absolute',
		top: '50%',
		width: '100%',
	},
	rowHover: {
		'&.MuiTableRow-hover:hover': {
			cursor: 'pointer',
			backgroundColor: theme.palette.primary[100],
			'& td': {
				backgroundColor: theme.palette.primary[100],
			},
		},
	},
	selected: {
		backgroundColor: theme.palette.primary.main,
	},
	header: {
		'& > tr th': {
			backgroundColor: '#f4f4f4',
		},
	},
	body: {
		// except selected row
		'& > tr:nth-child(even):not(.Mui-selected)': {
			backgroundColor: '#f4f4f4',
		},
	},
	checkboxAll: {
		width: 54,
	},
	status: {
		paddingLeft: theme.spacing(2),
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
}));

export const SORT_DIRECTION = {
	ASC: 'asc',
	DESC: 'desc',
};

const DataTable = (props) => {
  const {
    headerRowProps = {},
    headerProps = {},
    bodyProps = {},
    bodyRowProps = {},
    containerProps = {},
    firstRowProps = null,
    columns = [],
    data = [],
    idField = 'id',
    progress = false,
    emptyMessage = 'No data to display',
    onRowClick,
    pagination = false,
    count = 0,
    page = 0,
    size = 20,
    sizes = [10, 20, 50, 100],
    onChangePage,
    onChangeRowsPerPage,
    header,
    className,
    hover = false,
    checkbox = false,
    highLightSelected = true,
    checkAll = false,
    onCheckAll,
    checked = [],
    onCheck,
    select = false,
    onSort,
    maxHeight,
    editable = false,
    rowDeps = [],
    checkWhenSelectRow = true, // In case do not want to check onRowClick, set it to false
    hasHeader = true, // display header table
    checkedWhenDisable = false, // NG-4033
    ...rest
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { className: headerRowClassName, ...headerRowPropsRest } = headerRowProps;
  const [checkedData, _setCheckedData] = useState(new Set());
  const checkedDataRef = React.useRef(checkedData);
  const setCheckedData = (newData) => {
    checkedDataRef.current = newData;
    _setCheckedData(newData);
  };
  const defaultSortCol = columns.find((c) => c.defaultSort) || columns.find((c) => c.sort) || columns[0];
  const [sortDirection, setSortDirection] = React.useState(defaultSortCol.direction ?? SORT_DIRECTION.ASC);
  const [sortField, setSortField] = React.useState(defaultSortCol.field);

	// using to watch the column header change
	const columnHeaders = columns.map((i) => i.name).join('-');

	// handle checked changes from outsite
	useEffect(() => {
		setCheckedData(new Set(checked));
	}, [checked.join(',')]);

	const handleSelectRow = (item) => {
		if (onRowClick) {
			onRowClick(item);
		}
		if (checkWhenSelectRow) {
			handleCheckSingle(!checkedDataRef.current.has(item[idField]), item);
		}
	};

	const handleCheckAll = (e) => {
		setCheckedData(new Set());

		if (onCheckAll) onCheckAll(e.target.checked);
	};

	// eslint-disable-next-line consistent-return
	const handleCheckSingle = (isChecked, item) => {
		if (isChecked && !checkedDataRef.current.has(item[idField])) {
			if (!checkbox && select) {
				checkedDataRef.current.clear();
			}
			// If checked and not existed in array
			checkedDataRef.current.add(item[idField]); // add to array
			setCheckedData(new Set(checkedDataRef.current)); // save
		} else if (!isChecked && checkedDataRef.current.has(item[idField])) {
			// If not checked and existed in array
			checkedDataRef.current.delete(item[idField]); // remove from array
			setCheckedData(new Set(checkedDataRef.current)); // save
		}
		if (onCheck) onCheck(Array.from(checkedDataRef.current));
	};

	const revertDirection = () => {
		let currentSortDirection = sortDirection;
		if (currentSortDirection === SORT_DIRECTION.ASC) {
			currentSortDirection = SORT_DIRECTION.DESC;
		} else {
			currentSortDirection = SORT_DIRECTION.ASC;
		}

		return currentSortDirection;
	};

	const handleSort = (column) => {
		const newSortField = column.field;
		const revertedDirection = revertDirection();

		if (newSortField === sortField) {
			// if same column then revert direction and save
			setSortDirection(revertedDirection);
		} else {
			// If sort column is changed then update state
			setSortField(newSortField);
		}

		const sortData = {
			field: newSortField,
			direction: newSortField === sortField ? revertedDirection : sortDirection, // if column is same then revert direction, otherwise, keeps same direction
		};

		onSort && onSort(sortData);
	};

	const MemorizedTableHeader = useMemo(() => {
		if (header) return header;
		return (
			<TableHead {...headerProps} className={clsx(classes.header, headerProps.className)}>
				<TableRow className={clsx(headerRowClassName, 'h-40')} {...headerRowPropsRest}>
					{(checkbox || select) && (
						<TableCell key="table-header-cell-checkall" className={clsx('border-none', classes.checkboxAll)}>
							{checkbox && onCheckAll ? (
								<Checkbox checked={checkAll} onChange={handleCheckAll} disabled={!data.length} />
							) : (
								<span />
							)}
						</TableCell>
					)}
					{columns.map((c, idx) => (
						<TableCell
							key={`table-header-cell-${c.field}-${idx}`}
							{...c.props}
							className={clsx('border-none', !c.sort && 'cursor-default', c.props?.className)}
							sortDirection={c.sort && sortField === c.field ? sortDirection : false}
						>
							<>
								{c.sort ? (
									<TableSortLabel
										active={sortField === c.field}
										direction={sortDirection}
										onClick={() => handleSort(c)}
									>
										{/* To support HTML header */}
										<div className="inline-block" dangerouslySetInnerHTML={{ __html: c.name }} />
										{sortField === c.field ? (
											<span className={classes.visuallyHidden}>
												{sortDirection === SORT_DIRECTION.DESC ? 'sorted descending' : 'sorted ascending'}
											</span>
										) : null}
									</TableSortLabel>
								) : (
									c.name
								)}
							</>
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	}, [checkAll, columns.length.toString(), sortDirection, sortField, columnHeaders, onCheckAll]);

	const MemorizedTablePagination = useMemo(() => {
		let _onChangePage = onChangePage;
		if (!_onChangePage) _onChangePage = () => {};

		let _onChangeRowsPerPage = onChangeRowsPerPage;
		if (!_onChangeRowsPerPage) _onChangeRowsPerPage = () => {};

		if (!pagination) return null;
		return (
			<div className={clsx(checkbox && 'flex items-center')}>
				{checkbox && (
					<div className={clsx(classes.status)}>
						{t('DATATABLE_SELECTED_STATUS', {
							selected: checkAll ? count : checkedData.size,
							total: count,
						})}
					</div>
				)}
				<TablePagination
					className={clsx(checkbox && 'flex-1')}
					component="div"
					count={count}
					rowsPerPage={size}
					rowsPerPageOptions={sizes}
					page={page}
					backIconButtonProps={{
						'aria-label': 'Previous Page',
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page',
					}}
					onChangePage={_onChangePage}
					onChangeRowsPerPage={_onChangeRowsPerPage}
				/>
			</div>
		);
	}, [pagination, count, page, size, checkedData.size]);

	const TableRowCellMemorized = (cellProps) => {
		const { id, rowId, ...cellRest } = cellProps;
		const tableCell = useMemo(() => {
			return <TableCell {...cellRest} />;
		}, [id, rowId]);

		return tableCell;
	};

  return (
    <div
      className={clsx(classes.datatable, 'relative w-full flex flex-col', containerProps.className)}
      ref={containerProps.ref}
    >
      {progress && <OverlayLoading />}
      <Table {...rest} className={clsx(className, data.length <= 0 && 'h-full')}>
        {hasHeader && MemorizedTableHeader}
        <TableBody {...bodyProps} className={clsx(classes.body, bodyProps.className)}>
          {data.length <= 0 && !progress && (
            <TableRow>
              <TableCell
                colSpan={checkbox || select ? columns.length + 1 : columns.length}
                className="text-center cursor-default"
                style={{ borderBottom: 'none' }}
              >
                {emptyMessage}
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && firstRowProps && (
            <TableRow>
              <TableCell
                colSpan={checkbox || select ? columns.length + 1 : columns.length}
                className="text-center cursor-default"
              >
                {firstRowProps}
              </TableCell>
            </TableRow>
          )}
          {data.map((item) => {
            const { disabledRow, ...restBodyRowProps } = bodyRowProps;
            let rowStyles = bodyRowProps.className;
            if (rowStyles && typeof bodyRowProps.className === 'function') {
              rowStyles = bodyRowProps.className(item);
            }
            let tooltipMessage = bodyRowProps.tooltip;
            if (tooltipMessage && typeof bodyRowProps.tooltip === 'function') {
              tooltipMessage = bodyRowProps.tooltip(item);
            }
            let disabled = disabledRow;
            if (disabled && typeof disabledRow === 'function') {
              disabled = disabledRow(item);
            }
            const row = (
              <MemorizedTableRow
                key={`table-body-row-${item[idField]}`}
                id={item[idField]}
                {...restBodyRowProps}
                hover={hover}
                className={clsx(rowStyles, classes.rowHover)}
                onClick={() => {
                  if (disabled) return;
                  handleSelectRow(item);
                }}
                editable={editable}
                rowDeps={rowDeps}
                rowStyles={rowStyles}
                item={item}
                columnCount={columns.length}
                rowChecked={checkedData.has(item[idField])}
                checkAll={checkAll}
              >
                {(checkbox || select) && (
                  <TableCell className="border-none" key={`table-header-cell-checkbox-${item[idField]}`}>
                    {checkbox ? (
                      <Checkbox
                        // MC-780031: should not disable when select all
                        value={item[idField]}
                        disabled={disabled}
                        checked={
                          (checkAll && !disabled) || checkedData.has(item[idField]) || (checkedWhenDisable && disabled)
                        }
                        onClick={(e) => {
                          if (!checkWhenSelectRow && !disabled) {
                            e.stopPropagation();
                            handleCheckSingle(e.target.checked, item);
                          }
                        }}
                      />
                    ) : select ? (
                      <Radio
                        value={item[idField]}
                        disabled={checkAll || disabled}
                        checked={(checkAll && !disabled) || checkedData.has(item[idField])}
                        onClick={(e) => {
                          if (!checkWhenSelectRow && !disabled) {
                            e.stopPropagation();
                            handleCheckSingle(e.target.checked, item);
                          }
                        }}
                      />
                    ) : (
                      <span />
                    )}
                  </TableCell>
                )}
                {columns.map((c, idx) => {
                  let value = item[c.field];
                  if (typeof c.render === 'function') {
                    value = c.render(value, item);
                  }

									// eslint-disable-next-line no-shadow
									let { bodyProps = {} } = c;
									if (typeof bodyProps === 'function') {
										bodyProps = bodyProps(item);
									}

                  return (
                    <TableRowCellMemorized
                      key={`table-body-cell-${c.field}-${idx}`}
                      id={c.field}
                      rowId={item[idField]}
                      {...bodyProps}
                      className={clsx('break-all border-none', !hover && 'cursor-default', bodyProps.className)}
                    >
                      {value}
                    </TableRowCellMemorized>
                  );
                })}
              </MemorizedTableRow>
            );

						return tooltipMessage ? (
							<Tooltip
								key={`table-body-row-${item[idField]}`}
								enterDelay={0}
								enterTouchDelay={0}
								leaveDelay={0}
								leaveTouchDelay={1500}
								interactive
								title={tooltipMessage}
							>
								{row}
							</Tooltip>
						) : (
							row
						);
					})}
				</TableBody>
			</Table>
			{MemorizedTablePagination}
		</div>
	);
};

DataTable.propTypes = {
	tableProps: PropTypes.any,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.node.isRequired,
			field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
			props: PropTypes.any,
		})
	).isRequired,
	data: PropTypes.array.isRequired,
	idField: PropTypes.string,
	progress: PropTypes.bool,
	count: PropTypes.number,
	page: PropTypes.number,
	size: PropTypes.number,
	sizes: PropTypes.arrayOf(PropTypes.number),
	onChangePage: PropTypes.func,
	onChangeRowsPerPage: PropTypes.func,
	pagination: PropTypes.bool,
	editable: PropTypes.bool,
};

export default DataTable;
