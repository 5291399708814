import { authRoles, PERMISSION } from 'app/auth';
import i18next from 'i18next';
import { MENU_ENUM } from 'app/main/role/constants';
import Feedback from './Feedback';
import en from './i18n/en';

i18next.addResourceBundle('en', 'feedback', en);

const FeedbackConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  key: MENU_ENUM.FEEDBACK,
  permission: PERMISSION.READ,
  routes: [
    {
      path: '/feedback',
      component: Feedback,
    },
  ],
};

export default FeedbackConfig;
