import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import i18next from 'i18next';
import { MENU_ENUM } from 'app/main/role/constants';
import en from './i18n/en';

i18next.addResourceBundle('en', 'claim', en);
const ClaimConfig = {
	settings: {
		layout: {
			config: {}
		},
		nav: {
			title: 'PPA Claim'
		}
	},
	auth: authRoles.user,
	key: MENU_ENUM.PRINT_FOIL,
	permission: PERMISSION.READ,
	routes: [
		{
			path: '/claim',
			component: React.lazy(() => import('./Claim'))
		}
	]
};

export default ClaimConfig;
