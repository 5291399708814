import React from 'react';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import accessIcon from './access.svg';

function UnAuthorized(props) {
	const { t } = useTranslation();

	return (
		<FusePageSimple
			content={
				<div className="p-24 flex flex-col justify-center items-center h-full">
					<img src={accessIcon} alt="403 Forbidden" width={150} />
					<Typography className="text-center" variant="body1">
						{t('unAuthorized:MESSAGE')}
					</Typography>
				</div>
			}
		/>
	);
}

export default UnAuthorized;
