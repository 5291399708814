import * as PharmacyConfigurationActions from '../actions/configuration.actions';

const initState = {
  data: {},
};

const configuration = (state = initState, action) => {
  switch (action.type) {
    case PharmacyConfigurationActions.SET_PHARMACY_CONFIG:
      return { ...state, data: action.payload.data };
    default:
      return state;
  }
};

export default configuration;
