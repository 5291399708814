import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const DISTRIBUTE_GET_DOSE = '@JOB_DISTRIBUTE/GET_DOSE';
export const DISTRIBUTE_GET_DOSE_SUCCESS = '@JOB_DISTRIBUTE/GET_DOSE_SUCCESS';
export const DISTRIBUTE_GET_DOSE_FAIL = '@JOB_DISTRIBUTE/GET_DOSE_FAIL';

export const DISTRIBUTE_COMPLETE = '@JOB_DISTRIBUTE/COMPLETE';
export const DISTRIBUTE_COMPLETE_SUCCESS = '@JOB_DISTRIBUTE/COMPLETE_SUCCESS';
export const DISTRIBUTE_COMPLETE_FAIL = '@JOB_DISTRIBUTE/COMPLETE_FAIL';

export const DISTRIBUTE_UPDATE_COMMENT = '@JOB_DISTRIBUTE/UPDATE_COMMENT';
export const DISTRIBUTE_UPDATE_COMMENT_SUCCESS = '@JOB_DISTRIBUTE/UPDATE_COMMENT_SUCCESS';
export const DISTRIBUTE_UPDATE_COMMENT_FAIL = '@JOB_DISTRIBUTE/UPDATE_COMMENT_FAIL';

export const DISTRIBUTE_RESET_DATA = '@JOB_DISTRIBUTE/RESET_DATA';

export function getDoseAllocated(jobId) {
	return dispatch => {
		dispatch({ type: DISTRIBUTE_GET_DOSE });
		HttpService.get(`/api/packjobs/${jobId}/distribution`)
			.then(resp => {
				dispatch({ type: DISTRIBUTE_GET_DOSE_SUCCESS, payload: { data: resp.data } });
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
				dispatch({ type: DISTRIBUTE_GET_DOSE_FAIL, payload: { message: errorMessages.join('\n') } });
			});
	};
}

export function completeDistribute(jobIds, comment = '') {
	return dispatch => {
		dispatch({ type: DISTRIBUTE_COMPLETE });
		return HttpService.put('/api/packjobs/complete-distribution', { jobIds, comment })
			.then(resp => {
				dispatch({ type: DISTRIBUTE_COMPLETE_SUCCESS, payload: { data: resp.data } });
				dispatch(AppActions.showMessage({ message: 'job:COMPLETE_DISTRIBUTE_MESSAGE' }));
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
				dispatch({ type: DISTRIBUTE_COMPLETE_FAIL, payload: { message: errorMessages.join('\n') } });
			});
	};
}

export function updateComment(jobId, comment = '') {
	return dispatch => {
		dispatch({ type: DISTRIBUTE_UPDATE_COMMENT });
		return HttpService.put(`/api/packjobs/${jobId}/complete/comment`, { comment })
			.then(resp => {
				dispatch({ type: DISTRIBUTE_UPDATE_COMMENT_SUCCESS, payload: { data: resp.data } });
				dispatch(AppActions.showMessage({ message: 'job:COMPLETE_DISTRIBUTE_MESSAGE' }));
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
				dispatch({ type: DISTRIBUTE_UPDATE_COMMENT_FAIL, payload: { message: errorMessages.join('\n') } });
			});
	};
}

export function resetData() {
	return dispatch => {
		dispatch({ type: DISTRIBUTE_RESET_DATA });
	};
}

export function parseModelToData(model) {
  return {
    jobId: model.jobId,
    jobNumber: model.jobNumber,
    patient: model.patient,
    patientGroup: model.patientGroup,
    numberOfWeek: model.numberOfWeek,
    packStartDate: model.packStartDate,
    prescriptions: model.prescriptions,
    checkChangePrescriptions: model.checkChangePrescriptions,
    headings: model.headings,
  };
}
