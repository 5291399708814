const locale = {
  HEADER_BREADCRUMB: 'Doctor',
  HEADER_TITLE: 'Doctor Management',
  HEADER_SEARCH_TEXT_PLACEHOLDER: 'Search doctor...',
  HEADER_BUTTON_NEW_FULL: 'Add Doctor',
  HEADER_BUTTON_NEW_SHORT: 'New',
  LIST_HEADER_COL_FIRST_NAME: 'First Name',
  LIST_HEADER_COL_LAST_NAME: 'Last Name',
  LIST_HEADER_COL_PHONE: 'Phone',
  LIST_HEADER_COL_EMAIL: 'Email',
  LIST_HEADER_COL_FAX: 'Fax',
  LIST_HEADER_OUT_OF_HOUR: 'Out of Hour',
  LIST_HEADER_PBS_APPROVAL: 'Prescriber/Provider Number',
  LIST_HEADER_ADDRESS: 'Address',
  LIST_HEADER_ACTIONS: 'Actions',
  LIST_HEADER_IS_PRINT: 'Print',
  LIST_HEADER_PRIORITY: 'Priority',
  ARCHIVE_DOCTOR_CONFIRM_TITLE: 'Archive Doctor',
  ARCHIVE_DOCTOR_CONFIRM_CONTENT: 'Are you sure you want to archive doctor {{firstName}} {{lastName}}?',
  ARCHIVE_DOCTOR_SUCCESS: 'Doctor has been archived successfully!',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  PHONE: 'Phone',
  FAX: 'Fax',
  ADDRESS: 'Address',
  PBS_APPROVAL: 'Prescriber/Provider Number',
  OUT_OF_HOUR: 'Out Of Hours',
  ADD_DOCTOR_SUCCESS: 'Doctor has been added successfully',
  IMPORT_DOCTOR_SUCCESS: 'Doctor has been imported successfully',
  UPDATE_DOCTOR_SUCCESS: 'Doctor has been updated successfully',
  DELETE_DOCTOR_SUCCESS: 'Doctor has been deleted successfully',
  SEARCH_DOCTOR: 'Search doctor...',
  GROUP_NAME: 'Group',
  TITLE: 'Title',
  ROOM: 'Room',
  TAB_DOCTOR_DETAILS: 'Details',
  TAB_PATIENTS: 'Patients',
  DETAILS_SECTION: 'Doctor Details',
  PATIENTS_SECTION: 'Patients linked to this Doctor',
  BUTTON_IMPORT_FROM_DISPENSE: 'Import From Dispense',
  DIALOG_IMPORT_FROM_DISPENSE_TITLE: 'Import Doctor From Dispense',
  BUTTON_DELETE: 'Delete',
  MEDICAL_CENTRE: 'Medical Centre',
  ACTIVE_PATIENTS: 'Active Patient',
};

export default locale;
