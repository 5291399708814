import * as PatientGroupAction from '../actions/list.actions';

const defaultFilter = {
	textSearch: ''
};

const initialState = {
	message: 'No data to display',
	filter: defaultFilter,
	data: [],
	loading: false
};

const list = (state = initialState, action) => {
	switch (action.type) {
		case PatientGroupAction.SET_PATIENT_GROUP_FILTER: {
			return { ...state, filter: action.payload.filter };
		}
		case PatientGroupAction.RESET_PATIENT_GROUP_FILTER: {
			return { ...state, filter: defaultFilter };
		}
		case PatientGroupAction.SET_LIST_MESSAGE: {
			return { ...state, message: action.payload.message };
		}
		default: {
			return state;
		}
	}
};

export default list;
