import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import * as userActions from 'app/auth/store/actions';
import * as Actions from 'app/store/actions';
import { AuthService } from 'app/services';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '@history';
import { noSyncDataItem } from 'app/fuse-configs/navigationConfig';

const SYNC_STATUS = {
  NONE: 0,
  SYNCED: 1,
  NOT_SYNCED: 2,
};

class Auth extends Component {
	state = {
		waitAuthCheck: true
	};

	componentDidMount() {
		this.authCheck()
			.then(isAuth => {
				if (isAuth) {
					return this.props.getUserProfile();
				}
				return true;
			})
			.then(() => {
				this.setState({ waitAuthCheck: false });
			})
			.catch(e => {
				console.log('eeee', e);
			});

    // listen to login success event
    AuthService.on('loginSuccess', (needToChangePassword) => {
      this.props.getUserProfile().then(() => {
        if (needToChangePassword) {
          history.replace('/auth/change-password');
        } else {
          history.replace('/home');
        }
      });
    });
  }

  componentDidUpdate() {
    switch (this.props.syncStatus) {
      case SYNC_STATUS.SYNCED:
        this.props.resetNavigation();
        return;
      case SYNC_STATUS.NOT_SYNCED:
        this.props.updateNavigationItem('sync', noSyncDataItem, 'navigationBottomConfig');
        return;
      default:
        this.props.removeNavigationItem('sync', 'navigationBottomConfig');
    }
  }

  authCheck() {
    return new Promise((resolve) => {
      const lastTime = window.localStorage.getItem('lastTime');
      if (Number(lastTime) < Number(Date.now() - 5 * 60000)) {
        // auto log out after 5 minute
        resolve(false);
      } else {
        AuthService.on('checkSuccess', () => {
          resolve(true);
        });

        AuthService.on('checkFailed', () => {
          this.props.logout();
          resolve(false);
        });

        AuthService.checkAuth();
      }

			return Promise.resolve();
		});
	}

	render() {
		return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>;
	}
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: userActions.logoutUser,
      setUserData: userActions.setUserData,
      showMessage: Actions.showMessage,
      hideMessage: Actions.hideMessage,
      setRole: userActions.setRole,
      getUserProfile: userActions.getUserProfile,
      updateNavigationItem: Actions.updateNavigationItem,
      removeNavigationItem: Actions.removeNavigationItem,
      resetNavigation: Actions.resetNavigation,
    },
    dispatch,
  );
}
function mapStateToProps({ auth }) {
  return {
    syncStatus: auth.user.syncStatus,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
