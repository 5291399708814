import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from '../../../../../helpers/Error';

export const GET_DIRECTIONS = '@DIRECTION/GET_DIRECTIONS';
export const GET_DIRECTIONS_SUCCESS = '@DIRECTION/GET_DIRECTIONS_SUCCESS';
export const GET_DIRECTIONS_FAIL = '@DIRECTION/GET_DIRECTIONS_FAIL';

export const REMOVE_DIRECTION = '@DIRECTION/REMOVE_DIRECTION';
export const REMOVE_DIRECTION_SUCCESS = '@DIRECTION/REMOVE_DIRECTION_SUCCESS';
export const REMOVE_DIRECTION_FAIL = '@DIRECTION/REMOVE_DIRECTION_FAIL';

export const SET_DIRECTION_FILTER = '@DIRECTION/SET_DIRECTION_FILTER';
export const RESET_DIRECTION_FILTER = '@DIRECTION/RESET_DIRECTION_FILTER';

export const getDirection = () => {
	return dispatch => {
		dispatch({ type: GET_DIRECTIONS });
		HttpService.get(`api/directions`)
			.then(resp => {
				dispatch({
					type: GET_DIRECTIONS_SUCCESS,
					payload: { data: resp.data }
				});
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch({ type: GET_DIRECTIONS_FAIL, payload: { message: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

export const setDirectionFilter = filter => {
	return dispatch => {
		dispatch({ type: SET_DIRECTION_FILTER, payload: { filter } });
	};
};

export const resetDirectionFilter = () => {
	return dispatch => {
		dispatch({ type: RESET_DIRECTION_FILTER });
	};
};

export const removeDirection = directionId => {
	return dispatch => {
		HttpService.abort(`removeDirection`);
		dispatch({ type: REMOVE_DIRECTION });
		HttpService.delete(`api/directions/${directionId}`, { cancelToken: 'removeDirection' })
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'direction:ARCHIVE_DIRECTION_SUCCESS'
					})
				);
				dispatch({ type: REMOVE_DIRECTION_SUCCESS });
				dispatch(AppActions.dataLoader_reload(`GET:/api/directions`));
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch({ type: REMOVE_DIRECTION_FAIL, payload: { message: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};
