import * as ChangePasswordActions from '../actions/changePassword.actions';

const initialState = {
  loading: false,
  error: '',
  success: false,
};

const changePass = (state = initialState, action) => {
  switch (action.type) {
    case ChangePasswordActions.SEND_REQUEST:
      return { ...state, loading: true, error: '' };
    case ChangePasswordActions.SEND_REQUEST_SUCCESS:
      return { ...state, success: true, loading: false };
    case ChangePasswordActions.SEND_REQUEST_FAIL:
      return { ...state, error: action.payload.error, loading: false };
    case ChangePasswordActions.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default changePass;
