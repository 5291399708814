import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const CORRECT_GET_DOSE = '@JOB_CORRECT/CORRECT_GET_DOSE';
export const CORRECT_GET_DOSE_SUCCESS = '@JOB_CORRECT/CORRECT_GET_DOSE_SUCCESS';
export const CORRECT_GET_DOSE_FAIL = '@JOB_CORRECT/CORRECT_GET_DOSE_FAIL';

export const CORRECT_CONFIRM_PRESCRIPTION = '@JOB_CORRECT/CORRECT_CONFIRM_PRESCRIPTION';
export const CORRECT_CONFIRM_PRESCRIPTION_SUCCESS = '@JOB_CORRECT/CORRECT_CONFIRM_PRESCRIPTION_SUCCESS';
export const CORRECT_CONFIRM_PRESCRIPTION_FAIL = '@JOB_CORRECT/CORRECT_CONFIRM_PRESCRIPTION_FAIL';

export const CORRECT_COMPLETE = '@JOB_CORRECT/CORRECT_COMPLETE';
export const CORRECT_COMPLETE_SUCCESS = '@JOB_CORRECT/CORRECT_COMPLETE_SUCCESS';
export const CORRECT_COMPLETE_FAIL = '@JOB_CORRECT/CORRECT_COMPLETE_FAIL';

export const CORRECT_RESET_DATA = '@JOB_CORRECT/CORRECT_RESET_DATA';

export function getDoseAllocated(jobId) {
	return dispatch => {
		dispatch({ type: CORRECT_GET_DOSE });
		HttpService.get(`/api/packjobs/${jobId}/correction`)
			.then(resp => {
				dispatch({
					type: CORRECT_GET_DOSE_SUCCESS,
					payload: { data: resp.data }
				});
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
				dispatch({ type: CORRECT_GET_DOSE_FAIL, payload: { message: errorMessages.join('\n') } });
			});
	};
}

export function confirmPrescription(jobId, prescription) {
	return dispatch => {
		dispatch({ type: CORRECT_CONFIRM_PRESCRIPTION });
		return HttpService.get(
			`/api/packjobs/${jobId}/correction/${prescription.prescriptionId}/confirm/${!prescription.isCorrectionConfirm}`
		)
			.then(resp => {
				dispatch({
					type: CORRECT_CONFIRM_PRESCRIPTION_SUCCESS,
					payload: {
						prescriptionId: prescription.prescriptionId,
						isCorrectionConfirm: !prescription.isCorrectionConfirm
					}
				});
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
				dispatch({ type: CORRECT_CONFIRM_PRESCRIPTION_FAIL, payload: { message: errorMessages.join('\n') } });
			});
	};
}

export function completeCorrection(jobId) {
	return dispatch => {
		dispatch({ type: CORRECT_COMPLETE });
		return HttpService.get(`/api/packjobs/${jobId}/complete-correction`)
			.then(resp => {
				dispatch({ type: CORRECT_COMPLETE_SUCCESS });
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
				dispatch({ type: CORRECT_COMPLETE_FAIL, payload: { message: errorMessages.join('\n') } });
			});
	};
}

export function resetData() {
	return dispatch => {
		dispatch({ type: CORRECT_RESET_DATA });
	};
}
