import * as DashboardActions from '../actions';

const initState = {
  data: [],
  selectedDate: new Date(),
  loading: false,
  isCreateDone: false,
};

export const calendarReducer = (state = initState, actions) => {
  const { type, payload } = actions;

  switch (type) {
    case DashboardActions.GET_CALENDAR_SCHEDULE_LIST_SUCCESS:
      return { ...state, data: payload, loading: false };
    case DashboardActions.GET_CALENDAR_SELECTED_DATE:
      return { ...state, selectedDate: payload, loading: false };
    case DashboardActions.CREATE_CALENDAR:
      return { ...state, loading: true, isCreateDone: false };
    case DashboardActions.CREATE_CALENDAR_SUCCESS:
      return { ...state, isCreateDone: true, loading: false };
    case DashboardActions.CREATE_CALENDAR_FAIL:
      return { ...state, loading: false, isCreateDone: false };
    default:
      return state;
  }
};

export default calendarReducer;
