import * as PrintProfileCreateActions from '../actions/create.actions';

const initialState = {
	id: '',
	data: {
		clientCode: 0,
		packType: 0,
		name: '',
		topMargin: '0',
		leftMargin: '0',
		width: '0',
		height: '0',
		foilDrugNameLength: '0',
		alpacaFoilDirectionLength: '0',
		isPackDateOnFoil: false,
		isPackStartDate: false,
		isDrugWarning: false,
		isSamAsDrugInfo: false,
		isPackDoseTimeHeading: false,
		isPackBarcode: false,
		isUnpackedPrnMedArea: false
	},
	submitting: false,
	error: null,
	message: ''
};

const create = (state = initialState, action) => {
	switch (action.type) {
		case PrintProfileCreateActions.CREATE_PRINT_PROFILE: {
			return {
				...state,
				error: null,
				message: '',
				submitting: true
			};
		}
		case PrintProfileCreateActions.CREATE_PRINT_PROFILE_SUCCESS: {
			return {
				...state,
				id: action.payload.printProfileId,
				submitting: false
			};
		}
		case PrintProfileCreateActions.CREATE_PRINT_PROFILE_FAIL: {
			return {
				...state,
				message: action.payload.message,
				error: action.payload.error,
				submitting: false
			};
		}
		case PrintProfileCreateActions.CHANGE_PRINT_PROFILE_FORM:
			return {
				...state,
				data: {
					...state.data,
					...action.payload.data
				}
			};
		case PrintProfileCreateActions.CLEAR_CREATE_FORM:
			return initialState;
		default: {
			return state;
		}
	}
};

export default create;
