import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const SET_PATIENT_GROUP_FILTER = '@PATIENT_GROUP/SET_PATIENT_GROUP_FILTER';
export const RESET_PATIENT_GROUP_FILTER = '@PATIENT_GROUP/RESET_PATIENT_GROUP_FILTER';
export const SET_LIST_MESSAGE = '@PATIENT_GROUP/SET_LIST_MESSAGE';
export const DELETE_WARD = '@PATIENT_GROUP/DELETEWARD';
export const DELETE_WARD_FAIL = '@PATIENT_GROUP/DELETE_WARD_FAIL';
export const DELETE_WARD_SUCCESS = '@PATIENT_GROUP/DELETE_WARD_SUCCESS';

export const removePatientGroup = patientGroupId => {
	return dispatch => {
		HttpService.abort('removePatientGroup');
		HttpService.delete(`api/patientGroups/${patientGroupId}`, { cancelToken: 'removePatientGroup' })
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'patientGroup:ARCHIVE_PATIENT_GROUP_SUCCESS'
					})
				);
				dispatch(AppActions.dataLoader_reload(`GET:/api/patientGroups`));
			})
			.catch(err => {
				dispatch(
					AppActions.showMessage({
						message: 'Failed to update drug details',
						variant: 'error'
					})
				);
			});
	};
};

export const setPatientGroupFilter = filter => {
	return dispatch => {
		dispatch({ type: SET_PATIENT_GROUP_FILTER, payload: { filter } });
	};
};

export const resetPatientFilter = () => {
	return dispatch => {
		dispatch({ type: RESET_PATIENT_GROUP_FILTER });
	};
};

export const setListMessage = message => {
	return dispatch => {
		dispatch({ type: SET_LIST_MESSAGE, payload: { message } });
	};
};

export const deleteWard = (ward) => {
  return (dispatch) => {
    dispatch({ type: DELETE_WARD });
    return HttpService.delete(`/api/wards/${ward}`)
      .then(() => {
        dispatch({ type: DELETE_WARD_SUCCESS });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};
