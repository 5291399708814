import * as DoctorActions from '../actions/importDoctor.actions';

const defaultDoctorForm = {
	dispenseStagingId: '',
	firstName: '',
	lastName: '',
	address: '',
	fax: '',
	phone: '',
	pbsApproval: '',
	email: '',
	outOfHour: ''
};

const initialState = {
	submitting: false,
	submitSuccess: false,
	doctorForm: defaultDoctorForm,
	error: null,
	message: '',
	id: null
};

const create = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		// Get Doctor
		case DoctorActions.IMPORT_GET_DOCTOR:
			return { ...state, submitting: true };
		case DoctorActions.IMPORT_GET_DOCTOR_SUCCESS:
			return { ...state, submitting: false, doctorForm: payload };
		case DoctorActions.IMPORT_GET_DOCTOR_FAIL:
			return { ...state, submitting: false };
		// Import
		case DoctorActions.IMPORT_CREATE_DOCTOR:
			return { ...state, submitting: true, error: null };
		case DoctorActions.IMPORT_CREATE_DOCTOR_SUCCESS:
			return { ...state, submitting: false, id: payload.doctorId };
		case DoctorActions.IMPORT_CREATE_DOCTOR_FAIL:
			return { ...state, submitting: false, message: payload.message, error: payload.error };
		// Clear form
		case DoctorActions.IMPORT_CLEAR_DOCTOR_FORM:
			return initialState;
		// Change form
		case DoctorActions.IMPORT_CHANGE_DOCTOR_FORM:
			return { ...state, doctorForm: { ...state.doctorForm, ...payload.data } };
		default:
			return state;
	}
};

export default create;
