import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import { MENU_ENUM } from 'app/main/role/constants';

const RedirectPharmacyConfig = {
	settings: {
		layout: {
			config: {}
		},
		nav: {
			title: 'Redirect Pharmacy'
		}
	},
	auth: authRoles.user,
	key: MENU_ENUM.PHARMACY,
	permission: PERMISSION.UPDATE,
	routes: [
		{
			exact: true,
			path: '/pharmacy/redirect',
			component: React.lazy(() => import('./RedirectPharmacy'))
		}
	]
};

export default RedirectPharmacyConfig;
