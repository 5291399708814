import * as PharmacyDataUploadActions from '../actions/update.dataUpload.actions';

const initialState = {
	loading: false
};

const dataUpload = (state = initialState, action) => {
	const { type } = action;
	switch (type) {
		case PharmacyDataUploadActions.START_DATA_MIGRATION:
			return { ...state, loading: true };
		case PharmacyDataUploadActions.END_DATA_MIGRATION:
			return { ...state, loading: false };
		case PharmacyDataUploadActions.START_DISPENSE_UPLOAD:
			return { ...state, loading: true };
		case PharmacyDataUploadActions.END_DISPENSE_UPLOAD:
			return { ...state, loading: false };
		case PharmacyDataUploadActions.START_REQUEST_SYNC:
			return { ...state, loading: true };
		case PharmacyDataUploadActions.END_REQUEST_SYNC:
			return { ...state, loading: false };
		default:
			return state;
	}
};

export default dataUpload;
