import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import { MENU_ENUM } from 'app/main/role/constants';
import i18next from 'i18next';
import en from '../i18n/en';

i18next.addResourceBundle('en', 'sync', en);

const SyncConfig = {
  settings: {
    layout: {
      config: {},
    },
    nav: {
      title: 'Sync Data and Status',
    },
  },
  auth: authRoles.user,
  key: MENU_ENUM.SYNC,
  permission: PERMISSION.READ,
  routes: [
    {
      path: '/sync',
      component: React.lazy(() => import('./Sync')),
    },
  ],
};

export default SyncConfig;
