import * as UserActions from '../actions';

const initialState = {
  role: [], // guest
  data: {},
  permission: {},
  syncStatus: null,
  dispensingSystem: -1, // None
  syncVersion: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case UserActions.SET_ROLE: {
      return {
        ...state,
        role: action.payload.role,
      };
    }
    case UserActions.SET_USER_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case UserActions.REMOVE_USER_DATA: {
      return {
        ...initialState,
      };
    }
    case UserActions.USER_LOGGED_OUT: {
      return initialState;
    }
    case UserActions.SET_PERMISSION: {
      return { ...state, permission: action.payload.permission };
    }
    case UserActions.SET_SYNC_STATUS: {
      return { ...state, syncStatus: action.payload.syncStatus, syncVersion: action.payload.syncVersion };
    }
    case UserActions.SET_DISPENSING_SYSTEM: {
      return { ...state, dispensingSystem: action.payload.dispensingSystem };
    }
    default: {
      return state;
    }
  }
};

export default user;
