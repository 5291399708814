import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { endOfYear, formatISO9075, startOfYear } from 'date-fns';
import { parseError } from 'helpers/Error';

export const GET_SCHEDULE_LIST = '@DASHBOARD/GET_SCHEDULE_LIST';
export const GET_SCHEDULE_LIST_SUCCESS = '@DASHBOARD/GET_SCHEDULE_LIST_SUCCESS';
export const EDIT_SCHEDULE = '@DASHBOARD/EDIT_SCHEDULE';
export const EDIT_SCHEDULE_SUCCESS = '@DASHBOARD/EDIT_SCHEDULE_SUCCESS';
export const EDIT_SCHEDULE_FAIL = '@DASHBOARD/EDIT_SCHEDULE_FAIL';
export const DELETE_SCHEDULE = '@DASHBOARD/DELETE_SCHEDULE';
export const DELETE_SCHEDULE_SUCCESS = '@DASHBOARD/DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_FAIL = '@DASHBOARD/DELETE_SCHEDULE_FAIL';

export const getScheduleList = (date) => {
  const parseDate = {
    startDate: formatISO9075(date?.startDate || startOfYear(new Date())),
    endDate: formatISO9075(date?.endDate || endOfYear(new Date())),
  };
  return (dispatch) => {
    HttpService.get('api/schedules', parseDate).then((resp) => {
      dispatch({ type: GET_SCHEDULE_LIST_SUCCESS, payload: resp.data || [] });
    });
  };
};

export const editScheduleItem = (data, id) => {
  return (dispatch) => {
    dispatch({ type: EDIT_SCHEDULE });
    HttpService.put(`api/schedules/${id}`, data)
      .then(() => {
        dispatch({ type: EDIT_SCHEDULE_SUCCESS, payload: data });
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: DELETE_SCHEDULE_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const deleteSchedule = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_SCHEDULE });
    HttpService.delete(`api/schedules/${id}`)
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'Schedule has been deleted successfully',
          }),
        );
        dispatch({ type: DELETE_SCHEDULE_SUCCESS, payload: id });
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: DELETE_SCHEDULE_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};
