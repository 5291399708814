import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const BRAND_SWAP_BY_DRUG = '@BRAND_SWAP/BRAND_SWAP_BY_DRUG';
export const BRAND_SWAP_BY_DRUG_SUCCESS = '@BRAND_SWAP/BRAND_SWAP_BY_DRUG_SUCCESS';
export const BRAND_SWAP_BY_DRUG_FAIL = '@BRAND_SWAP/BRAND_SWAP_BY_DRUG_FAIL';

export const RESET_BRAND_SWAP_BY_DRUG_FORM = '@BRAND_SWAP/RESET_BRAND_SWAP_BY_DRUG_FORM';

export const CHANGE_FILTER = '@BRAND_SWAP/CHANGE_FILTER';

export function swapByDrug(data) {
  return (dispatch) => {
    dispatch({ type: BRAND_SWAP_BY_DRUG });
    HttpService.post(`api/drugs/brandswap`, data)
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'brandSwap:BRAND_SWAP_SUCCESS',
          }),
        );
        dispatch({ type: BRAND_SWAP_BY_DRUG_SUCCESS });
        // Reload drug
        dispatch(AppActions.dataLoader_reload(`GET:BRAND_SWAP_OLD_DRUG`));
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: BRAND_SWAP_BY_DRUG_FAIL,
          payload: { message: errorMessages, error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function resetSwapByDrugForm() {
	return dispatch => {
		dispatch({ type: RESET_BRAND_SWAP_BY_DRUG_FORM });
	};
}

export function changeFilter(filter) {
	return dispatch => {
		dispatch({ type: CHANGE_FILTER, payload: filter });
	};
}
