import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from '../../../../../helpers/Error';

export const ADD_ALLERGY = '@PATIENT/ADD_ALLERGY';
export const ADD_ALLERGY_SUCCESS = '@PATIENT/ADD_ALLERGY_SUCCESS';
export const ADD_ALLERGY_FAIL = '@PATIENT/ADD_ALLERGY_FAIL';

export const UPDATE_ALLERGY = '@PATIENT/UPDATE_ALLERGY';
export const UPDATE_ALLERGY_SUCCESS = '@PATIENT/UPDATE_ALLERGY_SUCCESS';
export const UPDATE_ALLERGY_FAIL = '@PATIENT/UPDATE_ALLERGY_FAIL';

export const DELETE_ALLERGY = '@PATIENT/DELETE_ALLERGY';
export const DELETE_ALLERGY_SUCCESS = '@PATIENT/DELETE_ALLERGY_SUCCESS';
export const DELETE_ALLERGY_FAIL = '@PATIENT/DELETE_ALLERGY_FAIL';

export const SET_SELECTED_ALLERGY = '@PATIENT/SET_SELECTED_ALLERGY';

export const UPDATE_NO_ALLERGY_SUCCESS = '@PATIENT/UPDATE_NO_ALLERGY_SUCCESS';

export const addAllergy = ({ target, reaction, patientId, drugId }) => {
  return (dispatch) => {
    dispatch({ type: ADD_ALLERGY });
    HttpService.post(`/api/patients/${patientId}/allergies`, { target, reaction, drugId })
      .then((resp) => {
        dispatch({
          type: ADD_ALLERGY_SUCCESS,
          payload: { newAllergy: { ...resp.data } },
        });
        dispatch(AppActions.showMessage({ message: 'patient:CREATE_ALLERGY_SUCCESS' }));
        dispatch(AppActions.dataLoader_reload('PatientAllergies'));
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: ADD_ALLERGY_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const updateAllergy = ({ allergyId, target, reaction, patientId, drugId }) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_ALLERGY });
    HttpService.put(`/api/patients/${patientId}/allergies/${allergyId}`, { target, reaction, drugId })
      .then((resp) => {
        dispatch({
          type: UPDATE_ALLERGY_SUCCESS,
          payload: { newAllergy: { ...resp.data } },
        });
        dispatch(AppActions.showMessage({ message: 'patient:UPDATE_ALLERGY_SUCCESS' }));
        dispatch(AppActions.dataLoader_reload('PatientAllergies'));
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: UPDATE_ALLERGY_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const deleteAllergy = ({ allergyId, patientId }) => {
	return dispatch => {
		dispatch({ type: DELETE_ALLERGY });
		HttpService.delete(`/api/patients/${patientId}/allergies/${allergyId}`)
			.then(resp => {
				dispatch({
					type: DELETE_ALLERGY_SUCCESS,
					payload: { allergyId }
				});
				dispatch(AppActions.showMessage({ message: 'patient:DELETE_ALLERGY_SUCCESS' }));
				dispatch(AppActions.dataLoader_reload('PatientAllergies'));
			})
			.catch(err => {
				const { errorMessages } = parseError(err);
				dispatch({ type: DELETE_ALLERGY_FAIL, payload: { message: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
};

export const setSelectedAllergy = allergy => {
	return dispatch => {
		dispatch({ type: SET_SELECTED_ALLERGY, payload: { allergy } });
	};
};

export const updateNoAllergy = ({ patientId, checked }) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_ALLERGY });
    HttpService.put(`/api/patients/${patientId}/allergies/nonallergy`, { IsNoAllergy: checked })
      .then((resp) => {
        dispatch({
          type: UPDATE_ALLERGY_SUCCESS,
          payload: { newAllergy: { ...resp.data } },
        });
        dispatch(AppActions.showMessage({ message: 'patient:UPDATE_ALLERGY_SUCCESS' }));
        dispatch({ type: UPDATE_NO_ALLERGY_SUCCESS, payload: checked });
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: UPDATE_ALLERGY_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};
