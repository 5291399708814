import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { endOfYear, formatISO9075, startOfYear } from 'date-fns';
import { parseError } from 'helpers/Error';

export const CREATE_CALENDAR = 'DASHBOARD/CREATE_CALENDAR';
export const CREATE_CALENDAR_SUCCESS = 'DASHBOARD/CREATE_CALENDAR_SUCCESS';
export const CREATE_CALENDAR_FAIL = 'DASHBOARD/CREATE_CALENDAR_FAIL';

export const GET_CALENDAR_SCHEDULE_LIST_SUCCESS = 'DASHBOARD/GET_CALENDAR_SCHEDULE_LIST_SUCCESS';
export const GET_CALENDAR_SELECTED_DATE = 'DASHBOARD/GET_CALENDAR_SELECTED_DATE';

export const CLEAR_HISTOY_CALENDAR = 'DASHBOARD/CLEAR_HISTORY_CALENDAR';

export const createCalendar = (data) => {
  return (dispatch) => {
    dispatch({ type: CREATE_CALENDAR });
    HttpService.post('api/schedules', data)
      .then(() => {
        dispatch({ type: CREATE_CALENDAR_SUCCESS });
        dispatch(getCalendarScheduleList());
        dispatch(
          AppActions.showMessage({
            message: 'dashboard:CREATE_CALENDAR_SUCCESS',
          }),
        );
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: CREATE_CALENDAR_FAIL, payload: { message: errorMessages } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const getCalendarScheduleList = (date) => {
  const parseDate = {
    startDate: formatISO9075(date?.startDate || startOfYear(new Date())),
    endDate: formatISO9075(date?.endDate || endOfYear(new Date())),
  };
  return (dispatch) => {
    HttpService.get('api/schedules', parseDate).then((resp) => {
      dispatch({ type: GET_CALENDAR_SCHEDULE_LIST_SUCCESS, payload: resp.data || [] });
    });
  };
};

export const getCalendarSelectedDate = (date) => {
  return (dispatch) => {
    dispatch({ type: GET_CALENDAR_SELECTED_DATE, payload: date || null });
  };
};
