import DateFnsUtils from '@date-io/date-fns';
import enAULocale from 'date-fns/locale/en-AU';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import FuseMessage from '@fuse/core/FuseMessage';
import history from '@history';
import { merge } from 'lodash';
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import React from 'react';
import Provider from 'react-redux/es/components/Provider';
import { Router } from 'react-router-dom';
import i18n from 'i18next';
import DialogMessage from 'components/DialogMessage';
import WebSocketMessage from 'components/WebSocketMessage';
import IdleTimer from 'components/IdleTimer';
import { StoreService } from 'app/services';
import { AppContextProvider } from './AppContext';
import { Auth } from './auth';
import store from './store';
import common from './i18n/common.en.json';
import validation from './i18n/validation.en.json';
import datatable from './i18n/datatable.en.json';
import idle from './i18n/idle.en.json';

i18n.addResourceBundle('en', 'common', merge(common, validation, datatable, idle));

const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName();

const setStore = s => StoreService.setStore(s);

const App = () => {
	return (
		<StylesProvider jss={jss} generateClassName={generateClassName}>
			<Provider store={store}>
				{setStore(store)}
				<AppContextProvider>
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={enAULocale}>
						<Auth>
							<IdleTimer>
								<Router history={history}>
									<FuseAuthorization>
										<FuseTheme>
											<FuseLayout />
											<DialogMessage />
											<WebSocketMessage />
											<FuseMessage />
										</FuseTheme>
									</FuseAuthorization>
								</Router>
							</IdleTimer>
						</Auth>
					</MuiPickersUtilsProvider>
				</AppContextProvider>
			</Provider>
		</StylesProvider>
	);
};

export default App;
