import { HttpService } from 'app/services';
import { parseError } from 'helpers/Error';

export const SEND_RECOVER_PASSWORD_REQUEST = '@FORGOT_PASSWORD/SEND_RECOVER_PASSWORD_REQUEST';
export const SEND_RECOVER_PASSWORD_REQUEST_SUCCESS = '@FORGOT_PASSWORD/SEND_RECOVER_PASSWORD_REQUEST_SUCCESS';
export const SEND_RECOVER_PASSWORD_REQUEST_FAILED = '@FORGOT_PASSWORD/SEND_RECOVER_PASSWORD_REQUEST_FAILED';
export const RESET_STATE = '@FORGOT_PASSWORD/RESET_STATE';

export const sendRequest = username => {
	return dispatch => {
		dispatch({ type: SEND_RECOVER_PASSWORD_REQUEST });
		HttpService.get(`/api/auth/recoverpass?username=${username}`)
			.then(() => {
				dispatch({ type: SEND_RECOVER_PASSWORD_REQUEST_SUCCESS });
			})
			.catch(err => {
				const { errorMessages } = parseError(err);
				dispatch({ type: SEND_RECOVER_PASSWORD_REQUEST_FAILED, payload: { error: errorMessages.join('\n') } });
			});
	};
};

export const resetState = () => {
	return dispatch => {
		dispatch({ type: RESET_STATE });
	};
};
