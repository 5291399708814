import * as DrugCreateActions from '../actions/create.actions';
import { DRUG_ROUTE_DEFAULT_VALUE } from '../../constants';

const initialState = {
	id: '',
	data: {
		drugCode: '',
		genericCode: '',
		drugName: '',
		imageId: '',
		imageName: '',
		supplier: '',
		strength: '',
		description: '',
		type: '',
		genericName: '',
		altBrand: '',
		schedule: '',
		isCytotoxic: 'false',
		isDoNotCrush: 'true',
		route: DRUG_ROUTE_DEFAULT_VALUE,
		packSize: '',
		shelfLife: '',
		drugGroupId: null,
		authorized: '0',
		usePrintingDrugName: false,
		printingDrugName: '',
		presentation: '',
		warning: '',
		direction: '',
		preferredBrand: []
	},
	loading: false,
	error: null,
	message: ''
};

const create = (state = initialState, action) => {
	switch (action.type) {
		case DrugCreateActions.CREATE_DRUG: {
			return {
				...state,
				error: null,
				message: '',
				loading: true
			};
		}
		case DrugCreateActions.CREATE_DRUG_SUCCESS: {
			return {
				...state,
				id: action.payload.drugId,
				loading: false
			};
		}
		case DrugCreateActions.CREATE_DRUG_FAIL: {
			return {
				...state,
				message: action.payload.message,
				error: action.payload.error,
				loading: false
			};
		}
		case DrugCreateActions.CHANGE_DRUG_FORM:
			return {
				...state,
				data: {
					...state.data,
					...action.payload.data
				}
			};
		case DrugCreateActions.CLEAR_CREATE_FORM:
			return initialState;
		default: {
			return state;
		}
	}
};

export default create;
