const locale = {
  TITLE: 'User Management',
  HEADER_TITLE: 'User Management',
  HEADER_SEARCH_TEXT_PLACEHOLDER: 'Search users...',
  HEADER_BUTTON_NEW_FULL: 'Add User',
  HEADER_BUTTON_NEW_SHORT: 'New',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  USER_NAME: 'Username',
  GENDER: 'Gender',
  DOB: 'Date of birth',
  ACTIONS: 'Actions',
  PASS_WORD: 'Password',
  EMAIL: 'Email',
  ACTIVE: 'Active',
  ARCHIVED: 'Archived',
  ADD_USER_SUCCESS: 'User has been added successfully!',
  ARCHIVE_USER_CONFIRM_CONTENT: 'Are you sure you want to archive user {{firstName}} {{lastName}}?',
  ARCHIVE_USER_CONFIRM_TITLE: 'Archive user',
  ROLE_LEVEL: 'Role Level',
  ROLES: 'Selected Roles',
  ROLES_PLACEHOLDER: 'All Roles',
  UPDATE_USER_SUCCESS: 'User has been updated successfully!',
  ARCHIVE_USER_SUCCESS: 'User has been archive successfully!',
  CHANGE_PASSWORD: 'Change User Password',
  RESEND_EMAIL_PASSWORD: 'Send Reset Password Email',
  NEW_PASSWORD: 'New Password',
  GENERATE: 'Generate',
  COPY: 'Copy',
  COPIED: 'Copied',
  AVAILABLE: 'Available:',
  USER_NAME_EXISTED: 'This username is taken. Try another.',
  EMAIL_EXISTED: 'This email is taken. Try another.',
  USER_DETAIL: 'User Detail',
  STATUS: 'Status',
  PHARMACY: 'Pharmacy',
  AVATAR: 'User Photo',
  RESEND_EMAIL_PASSWORD_TITLE: 'Resend Password Email',
  RESEND_EMAIL_PASSWORD_CONTENT: 'You will receive an email to reset your password. Are you sure you want to proceed?',
  RESEND_EMAIL_PASSWORD_MESSAGE: 'Resend password email successfully',
  VALIDATION_SPECIAL_CHARACTER_MESSAGE: 'User name only include letters, numbers, underscore and period.',
  SAVE: 'Save',
};

export default locale;
