import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import i18next from 'i18next';
import { MENU_ENUM } from 'app/main/role/constants';
import en from '../i18n/en';

i18next.addResourceBundle('en', 'printProfile', en);

const CreatePrintProfileConfig = {
	settings: {
		layout: {
			config: {}
		},
		nav: {
			title: 'Create Print Profile'
		}
	},
	auth: authRoles.user,
	key: MENU_ENUM.PRINT_PROFILE,
	permission: PERMISSION.CREATE,
	routes: [
		{
			path: '/print-profile/create',
			component: React.lazy(() => import('./CreatePrintProfile'))
		}
	]
};

export default CreatePrintProfileConfig;
