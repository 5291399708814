import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import i18next from 'i18next';
import { MENU_ENUM } from 'app/main/role/constants';
import en from '../i18n/en';

i18next.addResourceBundle('en', 'user', en);

const UpdateUserConfig = {
	settings: {
		layout: {
			config: {}
		},
		nav: {
			title: 'User Management'
		}
	},
	auth: authRoles.user,
	key: MENU_ENUM.USER,
	permission: PERMISSION.UPDATE,
	routes: [
		{
			path: '/user/:id',
			component: React.lazy(() => import('./UpdateUser'))
		}
	]
};

export default UpdateUserConfig;
