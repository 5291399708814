import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from '../../../../helpers/Error';

export const SEND_REQUEST = '@CHANGE_PASSWORD/SEND_REQUEST';
export const SEND_REQUEST_SUCCESS = '@CHANGE_PASSWORD/SEND_REQUEST_SUCCESS';
export const SEND_REQUEST_FAIL = '@CHANGE_PASSWORD/SEND_REQUEST_FAIL';
export const RESET_STATE = '@CHANGE_PASSWORD/RESET_STATE';

export const sendRequest = (newPassword) => {
  return (dispatch, getState) => {
    const { id } = getState().auth.user.data;
    dispatch({ type: SEND_REQUEST });

    const postData = {
      password: '',
      newPassword,
    };

    HttpService.put(`/api/users/updatepassword/${id}`, postData, { resolveStatus400: true })
      .then((resp) => {
        if (resp.isSuccess) {
          dispatch({ type: SEND_REQUEST_SUCCESS });
        } else {
          dispatch({
            type: SEND_REQUEST_FAIL,
            payload: { message: resp.message, error: { password: resp.message } },
          });
        }
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: SEND_REQUEST_FAIL, payload: { error: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'resetPass:REQUEST_FAIL_DIALOG_TITLE', errorMessages));
      });
  };
};

export const resetState = () => {
  return (dispatch) => {
    dispatch({ type: RESET_STATE });
  };
};
