import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControl, InputLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	readonlyText: {
		padding: props =>
			props.inline ? `${theme.spacing(0.625)}px 0` : `${theme.spacing(1.875)}px 0 ${theme.spacing(0.625)}px`
	},
	inline: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'baseline',
		'& .MuiTextField-root': {
			flex: 1
		},
		'& > label': {
			position: 'relative',
			transform: 'none',
			alignItems: 'center',
			display: 'flex',
			width: props => (props.labelSize ? `${props.labelSize}px` : '200px'),
			color: '#414141'
		},
		'& input': {
			padding: `${theme.spacing(0.85)}px ${theme.spacing(1.5)}px`,
			fontWeight: 600,
			fontSize: 12
		},
		'& fieldset': {
			borderRadius: props => (props.inline ? 2 : 4)
		},
		'& .MuiFormControlLabel-label': {
			fontWeight: props => (props.bold ? 700 : 400)
		}
	}
}));

const MyCheckbox = props => {
	const classes = useStyles(props);
	const { t } = useTranslation();
	const { label, disabled, readOnly, onCheck, onChange, inline, showValue, bold = true, ...rest } = props;

	const _onChange = e => {
		const { checked } = e.target;
		if (onChange) onChange(e);
		if (onCheck) onCheck(checked);
	};

	const renderControl = () => {
		return (
			<FormControlLabel
				control={
					<Checkbox type="checkbox" color="primary" size="small" onChange={_onChange} className="py-0" {...rest} />
				}
				label={label}
				disabled={disabled}
			/>
		);
	};

	const getDisplayValue = () => (rest.checked ? t('YES') : t('NO'));

	if (inline) {
		return (
			<FormControl fullWidth className={classes.inline}>
				{!readOnly && renderControl()}
				{readOnly && (
					<>
						<InputLabel className={clsx(bold && 'font-bold')}>{label}</InputLabel>
						{showValue && <Typography className={classes.readonlyText}>{getDisplayValue()}</Typography>}
					</>
				)}
			</FormControl>
		);
	}

	if (readOnly) {
		return (
			<FormControl fullWidth>
				<InputLabel shrink>{label}</InputLabel>
				<Typography className={classes.readonlyText}>{getDisplayValue()}</Typography>
			</FormControl>
		);
	}

	if (!label) {
		return <Checkbox type="checkbox" color="primary" size="small" disabled={disabled} onChange={_onChange} {...rest} />;
	}

	return renderControl();
};

MyCheckbox.defaultProps = {
	bold: true
};

export default MyCheckbox;
