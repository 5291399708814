// eslint-disable-next-line import/prefer-default-export
export const AUTHORISED_DRUG_TYPES = {
	NONE: '0',
	MANUAL: '1',
	CANISTER: '2'
};

export const AUTHORISED_DRUG = [
	{
		label: 'No',
		value: AUTHORISED_DRUG_TYPES.NONE
	},
	{
		label: 'Yes - Manual',
		value: AUTHORISED_DRUG_TYPES.MANUAL
	},
	{
		label: 'Yes - Canister',
		value: AUTHORISED_DRUG_TYPES.CANISTER
	}
];

export const SHAPE_TYPES = {
	CAPSULE: 'Capsule',
	CIRCULAR: 'Circular',
	DIAMOND_SHAPED: 'Diamond-shaped',
	ELLIPTICAL: 'Elliptical',
	ELONGATED: 'Elongated',
	OBLONG: 'Oblong',
	OVAL: 'Oval',
	OVALOID: 'Ovaloid',
	RECTANGULAR_SHAPED: 'Rectangular-shaped',
	ROD_SHAPED: 'Rod-shaped',
	ROUND: 'Round',
	SHIELD_SHAPED: 'Shield-shaped',
	SQUARE: 'Square',
	TRIANGULAR: 'Triangular'
};

export const SHAPE = [
	{
		label: 'Capsule',
		value: SHAPE_TYPES.CAPSULE
	},
	{
		label: 'Circular',
		value: SHAPE_TYPES.CIRCULAR
	},
	{
		label: 'Diamond-shaped',
		value: SHAPE_TYPES.DIAMOND_SHAPED
	},
	{
		label: 'Elliptical',
		value: SHAPE_TYPES.ELLIPTICAL
	},
	{
		label: 'Elongated',
		value: SHAPE_TYPES.ELONGATED
	},
	{
		label: 'Oblong',
		value: SHAPE_TYPES.OBLONG
	},
	{
		label: 'Oval',
		value: SHAPE_TYPES.OVAL
	},
	{
		label: 'Ovaloid',
		value: SHAPE_TYPES.OVALOID
	},
	{
		label: 'Rectangular-shaped',
		value: SHAPE_TYPES.RECTANGULAR_SHAPED
	},
	{
		label: 'Rod-shaped',
		value: SHAPE_TYPES.ROD_SHAPED
	},
	{
		label: 'Round',
		value: SHAPE_TYPES.ROUND
	},
	{
		label: 'Shield-shaped',
		value: SHAPE_TYPES.SHIELD_SHAPED
	},
	{
		label: 'Square',
		value: SHAPE_TYPES.SQUARE
	},
	{
		label: 'Triangular',
		value: SHAPE_TYPES.TRIANGULAR
	}
];

export const COLOUR_TYPES = {
	BEIGE: 'Beige',
	BLUE: 'Blue',
	BROWN: 'Brown',
	GREEN: 'Green',
	GREY: 'Grey',
	LIGHT_GREEN: 'Light Green',
	LIGHT_PINK: 'Light Pink',
	LIGHT_YELLOW: 'Light Yellow',
	OFF_WHITE: 'Off-White',
	ORANGE: 'Orange',
	PALE_YELLOW: 'Pale Yellow',
	PEACH: 'Peach',
	PINK: 'Pink',
	PURPLE: 'Purple',
	RED: 'Red',
	RED_BROWN: 'Red-Brown',
	VIOLET: 'Violet',
	WHITE: 'White',
	WHITE_TO_OFF_WHITE: 'White To Off-White',
	YELLOW: 'Yellow'
};

export const COLOUR = [
	{
		label: 'Beige',
		value: COLOUR_TYPES.BEIGE
	},
	{
		label: 'Blue',
		value: COLOUR_TYPES.BLUE
	},
	{
		label: 'Brown',
		value: COLOUR_TYPES.BROWN
	},
	{
		label: 'Green',
		value: COLOUR_TYPES.GREEN
	},
	{
		label: 'Grey',
		value: COLOUR_TYPES.GREY
	},
	{
		label: 'Light Green',
		value: COLOUR_TYPES.LIGHT_GREEN
	},
	{
		label: 'Light Pink',
		value: COLOUR_TYPES.LIGHT_PINK
	},
	{
		label: 'Light Yellow',
		value: COLOUR_TYPES.LIGHT_YELLOW
	},
	{
		label: 'Off-White',
		value: COLOUR_TYPES.OFF_WHITE
	},
	{
		label: 'Orange',
		value: COLOUR_TYPES.ORANGE
	},
	{
		label: 'Pale Yellow',
		value: COLOUR_TYPES.PALE_YELLOW
	},
	{
		label: 'Purple',
		value: COLOUR_TYPES.PURPLE
	},
	{
		label: 'Pink',
		value: COLOUR_TYPES.PINK
	},
	{
		label: 'Red',
		value: COLOUR_TYPES.RED
	},
	{
		label: 'Red-Brown',
		value: COLOUR_TYPES.RED_BROWN
	},
	{
		label: 'Violet',
		value: COLOUR_TYPES.VIOLET
	},
	{
		label: 'White',
		value: COLOUR_TYPES.WHITE
	},
	{
		label: 'White To Off-White',
		value: COLOUR_TYPES.WHITE_TO_OFF_WHITE
	},
	{
		label: 'Yellow',
		value: COLOUR_TYPES.YELLOW
	}
];

export const IS_CYTOTOXIC_TYPES = {
	YES: 'true',
	NO: 'false'
};

export const IS_CYTOTOXIC = [
	{
		label: 'Yes',
		value: IS_CYTOTOXIC_TYPES.YES
	},
	{
		label: 'No',
		value: IS_CYTOTOXIC_TYPES.NO
	}
];

export const IS_DO_NOT_CRUSH_TYPES = {
	YES: 'true',
	NO: 'false'
};

export const IS_DO_NOT_CRUSH = [
	{
		label: 'Yes',
		value: IS_DO_NOT_CRUSH_TYPES.YES
	},
	{
		label: 'No',
		value: IS_DO_NOT_CRUSH_TYPES.NO
	}
];

export const DRUG_ROUTE_DEFAULT_VALUE = { code: 'PO', description: 'Oral' };

export const DRUG_STATUS = {
	PACKED: 'PACKED',
	PACKED_PRN: 'PACKED_PRN',
	CEASED: 'CEASED'
};

export const DRUG_STATUS_OPTIONS = [
	{ label: 'Packed', value: DRUG_STATUS.PACKED },
	{ label: 'Packed PRN', value: DRUG_STATUS.PACKED_PRN },
	{ label: 'Ceased/Deleted', value: DRUG_STATUS.CEASED }
];
