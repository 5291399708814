import React from 'react';
import { MENU_ENUM } from 'app/main/role/constants';
import { authRoles, PERMISSION } from 'app/auth';

const UpdateDoctorConfig = {
	settings: {
		layout: {
			config: {}
		},
		nav: {
			title: 'Update Doctor'
		}
	},
	auth: authRoles.user,
	key: MENU_ENUM.DOCTOR,
	permission: PERMISSION.UPDATE,
	routes: [
		{
			path: '/doctor/:id',
			component: React.lazy(() => import('./UpdateDoctor'))
		}
	]
};

export default UpdateDoctorConfig;
