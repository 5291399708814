import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from '../../../../../helpers/Error';

export const GET_FOILS = '@PATIENT/GET_FOILS';
export const GET_FOILS_SUCCESS = '@PATIENT/GET_FOILS_SUCCESS';
export const GET_FOILS_FAIL = '@PATIENT/GET_FOILS_FAIL';

export const ADD_FOIL = '@PATIENT/ADD_FOIL';
export const ADD_FOIL_SUCCESS = '@PATIENT/ADD_FOIL_SUCCESS';
export const ADD_FOIL_FAIL = '@PATIENT/ADD_FOIL_FAIL';

export const DELETE_FOIL = '@PATIENT/DELETE_FOIL';
export const DELETE_FOIL_SUCCESS = '@PATIENT/DELETE_FOIL_SUCCESS';
export const DELETE_FOIL_FAIL = '@PATIENT/DELETE_FOIL_FAIL';

export const GET_NEXT_FOIL_LABEL = '@PATIENT/GET_NEXT_FOIL_LABEL';
export const GET_NEXT_FOIL_LABEL_SUCCESS = '@PATIENT/GET_NEXT_FOIL_LABEL_SUCCESS';
export const GET_NEXT_FOIL_LABEL_FAIL = '@PATIENT/GET_NEXT_FOIL_LABEL_FAIL';

export const RESET_NEXT_FOIL_LABEL = '@PATIENT/RESET_NEXT_FOIL_LABEL';

export const UPDATE_FOIL = '@PATIENT/UPDATE_FOIL';
export const UPDATE_FOIL_SUCCESS = '@PATIENT/UPDATE_FOIL_SUCCESS';
export const UPDATE_FOIL_FAIL = '@PATIENT/UPDATE_FOIL_FAIL';

export const SET_SELECTED_FOIL_INDEX = '@PATIENT/SET_SELECTED_FOIL_INDEX';

export const SHOW_PRESCRIPTION_FORM = '@PATIENT/SHOW_PRESCRIPTION_FORM';
export const HIDE_PRESCRIPTION_FORM = '@PATIENT/HIDE_PRESCRIPTION_FORM';

const draftFoil = {
	patientId: '',
	comment: '',
	alert: '',
	label: '',
	warningLabel: '',
	isActive: true,
	heading: [],
	foilType: 0,
	client: 0,
	packType: 0,
	dose: 0,
	frequency: 0
};

export const getFoils = patientId => {
	return dispatch => {
		dispatch({ type: GET_FOILS });
		HttpService.get(`api/foils`, { patientId })
			.then(resp => {
				const newData = resp.data.map(foil => {
					return Object.keys(foil).reduce((rs, i) => {
						return { ...rs, [i]: foil[i] ?? '' };
					}, {});
				});

				dispatch({
					type: GET_FOILS_SUCCESS,
					payload: { foils: newData }
				});
			})
			.catch(err => {
				const { errorMessages } = parseError(err);
				dispatch({ type: GET_FOILS_FAIL, payload: { message: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
};

export const addFoil = ({ patientId, foil }) => {
	return (dispatch, getState) => {
		dispatch({ type: ADD_FOIL });
		const { data } = getState().patient.update.medicationProfiles;
		const payload = {
			...draftFoil,
			patientId,
			...foil
		};
		HttpService.post(`api/foils`, payload)
			.then(resp => {
				dispatch({
					type: ADD_FOIL_SUCCESS,
					payload: {
						newFoil: {
							...resp.data
						}
					}
				});
				dispatch(
					AppActions.showMessage({
						message: 'patient:ADD_FOIL_SUCCESS_MESSAGE'
					})
				);
				const nextFoilIndex = data.length;
				dispatch({ type: SET_SELECTED_FOIL_INDEX, payload: { index: nextFoilIndex } });
			})
			.catch(err => {
				const { errorMessages } = parseError(err);
				dispatch({ type: ADD_FOIL_FAIL, payload: { message: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
};

export const updateFoil = (data, patientId) => {
	return dispatch => {
		dispatch({ type: UPDATE_FOIL });
		const payload = {
			...draftFoil,
			...data,
			patientId
		};
		HttpService.put(`api/foils/${data.foilId}`, payload)
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'patient:UPDATE_FOIL_SUCCESS_MESSAGE'
					})
				);
				dispatch({
					type: UPDATE_FOIL_SUCCESS,
					payload: { data }
				});
			})
			.catch(err => {
				const { errorMessages } = parseError(err);
				dispatch({ type: UPDATE_FOIL_FAIL, payload: { message: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
};

export const deleteFoil = foilId => {
	return (dispatch, getState) => {
		const { selectedFoilIndex } = getState().patient.update.medicationProfiles;
		dispatch({ type: DELETE_FOIL });
		HttpService.delete(`api/foils/${foilId}`)
			.then(() => {
				const nextFoilIndex = selectedFoilIndex > -1 ? selectedFoilIndex - 1 : -1;
				dispatch({ type: SET_SELECTED_FOIL_INDEX, payload: { index: nextFoilIndex } });
				dispatch(
					AppActions.showMessage({
						message: 'patient:DELETE_FOIL_SUCCESS_MESSAGE'
					})
				);
				dispatch({
					type: DELETE_FOIL_SUCCESS,
					payload: { foilId }
				});
			})
			.catch(err => {
				const { errorMessages } = parseError(err);
				dispatch({ type: DELETE_FOIL_FAIL, payload: { message: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
};

export const getNextFoilLabel = (patientId, foilType) => {
  return (dispatch) => {
    dispatch({ type: GET_NEXT_FOIL_LABEL });
    HttpService.get(`/api/foils/next-label?patientId=${patientId}&foilType=${foilType}`)
      .then((resp) => {
        dispatch({
          type: GET_NEXT_FOIL_LABEL_SUCCESS,
          payload: { nextLabel: resp.label, packTypeDefault: resp.packTypeDefault },
        });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: GET_NEXT_FOIL_LABEL_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const resetNextFoilLabel = () => {
	return dispatch => {
		dispatch({ type: RESET_NEXT_FOIL_LABEL });
	};
};

export const setSelectedFoilIndex = index => {
	return dispatch => {
		dispatch({ type: SET_SELECTED_FOIL_INDEX, payload: { index } });
	};
};

export const showPrescriptionForm = () => {
	return dispatch => {
		dispatch({ type: SHOW_PRESCRIPTION_FORM });
	};
};

export const hidePrescriptionForm = () => {
	return dispatch => {
		return new Promise(resolve => {
			dispatch({ type: HIDE_PRESCRIPTION_FORM });
			resolve();
		});
	};
};
