import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const CREATE_DOCTOR = '@DOCTOR/CREATE_DOCTOR';
export const CREATE_DOCTOR_SUCCESS = '@DOCTOR/CREATE_DOCTOR_SUCCESS';
export const CREATE_DOCTOR_FAIL = '@DOCTOR/CREATE_DOCTOR_FAIL';

export const CHANGE_DOCTOR_CREATE_FORM = '@DOCTOR/CHANGE_DOCTOR_CREATE_FORM';

export const CLEAR_CREATE_FORM = '@DOCTOR/CLEAR_CREATE_FORM';

export const createDoctor = () => {
	return (dispatch, getState) => {
		const { doctorForm } = getState().doctor.create;
		dispatch({ type: CREATE_DOCTOR });
		return HttpService.post(`api/doctors`, doctorForm)
			.then(resp => {
				dispatch(
					AppActions.showMessage({
						message: 'doctor:ADD_DOCTOR_SUCCESS'
					})
				);
				dispatch({ type: CREATE_DOCTOR_SUCCESS, payload: { doctorId: resp.data.id } });
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch({
					type: CREATE_DOCTOR_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields }
				});
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

export const clearCreateForm = () => {
	return dispatch => {
		dispatch({ type: CLEAR_CREATE_FORM });
	};
};

export const changeDoctorCreateForm = data => {
	return dispatch => {
		dispatch({ type: CHANGE_DOCTOR_CREATE_FORM, payload: { data } });
	};
};
