export const isValidPhoneNumber = phoneNumber =>
	/^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/gi.test(
		phoneNumber
	);

/**
 * Only allow Length > 0
 * else - allow any length
 */
export const isValidPhone = phoneNumber => /^[0-9 ]*$/gi.test(phoneNumber);

export const isValidFaxNumber = faxNumber =>
	/^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/gi.test(
		faxNumber
	);

export const isValidEmail = email => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const isValidString = string => !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(string);

/**
 * Valid:
 * 1
 * .424234
 * 4/5
 */
export const isValidNumber = number => /^\-?([0-9]+)$|^\-?([0-9]+[\/\.][0-9]+)$|^(\.[0-9]+)$/g.test(number);

export const isFractionNumber = number => /^([0-9]+[\/][0-9]+$)/g.test(number);

/**
 * Valid:
 * 123.
 * 96.
 */
export const isHalfDecimalNumber = number => /^([0-9]+[\.]$)/g.test(number);

/**
 * Valid:
 * C:\Users\Tam\Downloads
 * \\Users\Tam\Downloads
 */
export const isValidFolderPath = (path) =>
  /^(?<ParentPath>(?:[\w\s\]\\:|\\\\[\w\s\]+\\[\w\s\]*)\\(?:[\w\s\.]+\\)*)(?<BaseName>[\w\s\.]*?)$/gim.test(path);

/**
 * Valid:
 * 123
 * -23
 */
export const isValidInteger = (number) => /^[-+]?[0-9]+$/g.test(number);

export const isValidPositive = (number) => /^[+]?[1-9]+$/g.test(number);

export const isValidUsername = userName => /^[a-zA-Z0-9]+[._]?[a-zA-Z0-9]+$/g.test(userName);
