import * as DashboardActions from '../actions';

const initState = {
  data: [],
  loading: false,
  isEditDone: false,
  isDeleted: false,
};

const scheduleReducer = (state = initState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case DashboardActions.GET_SCHEDULE_LIST_SUCCESS: {
      return { ...state, data: payload, loading: false };
    }
    case DashboardActions.EDIT_SCHEDULE: {
      return { ...state, loading: true, isEditDone: false };
    }
    case DashboardActions.EDIT_SCHEDULE_SUCCESS: {
      return { ...state, loading: false, isEditDone: true };
    }
    case DashboardActions.EDIT_SCHEDULE_FAIL: {
      return { ...state, loading: false, isEditDone: false };
    }
    case DashboardActions.DELETE_SCHEDULE: {
      return { ...state, loading: true, isDeleted: false };
    }
    case DashboardActions.DELETE_SCHEDULE_SUCCESS: {
      return { ...state, loading: false, data: state.data.filter((item) => item.id !== payload), isDeleted: true };
    }
    case DashboardActions.DELETE_SCHEDULE_FAIL: {
      return { ...state, loading: false, isDeleted: false };
    }
    default:
      return state;
  }
};

export default scheduleReducer;
