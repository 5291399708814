const locale = {
  TITLE: 'RENEW YOUR PASSWORD',
  DESCRIPTION:'You are required to renew your password every 3 months',
  PASSWORD_LABEL: 'Password',
  PASSWORD_REQUIRE_ERROR: 'Password is required',
  PASSWORD_CONFIRM_LABEL: 'Confirm Password',
  PASSWORD_CONFIRM_INVALID_ERROR: 'Confirm Password does not match',
  BUTTON_RESET_PASSWORD: 'Renew Password',
  BUTTON_CONTINUE: 'CONTINUE',
  LINK_SKIP_TO_CHANGE: 'Skip',
  REQUEST_FAIL_DIALOG_TITLE: 'Opps! Something has happened',
  REQUEST_SUCCESS_MESSAGE: 'Password has been successfully renewed!',
};

export default locale;
