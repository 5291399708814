import * as AppActions from 'app/store/actions';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from 'components/Button';
import clsx from 'clsx';

import TextField from 'components/TextField';
import { useDispatch } from 'react-redux';
import { HttpService } from 'app/services';
import { parseError } from 'helpers/Error';
import { trim } from 'lodash';
import LogoShort from './assets/myPak-NG-Logo-NG.png';

const useStyles = makeStyles(() => ({
  input: {
    '&::placeholder': {
      color: '#000000',
    },
    '&.Mui-disabled, textarea:hover': {
      cursor: 'text !important',
    },
  },
  left: {
    '&.MuiDialogTitle-root': {
      padding: '12px 0',
    },
  },
  subtitle: {
    fontSize: '1.6rem',
  },
  caption: {
    fontSize: '1.34rem',
  },
  nolink: {
    textDecoration: 'none',
    color: '#4f52b2',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  isDoneFeedback: {
    '&::placeholder': {
      opacity: 1,
    },
  },
}));
function Feedback() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [textContent, setTextContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const handleClose = () => {
    setOpen(false);
    history.goBack();
  };
  const handleChange = (ev) => {
    setTextContent(ev.target.value);
  };

  const handleSave = () => {
    setLoading(true);
    HttpService.post('api/helpdesk/send-feedback', { content: trim(textContent) })
      .then(() => {
        setLoading(false);
        setTextContent('');
        setIsDone(true);
      })
      .catch((err) => {
        setLoading(false);
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth scroll="paper" disableBackdropClick onClose={handleClose}>
      <DialogTitle className={clsx(classes.left)}>
        <div className={clsx('flex direction-row nowrap w-7/12 justify-between')}>
          <span className={clsx('ml-12')}>
            <img src={LogoShort} width={35} alt="myPak" />
          </span>
          <div className={clsx('mr-12')}>{t('feedback:TITLE')}</div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.contentDialog}>
        <div className={clsx('flex flex-col gap-4 pb-36')}>
          {!isDone ? (
            <>
              <div className={clsx('mb-12')}>
                <Typography variant="h6" className={classes.subtitle}>
                  {t('feedback:GIVE_FEEDBACK_SUBTITLE')}
                </Typography>
              </div>
              <div className={clsx('mb-8')}>
                <Typography variant="body2" className={classes.caption}>
                  {t('feedback:GIVE_FEEDBACK_HEADER_CAPTION')}
                </Typography>
              </div>
            </>
          ) : (
            <>
              <div className={clsx('mb-12')}>
                <Typography variant="subtitle2">{t('feedback:THANK_YOU')}</Typography>
              </div>
            </>
          )}
          <div>
            <TextField
              type="text"
              name="password"
              variant="outlined"
              required
              fullWidth
              multiline
              disabled={isDone}
              placeholder={isDone ? t('feedback:FEEDBACK_COMPLETED') : t('feedback:FEEDBACK_INPUT_PLACEHOLDER')}
              rows={8}
              InputProps={{
                classes: { input: clsx(classes.input, isDone && classes.isDoneFeedback) },
              }}
              autoFocus
              onChange={handleChange}
              value={textContent}
            />
          </div>
          {!isDone && (
            <div className={clsx('mt-16')}>
              <Typography variant="body2" className={classes.caption}>
                {t('feedback:GIVE_FEEDBACK_FOOTER_CAPTION')}
              </Typography>
              <Typography>
                <a
                  className={clsx(classes.nolink, classes.caption)}
                  role="button"
                  href="https://mypaksolutions.com.au/privacy-policy/"
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                >
                  {t('feedback:POLICY_OF_MYPAK')}
                </a>
              </Typography>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {isDone ? (
          <Button type="button" className={clsx('mr-8')} onClick={handleClose}>
            {t('BUTTON_CLOSE')}
          </Button>
        ) : (
          <>
            <Button type="button" className={clsx('mr-8')} onClick={handleClose}>
              {t('BUTTON_CANCEL')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={textContent.length === 0}
              type="button"
              progress={loading}
              onClick={handleSave}
            >
              {t('feedback:SEND_TO_MYPAK_BUTTON')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default Feedback;
