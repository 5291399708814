import { isAfter, format } from 'date-fns';
import AppConfig from 'app/Config';
import { ZERO_DATE } from '../constants/DateTimeFormat';

export const arrayToObject = (array, keyField) =>
	array.reduce((obj, item) => {
		obj[item[keyField]] = item;
		return obj;
	}, {});

// prevent min date error when server returns 01/01/0001
export const parseValidDate = (val) => (val !== null && isAfter(new Date(val), AppConfig.minDate) ? val : null);

export const formatDate = (val, outputFormat) => {
	if (val === ZERO_DATE) {
		return '';
	}
	return format(new Date(val), outputFormat);
};

export const parseParams = params => {
	const keys = Object.keys(params);
	let options = '';

	keys.forEach(key => {
		const isParamTypeObject = typeof params[key] === 'object';
		const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

		if (!isParamTypeObject) {
			options += `${key}=${params[key]}&`;
		}

		if (isParamTypeObject && isParamTypeArray) {
			params[key].forEach(element => {
				options += `${key}=${element}&`;
			});
		}
	});

	return options ? options.slice(0, -1) : options;
};

export const shortenStringWithThreeDot = (stringVal, length) => {
  if (stringVal && stringVal.length > length) {
    return stringVal.substring(0, length).concat('...');
  }
  return stringVal;
};
