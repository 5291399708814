import { get, toString, toInteger, omit } from 'lodash';
import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const GET_PATIENT_GROUP = '@PATIENT_GROUP/GET_PATIENT_GROUP';
export const GET_PATIENT_GROUP_SUCCESS = '@PATIENT_GROUP/GET_PATIENT_GROUP_SUCCESS';
export const GET_PATIENT_GROUP_FAIL = '@PATIENT_GROUP/GET_PATIENT_GROUP_FAIL';
// packOptions
export const GET_PACK_OPTION = '@PATIENT_GROUP/GET_PACK_OPTION';
export const GET_PACK_OPTION_SUCCESS = '@PATIENT_GROUP/GET_PACK_OPTION_SUCCESS';
export const GET_PACK_OPTION_FAIL = '@PATIENT_GROUP/GET_PACK_OPTION_FAIL';

export const DELETE_PATIENT_GROUP_START = '@PATIENT_GROUP/DELETE_PATIENT_GROUP_START';
export const DELETE_PATIENT_GROUP_DONE = '@PATIENT_GROUP/DELETE_PATIENT_GROUP_DONE';

export const UPDATE_PATIENT_GROUP = '@PATIENT_GROUP/UPDATE_PATIENT_GROUP';
export const UPDATE_PATIENT_GROUP_SUCCESS = '@PATIENT_GROUP/UPDATE_PATIENT_GROUP_SUCCESS';
export const UPDATE_PATIENT_GROUP_FAIL = '@PATIENT_GROUP/UPDATE_PATIENT_GROUP_FAIL';

export const CHANGE_PATIENT_GROUP_UPDATE_FORM = '@PATIENT_GROUP/CHANGE_PATIENT_GROUP_UPDATE_FORM';
// packOptions
export const UPDATE_PACK_OPTION = '@PATIENT_GROUP/UPDATE_PACK_OPTION';
export const UPDATE_PACK_OPTION_SUCCESS = '@PATIENT_GROUP/UPDATE_PACK_OPTION_SUCCESS';
export const UPDATE_PACK_OPTION_FAIL = '@PATIENT_GROUP/UPDATE_PACK_OPTION_FAIL';

export const UPDATE_PATIENT_GROUP_STATUS = '@PATIENT_GROUP/UPDATE_PATIENT_GROUP_STATUS';
export const UPDATE_PATIENT_GROUP_STATUS_SUCCESS = '@PATIENT_GROUP/UPDATE_PATIENT_GROUP_STATUS_SUCCESS';
export const UPDATE_PATIENT_GROUP_STATUS_FAIL = '@PATIENT_GROUP/UPDATE_PATIENT_GROUP_STATUS_FAIL';

export const CLEAR_UPDATE_FORM = '@PATIENT_GROUP/CLEAR_UPDATE_FORM';

export function getPatientGroup(patGroupId) {
	return dispatch => {
		dispatch({ type: GET_PATIENT_GROUP });
		HttpService.get(`api/patientGroups/${patGroupId}`)
			.then(resp => {
				dispatch({ type: GET_PATIENT_GROUP_SUCCESS, payload: { data: parseModelToData(resp.data) } });
			})
			.catch(err => {
				const { errorFields, errorMessages } = parseError(err);
				dispatch({
					type: GET_PATIENT_GROUP_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields }
				});
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
}

export function updatePatientGroup() {
	return (dispatch, getState) => {
		const { data, id } = getState().patientGroup.update;
		dispatch({ type: UPDATE_PATIENT_GROUP });
		const postData = parseDataToModel(data);

		delete postData.id;

    HttpService.put(`api/patientGroups/${id}`, postData)
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'Patient group has been updated successfully',
            error: false,
          }),
        );
        dispatch({ type: UPDATE_PATIENT_GROUP_SUCCESS });
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({
          type: UPDATE_PATIENT_GROUP_FAIL,
          payload: { message: errorMessages.join('\n'), error: true },
        });
        dispatch(AppActions.createDialogMessage('error', 'Warning', errorMessages));
      });
  };
}

export function deletePatientGroup(patGroupId, callBack) {
  return (dispatch) => {
    dispatch({ type: DELETE_PATIENT_GROUP_START });
    HttpService.delete(`api/patientGroups/${patGroupId}`)
      .then(() => {
        dispatch({ type: DELETE_PATIENT_GROUP_DONE });
        dispatch(
          AppActions.showMessage({
            message: 'patientGroup:DELETE_SUCCESS_MESSAGE',
          }),
        );
        // eslint-disable-next-line no-unused-expressions
        callBack && callBack();
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        console.log(errorTitle);
        dispatch({ type: DELETE_PATIENT_GROUP_DONE });
        dispatch(AppActions.createDialogMessage('error', 'Warning', errorMessages));
      });
  };
}

export function clearUpdateForm() {
	return dispatch => {
		dispatch({ type: CLEAR_UPDATE_FORM });
	};
}

export function changePatientGroupUpdateForm(data) {
	return dispatch => {
		dispatch({ type: CHANGE_PATIENT_GROUP_UPDATE_FORM, payload: { data: { ...data, beds: toInteger(data.beds) } } });
	};
}

export function updatePatientGroupStatus(status) {
	return (dispatch, getState) => {
		const { id } = getState().patientGroup.update;
		dispatch({ type: UPDATE_PATIENT_GROUP_STATUS });

		const url = status ? `api/patientgroups/${id}/active` : `api/patientgroups/${id}/inactive`;

		HttpService.put(url)
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'Patient group has been updated successfully'
					})
				);
				dispatch({ type: UPDATE_PATIENT_GROUP_STATUS_SUCCESS, payload: status });
			})
			.catch(err => {
				const { errorFields, errorMessages } = parseError(err);
				dispatch({
					type: UPDATE_PATIENT_GROUP_STATUS_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields }
				});
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
}

function parseModelToData(data) {
  return {
    ...data,
    email: get(data.email, [0], ''),
    phone: get(data.phone, [0], ''),
    isListPatientByRoom: toString(data.isListPatientByRoom), // Parse value to match with constants
    isUseGenericAsDrugName: toString(data.isUseGenericAsDrugName), // Parse value to match with constants
    defaultTimeslot: fillTimeslot(data.defaultTimeslot || [], 4),
    sachetTimeslot: fillTimeslot(data.sachetTimeslot || [], 8),
    shortCourseTimeslot: fillTimeslot(data.shortCourseTimeslot || [], 8),
    status: data.status === 0,
    isAutomation: toString(data.isAutomation),
  };
}

export function parseDataToModel(data) {
	return omit(
		{
			...data,
			beds: data.beds || 0,
			email: [data.email],
			phone: [data.phone],
			isListPatientByRoom: data.isListPatientByRoom === 'true',
			isUseGenericAsDrugName: data.isUseGenericAsDrugName === 'true'
		},
		'status'
	);
}

function fillTimeslot(timeSlots, numOfSlot) {
	return Array(numOfSlot)
		.fill('')
		.reduce((prev, cur, index) => {
			return [...prev, timeSlots[index] || cur];
		}, []);
}

// packOption
export function getPackOption(patGroupId) {
	return dispatch => {
		dispatch({ type: GET_PACK_OPTION });
		HttpService.get(`/api/patientGroups/${patGroupId}/print-options`)
			.then(resp => {
				dispatch({ type: GET_PACK_OPTION_SUCCESS, payload: { data: resp.data } });
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch({
					type: GET_PACK_OPTION_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields }
				});
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
}

export function updatePackOption(id, data) {
	return dispatch => {
		dispatch({ type: UPDATE_PACK_OPTION });
		return HttpService.put(`/api/patientGroups/${id}/print-options`, data)
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'patientGroup:UPDATE_PACK_OPTIONS_SUCCESS_MESSAGE'
					})
				);
				dispatch({ type: UPDATE_PACK_OPTION_SUCCESS, payload: { data } });
				return true;
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch({
					type: UPDATE_PACK_OPTION_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields }
				});
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
}
