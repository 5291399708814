import head from 'lodash/head';
import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const GET_USER = '@USER/GET_USER_DETAILS';
export const GET_USER_SUCCESS = '@USER/GET_USER_SUCCESS';
export const GET_USER_FAIL = '@USER/GET_USER_FAIL';

export const UPDATE_USER = '@USER/UPDATE_USER';
export const UPDATE_USER_SUCCESS = '@USER/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = '@USER/UPDATE_USER_FAIL';

export const CHANGE_USER_UPDATE_FORM = '@USER/CHANGE_USER_UPDATE_FORM';

export const CLEAR_UPDATE_FORM = '@USER/CLEAR_UPDATE_FORM';

export const CHANGE_PASSWORD = '@USER/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = '@USER/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = '@USER/CHANGE_PASSWORD_FAIL';

export const RESEND_PASSWORD_EMAIL = '@USER/RESEND_PASSWORD_EMAIL';
export const RESEND_PASSWORD_EMAIL_SUCCESS = '@USER/RESEND_PASSWORD_EMAIL_SUCCESS';
export const RESEND_PASSWORD_EMAIL_FAIL = '@USER/RESEND_PASSWORD_EMAIL_FAIL';

export function getUserDetails(userId, callback) {
	return (dispatch) => {
		dispatch({ type: GET_USER });
		HttpService.get(`api/users/${userId}`)
			.then((resp) => {
				const inUsePharmacy = resp.data.pharmacies.find((i) => i.inUsed);
				const detail = {
					id: resp.data.id,
					firstName: resp.data.firstName,
					lastName: resp.data.lastName,
					email: resp.data.email,
					dob: resp.data.dob,
					gender: resp.data.gender.toString(),
					userName: resp.data.userName,
					roleIds: head(inUsePharmacy.roles),
					status: resp.data.status,
					originalUserName: resp.data.userName,
					originalEmail: resp.data.email,
					pharmacyIds: [inUsePharmacy.pharmacyId],
					avatarId: resp.data.avatarId,
				};
				dispatch({ type: GET_USER_SUCCESS, payload: { data: detail } });
			})
			.catch((err) => {
				const { errorFields, errorMessages } = parseError(err);
				dispatch({
					type: GET_USER_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields },
				});

				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages, { callback }));
			});
	};
}

export function updateUser() {
	return (dispatch, getState) => {
		dispatch({ type: UPDATE_USER });
		const { data, id } = getState().user.update;
		const postData = {
			...data,
			status: data.status,
			roleIds: [data.roleIds],
		};

		HttpService.put(`api/users/${id}`, postData)
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'user:UPDATE_USER_SUCCESS',
					})
				);
				dispatch({ type: UPDATE_USER_SUCCESS });
			})
			.catch((err) => {
				const { errorFields, errorMessages } = parseError(err);
				dispatch({
					type: UPDATE_USER_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields },
				});
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
}

export function clearUpdateForm() {
	return (dispatch) => {
		dispatch({ type: CLEAR_UPDATE_FORM });
	};
}

export function changeUserUpdateForm(data) {
	return (dispatch) => {
		dispatch({ type: CHANGE_USER_UPDATE_FORM, payload: { data } });
	};
}

export function changePassword(newPassword) {
	return (dispatch, getState) => {
		const { id } = getState().user.update;
		dispatch({ type: UPDATE_USER });
		const postData = {
			password: '',
			newPassword,
		};

    HttpService.put(`/api/users/updatepassword/${id}`, postData, { resolveStatus400: true })
      .then((resp) => {
        if (resp.isSuccess) {
          dispatch(
            AppActions.showMessage({
              message: 'user:UPDATE_USER_SUCCESS',
            }),
          );
          dispatch({ type: UPDATE_USER_SUCCESS });
        } else {
          dispatch({
            type: UPDATE_USER_FAIL,
            payload: { message: resp.message, error: { newPassword: resp.message } },
          });
        }
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({
          type: UPDATE_USER_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
}

export function resendPasswordEmail() {
	return (dispatch, getState) => {
		const { id } = getState().user.update;
		dispatch({ type: RESEND_PASSWORD_EMAIL });
		HttpService.put(`/api/users/${id}/reset-password-email`)
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'user:RESEND_EMAIL_PASSWORD_MESSAGE',
					})
				);
				dispatch({ type: RESEND_PASSWORD_EMAIL_SUCCESS });
			})
			.catch((err) => {
				const { errorTitle, errorMessages } = parseError(err);
				dispatch({
					type: RESEND_PASSWORD_EMAIL_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorTitle },
				});
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
}
