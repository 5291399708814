import { combineReducers } from 'redux';
import detail from './update.detail.reducers';
import configuration from './update.configuration.reducers';
import dataUpload from './update.dataUpload.reducers';

const pharmacyUpdateReducer = combineReducers({
	detail,
	configuration,
	dataUpload
});

export default pharmacyUpdateReducer;
