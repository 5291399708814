import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import i18next from 'i18next';
import { MENU_ENUM } from 'app/main/role/constants';
import en from './i18n/en';

i18next.addResourceBundle('en', 'brandSwap', en);
const BrandSwapConfig = {
	settings: {
		layout: {
			config: {}
		},
		nav: {
			title: 'Brand Swap'
		}
	},
	auth: authRoles.user,
	key: MENU_ENUM.BRAND_SWAP,
	permission: PERMISSION.READ,
	routes: [
		{
			path: '/brand-swap/list',
			component: React.lazy(() => import('./BrandSwap'))
		}
	]
};

export default BrandSwapConfig;
