import * as PrintProfileUpdateActions from '../actions/update.actions';

const initialState = {
  id: '',
  data: {
    clientCode: -1,
    packType: 0,
    name: '',
    topMargin: '0',
    leftMargin: '0',
    width: '0',
    height: '0',
    foilDrugNameLength: '0',
    alpacaFoilDirectionLength: '0',
    isPackDateOnFoil: false,
    isPackStartDate: false,
    isDrugWarning: false,
    isSamAsDrugInfo: false,
    isPackDoseTimeHeading: false,
    isPackBarcode: false,
    isUnpackedPrnMedArea: false,
    defaultPrinter: '',
  },
  testPrintFileUrl: '',
  testPrintFileName: '',
  loading: false,
  submitting: false,
  error: null,
  message: '',
};

const update = (state = initialState, action) => {
  switch (action.type) {
    case PrintProfileUpdateActions.GET_PRINT_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case PrintProfileUpdateActions.GET_PRINT_PROFILE_SUCCESS:
      return {
        ...state,
        id: action.payload.data.printProfileId,
        loading: false,
        data: action.payload.data,
      };
    case PrintProfileUpdateActions.GET_PRINT_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: action.payload.error,
      };
    case PrintProfileUpdateActions.CHANGE_PRINT_PROFILE_UPDATE_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };
    case PrintProfileUpdateActions.UPDATE_PRINT_PROFILE:
      return {
        ...state,
        submitting: true,
      };
    case PrintProfileUpdateActions.UPDATE_PRINT_PROFILE_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case PrintProfileUpdateActions.UPDATE_PRINT_PROFILE_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload.error,
        message: action.payload.message,
      };
    case PrintProfileUpdateActions.TEST_PRINT:
      return {
        ...state,
        loading: true,
        testPrintFileUrl: '',
        testPrintFileName: '',
      };
    case PrintProfileUpdateActions.TEST_PRINT_SUCCESS:
      return {
        ...state,
        loading: false,
        testPrintFileUrl: action.payload.data.fileUrl,
        testPrintFileName: action.payload.data.fileName,
      };
    case PrintProfileUpdateActions.TEST_PRINT_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: action.payload.error,
      };
    case PrintProfileUpdateActions.CLEAR_UPDATE_FORM:
      return initialState;
    default: {
      return state;
    }
  }
};

export default update;
