// http://mcg.mbitson.com/
const palette = {
	50: '#e0f2f9',
	100: '#b3dff0',
	200: '#80c9e6',
	300: '#4db3db',
	400: '#26a3d4',
	500: '#0093cc',
	600: '#008bc7',
	700: '#0080c0',
	800: '#0076b9',
	900: '#0064ad',
	A100: '#d7ecff',
	A200: '#a4d3ff',
	A400: '#71baff',
	A700: '#58aeff',
	contrastText: '#ffffff'
};

export default palette;
