const locale = {
  TITLE: 'Sync',
  TABLE_LOG_DATE: 'Date',
  TABLE_LOG_DETAIL: 'Details',
  TABLE_LOG_ACTIONED_USER: 'User',
  BUTTON_SHOW_MORE: 'Show More',
  BUTTON_DASHBOARD: 'Dashboard',
  TABLE_NOTIFICATION_CONTENT: 'Details',
  TABLE_NOTIFICATION_TYPE: 'Type',
  TABLE_NOTIFICATION_LAST_DATE_UPDATE: 'Last Update Date',
  SYNC_DATA_TAB: 'Sync Data',
  FILE_TRANSFER_STATUS_TAB: 'File Transfer Status',
  FILE_HEADER_NAME: 'File Name',
  FILE_HEADER_DETAILS: 'Details',
  FILE_HEADER_STATUS: 'Status',
  FILE_HEADER_LAST_UPDATE: 'Last Update',
  SYNC_VERSION: 'Sync Version',
};

export default locale;
