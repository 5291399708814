import { combineReducers } from 'redux';
import list from './list.reducers';
import pack from './pack.reducers';
import check from './check.reducers';
import correct from './correct.reducers';
import distribute from './distribute.reducers';

const jobReducers = combineReducers({
	list,
	pack,
	check,
	correct,
	distribute
});

export default jobReducers;
