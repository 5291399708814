import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from '../../../../helpers/Error';

export const SEND_REQUEST = '@RESET_PASSWORD/SEND_REQUEST';
export const SEND_REQUEST_SUCCESS = '@RESET_PASSWORD/SEND_REQUEST_SUCCESS';
export const SEND_REQUEST_FAIL = '@RESET_PASSWORD/SEND_REQUEST_FAIL';
export const RESET_STATE = '@RESET_PASSWORD/RESET_STATE';

export const sendRequest = ({ password, token }) => {
	return dispatch => {
		dispatch({ type: SEND_REQUEST });
		HttpService.put('/api/auth/recoverpass', { password, token })
			.then(resp => {
				dispatch({ type: SEND_REQUEST_SUCCESS });
			})
			.catch(err => {
				const { errorMessages } = parseError(err);
				dispatch({ type: SEND_REQUEST_FAIL, payload: { error: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', 'resetPass:REQUEST_FAIL_DIALOG_TITLE', errorMessages));
			});
	};
};

export const resetState = () => {
	return dispatch => {
		dispatch({ type: RESET_STATE });
	};
};
