import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
// import HomeConfig from 'app/main/home/HomeConfig';
import HomeLogsConfig from 'app/main/home/logs/HomeLogsConfig';
import HomeNotificationsConfig from 'app/main/home/notifications/HomeNotificationsConfig';
import LoginConfig from 'app/main/auth/login/LoginConfig';
import ForgotPasswordConfig from 'app/main/auth/forgot-password/ForgotPasswordConfig';
import ResetPasswordConfig from 'app/main/auth/reset-password/ResetPasswordConfig';
import UnAuthorizedConfig from 'app/main/auth/un-authorized/UnAuthorizedConfig';
import CreatePatientConfig from 'app/main/patient/create/CreatePatientConfig';
import UpdatePatientConfig from 'app/main/patient/update/UpdatePatientConfig';
import PatientListConfig from 'app/main/patient/list/PatientListConfig';
import SelectDrugConfig from 'app/main/patient/selectDrug/SelectDrugConfig';
import DrugListConfig from 'app/main/drug/list/DrugListConfig';
import CreateDrugConfig from 'app/main/drug/create/CreateDrugConfig';
import UpdateDrugConfig from 'app/main/drug/update/UpdateDrugConfig';
import PatientGroupListConfig from 'app/main/patientGroup/list/PatientGroupListConfig';
import CreatePatientGroupConfig from 'app/main/patientGroup/create/CreatePatientGroupConfig';
import UpdatePatientGroupConfig from 'app/main/patientGroup/update/UpdatePatientGroupConfig';
import DoctorListConfig from 'app/main/doctor/list/DoctorListConfig';
import CreateDoctorConfig from 'app/main/doctor/create/CreateDoctorConfig';
import ImportDoctorConfig from 'app/main/doctor/import/ImportDoctorConfig';
import UpdateDoctorConfig from 'app/main/doctor/update/UpdateDoctorConfig';
import DirectionListConfig from 'app/main/direction/list/DirectionListConfig';
import CreateDirectionConfig from 'app/main/direction/create/CreateDirectionConfig';
import UpdateDirectionConfig from 'app/main/direction/update/UpdateDirectionConfig';
import PrintConfig from 'app/main/print/PrintConfig';
import PrintReportConfig from 'app/main/report/print/PrintReportConfig';
import AnalyticReportConfig from 'app/main/report/analytic/AnalyticReportConfig';
import PrintProfileListConfig from 'app/main/printProfile/list/PrintProfileListConfig';
import CreatePrintProfileConfig from 'app/main/printProfile/create/CreatePrintProfileConfig';
import UpdatePrintProfileConfig from 'app/main/printProfile/update/UpdatePrintProfileConfig';
import RoleListConfig from 'app/main/role/list/RoleListConfig';
import UserAssignedListConfig from 'app/main/role/viewUser/UserAssignedListConfig';
import CreateRoleConfig from 'app/main/role/create/CreateRoleConfig';
import UpdateRoleConfig from 'app/main/role/update/UpdateRoleConfig';
import UserListConfig from 'app/main/user/list/UserListConfig';
import CreateUserConfig from 'app/main/user/create/CreateUserConfig';
import UpdateUserConfig from 'app/main/user/update/UpdateUserConfig';
import BrandSwapConfig from 'app/main/brandSwap/BrandSwapConfig';
import BrandSwapLogConfig from 'app/main/brandSwap/log/BrandSwapLogConfig';
import ClaimConfig from 'app/main/claim/ClaimConfig';
import PharmacyListConfig from 'app/main/pharmacy/list/PharmacyListConfig';
import CreatePharmacyConfig from 'app/main/pharmacy/create/CreatePharmacyConfig';
import RedirectPharmacyConfig from 'app/main/pharmacy/redirect/RedirectPharmacyConfig';
import UpdatePharmacyConfig from 'app/main/pharmacy/update/UpdatePharmacyConfig';
// import MigratePharmacyConfig from 'app/main/pharmacy/migrate/MigratePharmacyConfig';
import JobListConfig from 'app/main/job/list/JobListConfig';
import JobPackConfig from 'app/main/job/pack/JobPackConfig';
import JobCheckConfig from 'app/main/job/check/JobCheckConfig';
import JobCorrectConfig from 'app/main/job/correct/JobCorrectConfig';
import JobDistributeConfig from 'app/main/job/distribute/JobDistributeConfig';
import VpbListConfig from 'app/main/vpb/list/VpbListConfig';
import DispenseTrackerListConfig from 'app/main/dispenseTracker/list/DispenseTrackerListConfig';
import ProfileDetailConfig from 'app/main/profile/detail/ProfileDetailConfig';
import SigningSheetsConfig from 'app/main/signingSheets/SigningSheetsConfig';
import ScriptRequestListConfig from 'app/main/scriptRequest/list/ScriptRequestListConfig';
import DashboardConfig from 'app/main/dashboard/home/DashboardConfig';
import DashboardPermissionsListConfig from 'app/main/dashboard/permissions/list/DashboardPermissionsListConfig';
import DashboardPermissionsUpdateConfig from 'app/main/dashboard/permissions/update/DashboardPermissionsUpdateConfig';
import SyncConfig from 'app/main/sync/list/SyncConfig';
import ChangePasswordConfig from 'app/main/auth/change-password/ChangePasswordConfig';
import FeedbackConfig from 'app/main/feedback/FeedbackConfig';

import { MENU_ENUM } from 'app/main/role/constants';
import { authRoles, PERMISSION } from 'app/auth';
import ImportDispenseConfig from 'app/main/patient/import/ImportDispenseConfig';
import QScriptListConfig from 'app/main/qscript/list/QScriptConfig';
import PharmacyProfileConfig from 'app/main/pharmacy/profile/PharmacyProfileConfig';

const routeConfigs = [
  HomeNotificationsConfig,
  HomeLogsConfig,
  // HomeConfig,
  LoginConfig,
  ForgotPasswordConfig,
  ResetPasswordConfig,
  UnAuthorizedConfig,
  PatientListConfig,
  CreatePatientConfig,
  ImportDispenseConfig,
  SelectDrugConfig,
  UpdatePatientConfig,
  DrugListConfig,
  CreateDrugConfig,
  UpdateDrugConfig,
  PatientGroupListConfig,
  CreatePatientGroupConfig,
  PatientGroupListConfig,
  UpdatePatientGroupConfig,
  DoctorListConfig,
  CreateDoctorConfig,
  ImportDoctorConfig,
  UpdateDoctorConfig,
  DirectionListConfig,
  CreateDirectionConfig,
  UpdateDirectionConfig,
  PrintConfig,
  PrintReportConfig,
  AnalyticReportConfig,
  PrintProfileListConfig,
  CreatePrintProfileConfig,
  UpdatePrintProfileConfig,
  RoleListConfig,
  UserAssignedListConfig,
  CreateRoleConfig,
  UpdateRoleConfig,
  UserListConfig,
  CreateUserConfig,
  UpdateUserConfig,
  BrandSwapConfig,
  BrandSwapLogConfig,
  ClaimConfig,
  // PharmacyListConfig,
  CreatePharmacyConfig,
  PharmacyProfileConfig,
  // MigratePharmacyConfig,
  RedirectPharmacyConfig,
  UpdatePharmacyConfig,
  JobListConfig,
  JobPackConfig,
  JobCheckConfig,
  JobCorrectConfig,
  JobDistributeConfig,
  VpbListConfig,
  DispenseTrackerListConfig,
  ProfileDetailConfig,
  SigningSheetsConfig,
  ScriptRequestListConfig,
  DashboardPermissionsListConfig,
  DashboardPermissionsUpdateConfig,
  QScriptListConfig,
  SyncConfig,
  ChangePasswordConfig,
  FeedbackConfig,
];

const cacheRouteConfigs = [DashboardConfig];

const superAdminRouteConfigs = [
	LoginConfig,
	PharmacyListConfig,
	CreatePharmacyConfig,
	UpdatePharmacyConfig,
	UnAuthorizedConfig
];
const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		key: MENU_ENUM.HOME,
		permission: PERMISSION.READ,
		auth: authRoles.user,
		component: () => <Redirect to="/home" />
	}
];

const cacheRoutes = [...FuseUtils.generateRoutesFromConfigs(cacheRouteConfigs)];

const superAdminRoutes = [
	...FuseUtils.generateRoutesFromConfigs(superAdminRouteConfigs),
	{
		path: '/',
		key: MENU_ENUM.HOME,
		permission: PERMISSION.READ,
		auth: authRoles.user,
		component: () => <Redirect to="/pharmacy/list" />
	}
];

export { routes, superAdminRoutes, cacheRoutes };
