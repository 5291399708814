const locale = {
  TITLE: 'Give Feedback',
  THANK_YOU: 'Thank you for your feedback!',
  SEND_TO_MYPAK_BUTTON: 'Send to myPak',
  FEEDBACK_COMPLETED:
    'Your insights are highly valued, and we remain committed to continuous improvement in our software based on your valuable input.',
  POLICY_OF_MYPAK: 'Privacy Policy.',
  GIVE_FEEDBACK_SUBTITLE: 'Give feedback to myPak',
  GIVE_FEEDBACK_HEADER_CAPTION: 'Summarise your feedback',
  GIVE_FEEDBACK_FOOTER_CAPTION:
    'By pressing Send to myPak, your feedback will be used to improve myPak products and services.',
  FEEDBACK_INPUT_PLACEHOLDER: "Please don't include any confidental or personal information in your comment*",
};

export default locale;
