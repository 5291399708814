import { combineReducers } from 'redux';
import login from './login.reducer';
import user from './user.reducer';
import forgotPass from './forgotPassword.reducers';
import resetPass from './resetPassword.reducers';
import changePass from './changePassword.reducers';

const authReducers = combineReducers({
  user,
  login,
  forgotPass,
  resetPass,
  changePass
});

export default authReducers;
