const configs = {
  minDate: new Date(1900, 0, 1, 0, 0, 0),
  REACT_APP_MAP_KEY: process.env.REACT_APP_MAP_KEY,
  REACT_APP_API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  REACT_APP_APP_ENV: process.env.REACT_APP_APP_ENV,
  REACT_APP_WEBSOCKET_SERVER: process.env.REACT_APP_WEBSOCKET_SERVER,
  REACT_APP_VERSION: window.__EXTRA_CONFIG.buildName,
  REACT_APP_ELECTRON_PACKAGED: process.env.REACT_APP_ELECTRON_PACKAGED,
  REACT_APP_CHART_URL: process.env.REACT_APP_CHART_URL,
  REACT_APP_CHART_ID: process.env.REACT_APP_CHART_ID,
};
export default configs;
