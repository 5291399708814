import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const SET_DOCTORS_FILTER = '@DOCTOR/SET_DOCTORS_FILTER';
export const RESET_DOCTORS_FILTER = '@DOCTOR/RESET_DOCTORS_FILTER';

export const REMOVE_DOCTOR = '@DOCTOR/REMOVE_DOCTOR';
export const REMOVE_DOCTOR_SUCCESS = '@DOCTOR/REMOVE_DOCTOR_SUCCESS';
export const REMOVE_DOCTOR_FAIL = '@DOCTOR/REMOVE_DOCTOR_FAIL';

export const setDoctorFilter = filter => {
	return dispatch => {
		dispatch({ type: SET_DOCTORS_FILTER, payload: { filter } });
	};
};

export const resetDoctorFilter = () => {
	return dispatch => {
		dispatch({ type: RESET_DOCTORS_FILTER });
	};
};

export const removeDoctor = doctor => {
	return dispatch => {
		HttpService.abort('removeDoctor');
		dispatch({ type: REMOVE_DOCTOR });
		HttpService.delete(`api/doctors/${doctor}`, { cancelToken: 'removeDoctor' })
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'doctor:ARCHIVE_DOCTOR_SUCCESS'
					})
				);
				dispatch({ type: REMOVE_DOCTOR_SUCCESS });
				dispatch(AppActions.dataLoader_reload(`GET:/api/doctors`));
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch({
					type: REMOVE_DOCTOR_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields }
				});
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};
