const locale = {
  TITLE: 'Brand Swap',
  HEADER_TITLE: 'Brand Swap',
  TAB_BY_DRUG: 'By Drug',
  BRAND_SWAP: 'Swap Medication',
  BACK: 'Back',
  BRAND_SWAP_SUCCESS: 'Drug has been successfully swapped',
  SECTION_BRAND_SWAP_SELECTION: 'Brand Selection',
  SECTION_PATIENT_SELECTION: 'Patient Selection',
  SECTION_BRAND_SWAP_SUMMARY: 'Brand Swap Summary',
  SELECT_OLD_DRUG: '1. Select Original Brand to Swap from:',
  SELECT_NEW_DRUG: '2. Select New Brand to Swap to:',
  ORIGINAL_DRUG: 'Original Drug',
  NEW_DRUG: 'New Drug',
  SWAPPING_FROM: 'Swapping From',
  SWAPPING_TO: 'Swapping To',
  PATIENT_GROUP: '3. Select Patients to Swap Brand for:',
  TRANSFER_MEDICATION_BALANCE: 'Transfer Medication Balance',
  SWAP_DATE: 'Start Date',
  SELECTED_PATIENTS: 'Selected patients',
  SELECTED_PATIENTS_COUNT: '{{count}} patients selected',
  PATIENT_HEADER_FIRST_NAME: 'First Name',
  PATIENT_HEADER_LAST_NAME: 'Last Name',
  PATIENT_HEADER_GROUP: 'Patient Group',
  SELECT_DRUG: 'Select Drug',
  FIND_DRUG: 'Search Brand',
  FILTER_PATIENT: 'Filter Patient',
  BUTTON_LOG: 'Log',
  SEARCH_LOG: 'Search',
  FROM_DATE: 'From',
  TO_DATE: 'To',
  SWAPPED_FROM: 'Swapped From',
  SWAPPED_TO: 'Swapped To',
  START_DATE: 'Start Date',
  DATE_CHANGE: 'Date Changed',
  CONFIRMED_NON_EQUIVALENT: 'Confirmed\nNon-equivalent',
  BALANCE_TRANS: 'Balance Transferred?',
  USER: 'User',
  DRUG_LABEL: 'Drug',
  PRINT: 'Print',
};

export default locale;
