import auth from 'app/auth/store/reducers';
import { combineReducers } from 'redux';
import patient from 'app/main/patient/store/reducers';
import drug from 'app/main/drug/store/reducers';
import direction from 'app/main/direction/store/reducers';
import claim from 'app/main/claim/store/reducers';
import patientGroup from 'app/main/patientGroup/store/reducers';
import doctor from 'app/main/doctor/store/reducers';
import print from 'app/main/print/store/reducers';
import report from 'app/main/report/store/reducers';
import printProfile from 'app/main/printProfile/store/reducers';
import role from 'app/main/role/store/reducers';
import user from 'app/main/user/store/reducers';
import brandSwap from 'app/main/brandSwap/store/reducers';
import pharmacy from 'app/main/pharmacy/store/reducers';
import job from 'app/main/job/store/reducers';
import vpb from 'app/main/vpb/store/reducers';
import dispenseTracker from 'app/main/dispenseTracker/store/reducers';
import scriptRequest from 'app/main/scriptRequest/store/reducers';
import signingSheets from 'app/main/signingSheets/store/reducers';
import dashboard from 'app/main/dashboard/store/reducers';
import qscript from 'app/main/qscript/store/reducers';
import fuse from './fuse';
import dataLoader from './data-loader.reducer';
import dialogMessage from './dialog-message.reducer';
import nextState from './nextState.reducer';

const createReducer = (asyncReducers) =>
  combineReducers({
    auth,
    fuse,
    patient,
    dialogMessage,
    dataLoader,
    drug,
    direction,
    claim,
    patientGroup,
    doctor,
    print,
    report,
    printProfile,
    role,
    user,
    brandSwap,
    pharmacy,
    job,
    vpb,
    dispenseTracker,
    scriptRequest,
    signingSheets,
    dashboard,
    qscript,
    nextState,
    ...asyncReducers,
  });

export default createReducer;
