import { combineReducers } from 'redux';
import base from './update.base.reducers';
import details from './update.details.reducers';
import allergies from './update.allergies.reducers';
import considerations from './update.consideration.reducers';
import notes from './update.notes.reducers';
import contacts from './update.contacts.reducers';
import medicationProfiles from './update.medicationProfiles.reducers';
import prescriptions from './update.prescriptions.reducers';
import header from './update.header.reducers';
import medicationHistory from './update.medicationHistory.reducers';
import doctor from './update.doctor.reducers';
import documents from './update.documents.reducers';

const patientUpdateReducers = combineReducers({
  base,
  details,
  allergies,
  notes,
  considerations,
  contacts,
  medicationProfiles,
  prescriptions,
  header,
  medicationHistory,
  doctor,
  documents,
});

export default patientUpdateReducers;
