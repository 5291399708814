/* eslint-disable import/prefer-default-export */
import ROLE_LEVEL from 'app/constants/RoleLevel';

const ROLE_LEVEL_LABEL = {
	USER: 'User',
	SUPER_ADMIN: 'Super Admin',
	ADMINISTRATOR: 'Adminstrator',
	SUPPORT_ADMIN: 'Support Admin'
};

export const ROLE_LEVEL_ENUM = {
	[ROLE_LEVEL.USER]: ROLE_LEVEL_LABEL.USER,
	[ROLE_LEVEL.SUPER_ADMIN]: ROLE_LEVEL_LABEL.SUPER_ADMIN,
	[ROLE_LEVEL.ADMINISTRATOR]: ROLE_LEVEL_LABEL.ADMINISTRATOR,
	[ROLE_LEVEL.SUPPORT_ADMIN]: ROLE_LEVEL_LABEL.SUPPORT_ADMIN,
	[ROLE_LEVEL_LABEL.USER]: ROLE_LEVEL.USER,
	[ROLE_LEVEL_LABEL.SUPER_ADMIN]: ROLE_LEVEL.SUPER_ADMIN,
	[ROLE_LEVEL_LABEL.ADMINISTRATOR]: ROLE_LEVEL.ADMINISTRATOR,
	[ROLE_LEVEL_LABEL.SUPPORT_ADMIN]: ROLE_LEVEL.SUPPORT_ADMIN
};

export const CHART_TEMPLATE = {
	id: '',
	height: '',
	width: '',
	order: '',
	filter: [],
	_key: null
};

export const SCHEDULE_TAB = {
  TODAY: 0,
  WEEK: 1,
  MONTH: 2,
};

export const SCHEDULE_TAB_ENUM = {
  NOTE: 0,
  REMINEDER: 1,
  TASK: 2,
};

export const SCHEDULE_TAB_TYPE = [
  { label: 'Note', value: SCHEDULE_TAB_ENUM.NOTE, icon: 'description_outlined' },  
  { label: 'Reminder', value: SCHEDULE_TAB_ENUM.REMINEDER, icon: 'notification_important_outlined' },
  { label: 'Task', value: SCHEDULE_TAB_ENUM.TASK, icon: 'format_list_bulleted_outlined' },
];

export const REPEAT_ENUM = {
  DAY: 0,
  WEEK: 1,
  YEAR: 2,
};

export const REPEAT_TYPE = [
  { label: 'Day', value: REPEAT_ENUM.DAY },
  { label: 'Week', value: REPEAT_ENUM.WEEK },
  { label: 'Year', value: REPEAT_ENUM.YEAR },
];
