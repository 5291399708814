import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';
import { NOTE_USAGE } from '../../constants';

export const SUBMIT_PATIENT_NOTE = '@PATIENT/SUBMIT_PATIENT_NOTE';
export const SUBMIT_PATIENT_NOTE_SUCCESS = '@PATIENT/SUBMIT_PATIENT_NOTE_SUCCESS';
export const SUBMIT_PATIENT_NOTE_FAIL = '@PATIENT/SUBMIT_PATIENT_NOTE_FAIL';

export const ARCHIVE_PATIENT_NOTE = '@PATIENT/ARCHIVE_PATIENT_NOTE';
export const ARCHIVE_PATIENT_NOTE_SUCCESS = '@PATIENT/ARCHIVE_PATIENT_NOTE_SUCCESS';
export const ARCHIVE_PATIENT_NOTE_FAIL = '@PATIENT/ARCHIVE_PATIENT_NOTE_FAIL';

export const UPDATE_PATIENT_NOTE = '@PATIENT/UPDATE_PATIENT_NOTE';
export const UPDATE_PATIENT_NOTE_SUCCESS = '@PATIENT/UPDATE_PATIENT_NOTE_SUCCESS';
export const UPDATE_PATIENT_NOTE_FAIL = '@PATIENT/UPDATE_PATIENT_NOTE_FAIL';

export const SET_SELECTED_NOTE = '@PATIENT/SET_SELECTED_NOTE';

export function addNote({ note, noteUsage }) {
  return (dispatch, getState) => {
    const { patientId } = getState().patient.update.base.data;
    dispatch({ type: SUBMIT_PATIENT_NOTE });
    HttpService.post(`/api/patients/${patientId}/notes`, {
      content: note,
      isDisplay: NOTE_USAGE.PATIENT_LIST === noteUsage,
      isReportPrint: NOTE_USAGE.REPORT === noteUsage,
      isDistribution: NOTE_USAGE.DISTRIBUTION === noteUsage,
    })
      .then((resp) => {
        dispatch({ type: SUBMIT_PATIENT_NOTE_SUCCESS, payload: { note: resp.data } });
        dispatch(
          AppActions.showMessage({
            message: 'Note has been added successfully',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
        dispatch(AppActions.dataLoader_reload('PatientNotes'));
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: SUBMIT_PATIENT_NOTE_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function archiveNote(noteId) {
	return (dispatch, getState) => {
		const { patientId } = getState().patient.update.base.data;
		dispatch({ type: ARCHIVE_PATIENT_NOTE });
		HttpService.delete(`/api/patients/${patientId}/notes/${noteId}`)
			.then(() => {
				dispatch({ type: ARCHIVE_PATIENT_NOTE_SUCCESS, payload: { noteId } });
				dispatch(
					AppActions.showMessage({
						message: 'Note has been deleted successfully',
						autoHideDuration: 4000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right'
						},
						variant: 'success'
					})
				);
				dispatch(AppActions.dataLoader_reload('PatientNotes'));
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch({ type: ARCHIVE_PATIENT_NOTE_FAIL, payload: { message: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
}

export function updateNote({ note, noteId, noteUsage }) {
  return (dispatch, getState) => {
    const { patientId } = getState().patient.update.base.data;
    dispatch({ type: UPDATE_PATIENT_NOTE });
    HttpService.put(`/api/patients/${patientId}/notes/${noteId}`, {
      content: note,
      isDisplay: NOTE_USAGE.PATIENT_LIST === noteUsage,
      isReportPrint: NOTE_USAGE.REPORT === noteUsage,
      isDistribution: NOTE_USAGE.DISTRIBUTION === noteUsage,
    })
      .then(() => {
        dispatch({ type: UPDATE_PATIENT_NOTE_SUCCESS, payload: { noteId, note } });
        dispatch(
          AppActions.showMessage({
            message: 'Note has been updated successfully',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
        dispatch(AppActions.dataLoader_reload('PatientNotes'));
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: UPDATE_PATIENT_NOTE_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function setSelectedNote(note) {
	return dispatch => {
		dispatch({ type: SET_SELECTED_NOTE, payload: { note } });
	};
}
