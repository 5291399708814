import { useState, useEffect, useRef } from 'react';
import { EventService } from 'app/services';

const useContentScroller = callback => {
	const [isBottom, setIsBottom] = useState(false);
	const isButtomRef = useRef(isBottom);
	const [isTop, setIsTop] = useState(true);
	const isTopRef = useRef(isTop);

	const _setIsBottom = ib => {
		if (isButtomRef.current !== ib) {
			isButtomRef.current = ib;
			setIsBottom(ib);
		}
	};

  const _setIsTop = (ib) => {
    if (isTopRef.current !== ib) {
      isTopRef.current = ib;
      setIsTop(ib);
    }
  };

  // NG-4969 Script Tracking page doesn't load enough data
  const handleScroll = () => {
    // handle when scroll content is an area
    const container = callback?.current;

    if (container) {
      const isAtBottom = container.scrollTop + container.clientHeight >= container.scrollHeight;
      _setIsBottom(isAtBottom);
      _setIsTop(container.scrollTop === 0);
    }
  };

  useEffect(() => {
    const container = callback?.current;

    if (container) {
      EventService.on('contentScroll', handleScroll);

      return () => {
        EventService.removeListener('contentScroll', onScroll);
      };
    }
    // handle when scroll content is a view
    const onScroll = (e) => {
      // https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
      _setIsBottom(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight < 1);
      _setIsTop(e.currentTarget.scrollTop === 0);
    };

		EventService.on('contentScroll', onScroll);
		return () => {
			EventService.removeListener('contentScroll', onScroll);
		};
	}, [callback]);

	return {
		isBottom,
		isTop
		// scrollUp,
		// scrollDown
	};
};

export default useContentScroller;
