import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseResponseErrorMessage } from 'helpers/Error';
import * as DrugDetailActions from './update.details.actions';
import * as DrugBaseActions from './update.base.actions';

export const GET_PREFERRED_BRANDS = '@DRUG/GET_PREFERRED_BRANDS';

export const ADD_PREFERRED_BRANDS = '@DRUG/ADD_PREFERRED_BRANDS';

export const REMOVE_PREFERRED_BRANDS = '@DRUG/REMOVE_PREFERRED_BRANDS';

export const UPDATE_PREFERRED_BRANDS = '@DRUG/UPDATE_PREFERRED_BRANDS';
export const UPDATE_PREFERRED_BRANDS_SUCCESS = '@DRUG/UPDATE_PREFERRED_BRANDS_SUCCESS';
export const UPDATE_PREFERRED_BRANDS_FAIL = '@DRUG/UPDATE_PREFERRED_BRANDS_FAIL';

export function getPreferredBrands(drugId) {
	return (dispatch, getState) => {
		HttpService.get(`api/drugs/${drugId}/preferredbrands`)
			.then(resp => {
				dispatch({ type: GET_PREFERRED_BRANDS, payload: { data: resp.data.map(drug => drug.drugId) } });
			})
			.catch(err => {
				dispatch(
					AppActions.showMessage({
						message: parseResponseErrorMessage(err),
						variant: 'error'
					})
				);
			});
	};
}

export function updatePreferredBrands() {
	return (dispatch, getState) => {
		const { drugId } = getState().drug.update.base.data;
		const { list } = getState().drug.update.preferredBrand;
		dispatch({ type: UPDATE_PREFERRED_BRANDS });
		HttpService.post(`api/drugs/${drugId}/preferredbrands`, { listPreferredDrugId: list })
			.then(resp => {
				dispatch({ type: UPDATE_PREFERRED_BRANDS_SUCCESS });

				// has new drugId
				if (resp.data.drugId !== drugId) {
					// register new request
					const { requests } = getState().request;
					const option = requests[`GET:/api/drugs/${drugId}/preferredbrands`];
					dispatch(AppActions.dataLoader_register(`GET:/api/drugs/${resp.data.drugId}/preferredbrands`, option));

					// remove old request
					dispatch(AppActions.dataLoader_remove(`GET:/api/drugs/${drugId}/preferredbrands`));

					dispatch(DrugDetailActions.updateDrugId(resp.data.drugId));
					dispatch(DrugBaseActions.updateBaseId(resp.data.drugId));
				} else {
					dispatch(AppActions.dataLoader_reload(`GET:/api/drugs/${drugId}/preferredbrands`));
				}
				dispatch(
					AppActions.showMessage({
						message: 'Preferred brands have been updated'
					})
				);
			})
			.catch(err => {
				dispatch({ type: UPDATE_PREFERRED_BRANDS_FAIL });
				dispatch(
					AppActions.showMessage({
						message: parseResponseErrorMessage(err),
						variant: 'error'
					})
				);
			});
	};
}

export function addPreferredBrand(drugId) {
	return dispatch => {
		dispatch({ type: ADD_PREFERRED_BRANDS, payload: { drugId } });
	};
}
export function removePreferredBrand(drugId) {
	return dispatch => {
		dispatch({ type: REMOVE_PREFERRED_BRANDS, payload: { drugId } });
	};
}
