import { combineReducers } from 'redux';
import list from './list.reducer';
import create from './create.reducer';
import update from './update.reducer';
import importDoctor from './importDoctor.reducer';

const doctorReducers = combineReducers({
	list,
	create,
	update,
	importDoctor
});

export default doctorReducers;
