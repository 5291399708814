const locale = {
  TITLE: 'Drug Management',
  HEADER_TITLE: 'Drugs',
  HEADER_SEARCH_TEXT_PLACEHOLDER: 'Search drugs...',
  HEADER_ADD_DRUG_BUTTON_FULL: 'Add Custom Drug',
  HEADER_ADD_DRUG_BUTTON_SHORT: 'New',
  ARCHIVE_DRUG_CONFIRM_TITLE: 'Archive Drug?',
  ARCHIVE_DRUG_CONFIRM_CONTENT: 'Are you sure you want to archive drug',
  DRUG_TABLE_HEADER_CODE: 'Drug Code',
  DRUG_TABLE_HEADER_NAME: 'Drug Name',
  BRAND_TABLE_HEADER_NAME: 'Brand Name',
  DRUG_TABLE_HEADER_GENERIC_NAME: 'Generic Name',
  DRUG_TABLE_HEADER_GENERIC_NAME_SHORT: 'Generic',
  DRUG_TABLE_HEADER_GENERIC_CODE: 'Generic Code',
  DRUG_TABLE_HEADER_SUPPLIER: 'Supplier',
  DRUG_TABLE_HEADER_AUTHORIZED: 'Authorised Drug',
  DRUG_TABLE_HEADER_GROUP: 'Group',
  DRUG_TABLE_HEADER_ACTIONS: 'Actions',
  DRUG_LIST_NO_SEARCH_RESULT: 'No search results',
  DRUG_LIST_NO_DATA: 'No data to display',
  ARCHIVE_DRUG_SUCCESS: 'Drug has been archived successfully!',
  TAB_DRUG_DETAILS: 'Drug Details',
  TAB_DRUG_APN: 'Barcode Management',
  TAB_DRUG_USAGE_REPORT: 'Drug Usage Report',
  TAB_DRUG_PREFERRED_BRANDS: 'Preferred Brands',
  APN_LABEL: 'Barcode',
  APN_DIALOG_TITLE: '{{action}} Barcode',
  ARCHIVE_APN_CONFIRM_CONTENT: 'Are you sure you want to delete drug barcode {{barcode}}?',
  ARCHIVE_APN_CONFIRM_TITLE: 'Delete Drug Barcode',
  DRUG_IMAGE: 'Drug Image',
  CODE: 'Code',
  GENERIC_CODE: 'Generic Code',
  NAME: 'Name',
  SUPPLIER: 'Supplier',
  STRENGTH: 'Strength',
  DESCRIPTION: 'Description',
  COLOUR: 'Colour',
  SHAPE: 'Shape',
  FORM: 'Form',
  GENERIC_NAME: 'Generic Name',
  ALT_BRANDS: 'Alternative Brands',
  SCHEDULE: 'Schedule',
  IS_CYTOTOXIC: 'Cytotoxic',
  IS_DO_NOT_CRUSH: 'Do not crush',
  PACK_SIZE: 'Pack Size',
  SHELF_LIFE: 'Shelf Life',
  GROUP: 'Drug Group',
  AUTHORISED: 'Authorised Drug',
  ALT_NAME_FOR_PACK: 'Alternate Name for Pack',
  ALT_NAME: 'Alternative Drug Name',
  PRESENTATION: 'Presentation/Indication',
  DIRECTION: 'Direction',
  WARNING: 'Warning',
  DRUG_DETAILS_SECTION: 'Drug Details',
  PRESENTATION_SECTION: 'Presentations/Indications',
  WARNING_SECTION: 'Warning/Adverse Reaction',
  ALTERNATE_BRAND_SECTION: 'Alternate Brands',
  DRUG_BARCODE_SECTION: 'Drug Barcode',
  APN_TABLE_HEADER: 'Barcode',
  APN_DESCRIPTION: 'Administer barcodes currently associated with the drug',
  APN_INPUT_INDICATION: 'Scan barcode into the box below:',
  SEARCH_MAX_LENGTH: 'Maximum length is {{ length }}',
  DRUG_STATUS_PLACEHOLDER: 'Drug Status',
  FILTER_FROM: 'From',
  FILTER_TO: 'To',
  INCLUDE_PATIENT_BALANCE: "Include Patient's Balance",
  SORT_ORDER: 'Sort Order',
  SORT_ORDER_FIRST_NAME: 'First Name',
  SORT_ORDER_LAST_NAME: 'Last Name',
  BUTTON_PRINT_REPORT: 'Print',
  CANISTER: 'Canister',
  MANUAL: 'Manual',
  COMPACT_CANISTER: 'C',
  COMPACT_MANUAL: 'M',
  PATIENT_USAGE: 'Usage',
};

export default locale;
