import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const SCRIPT_REQUEST_RESET = '@SCRIPT_REQUEST/RESET';
export const SCRIPT_REQUEST_CHANGE_FILTER = '@SCRIPT_REQUEST/CHANGE_FILTER';
export const SCRIPT_REQUEST_APPLY_PRINT_OPTIONS = '@SCRIPT_REQUEST/APPLY_PRINT_OPTIONS';
export const SCRIPT_REQUEST_PRINT_REQUEST = '@SCRIPT_REQUEST/PRINT_REQUEST';
export const SCRIPT_REQUEST_PRINT_REQUEST_SUCCESS = '@SCRIPT_REQUEST/PRINT_REQUEST_SUCCESS';
export const SCRIPT_REQUEST_PRINT_REQUEST_FAIL = '@SCRIPT_REQUEST/PRINT_REQUEST_FAIL';
export const SCRIPT_REQUEST_CLEAR_HISTORY_FILE = '@SCRIPT_REQUEST/CLEAR_HISTORY_FILE';

export const resetData = () => {
	return dispatch => {
		dispatch({ type: SCRIPT_REQUEST_RESET });
	};
};

export const changeFilter = filter => {
	return dispatch => {
		dispatch({ type: SCRIPT_REQUEST_CHANGE_FILTER, payload: filter });
	};
};

export const applyPrintOptions = options => {
	return dispatch => {
		dispatch({ type: SCRIPT_REQUEST_APPLY_PRINT_OPTIONS, payload: options });
	};
};

export const printRequest = () => {
  return async (dispatch, getState) => {
    const {
      filter: { patientGroupIds, doctorIds, patientIds, weekNumber },
      printOptions: {
        includePRN,
        includeNonPacked,
        includeSuspended,
        isPrintPerPatient,
        isIncludeMedicationDetails,
        includedIds,
        excludedIds,
      },
    } = getState().scriptRequest.list;
    dispatch({ type: SCRIPT_REQUEST_PRINT_REQUEST });
    try {
      const res = await HttpService.post('/api/script-request/export', {
        patientGroupIds,
        doctorIds,
        patientIds,
        weekNumber,
        includePRN,
        includeNonPacked,
        includeSuspended,
        isPrintPerPatient,
        isIncludeMedicationDetails,
        includedIds,
        excludedIds,
      });
      dispatch({
        type: SCRIPT_REQUEST_PRINT_REQUEST_SUCCESS,
        payload: { fileName: res.data.fileName, fileUrl: res.data.fileUrl },
      });
    } catch (err) {
      const { errorMessages, errorTitle } = parseError(err);
      dispatch({ type: SCRIPT_REQUEST_PRINT_REQUEST_FAIL });
      dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
    }
  };
};

export const clearHistoryFile = () => {
	return dispatch => {
		dispatch({ type: SCRIPT_REQUEST_CLEAR_HISTORY_FILE });
	};
};
