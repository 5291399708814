import { toInteger } from 'lodash';
import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import i18n from 'i18n';
import { parseError } from 'helpers/Error';
import { parseDataToModel } from './update.actions';

export const CREATE_PATIENT_GROUP = '@PATIENT_GROUP/CREATE_PATIENT_GROUP';
export const CREATE_PATIENT_GROUP_SUCCESS = '@PATIENT_GROUP/CREATE_PATIENT_GROUP_SUCCESS';
export const CREATE_PATIENT_GROUP_FAIL = '@PATIENT_GROUP/CREATE_PATIENT_GROUP_FAIL';

export const CHANGE_PATIENT_GROUP_CREATE_FORM = '@PATIENT_GROUP/CHANGE_PATIENT_GROUP_CREATE_FORM';

export const CLEAR_CREATE_FORM = '@PATIENT_GROUP/CLEAR_CREATE_FORM';

export function createPatientGroup() {
	return (dispatch, getState) => {
		const { data } = getState().patientGroup.create;
		dispatch({ type: CREATE_PATIENT_GROUP });
		const postData = parseDataToModel(data);

    HttpService.post(`api/patientGroups`, postData)
      .then((resp) => {
        dispatch(
          AppActions.showMessage({
            message: i18n.t('Patient group has been added successfully'),
          }),
        );
        dispatch({ type: CREATE_PATIENT_GROUP_SUCCESS, payload: { patientGroupId: resp.data.id } });
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({
          type: CREATE_PATIENT_GROUP_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', 'Warning', errorMessages));
      });
  };
}

export function clearCreateForm() {
	return dispatch => {
		dispatch({ type: CLEAR_CREATE_FORM });
	};
}

export function changePatientGroupCreateForm(data) {
	return dispatch => {
		dispatch({ type: CHANGE_PATIENT_GROUP_CREATE_FORM, payload: { data: { ...data, beds: toInteger(data.beds) } } });
	};
}
