import shortid from 'shortid';
import { toNumber } from 'lodash';
import * as JobPackActions from '../actions/pack.actions';

const defautScanMedication = {
	isAlternativeSameGenericCode: false,
	prescriptionIds: []
};

const initState = {
  loading: false,
  dose: {
    jobNumbers: [],
    packStartDate: '',
    patientGroupName: '',
    patientName: '',
    doseAllocated: {},
    prescriptions: [],
    pageCount: 0,
    rowHeadings: [],
    pageHeadings: [],
    numberOfWeek: 0,
    clientCode: 0,
    foilLabel: '',
    packType: 0,
    patientPhotoId: '',
    blisterSize: 0,
    isLandscapePacking: false,
    isDeletedPacking: false,
    checkChangePrescriptions: [],
  },
  prescriptionIds: [],
  scanMedication: defautScanMedication,
  error: false,
  message: '',
  defaultPrinter: '',
  printing: false,
  resetApnSearchRef: shortid(),
  isCytotoxic: false,
};

export default function packReducer(state = initState, action) {
	const { type, payload } = action;

  switch (type) {
    // PACK_GET_DOSE
    case JobPackActions.PACK_GET_DOSE:
      return {
        ...state,
        loading: true,
        error: false,
        message: '',
        dose: { ...state.dose, isDeletedPacking: false },
      };
    case JobPackActions.PACK_GET_DOSE_SUCCESS:
      return { ...state, loading: false, dose: { ...state.dose, ...payload.data } };
    case JobPackActions.PACK_GET_DOSE_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };
    // PACK_SHOW_PRESCRIPTION
    case JobPackActions.PACK_SHOW_PRESCRIPTION:
      return {
        ...state,
        prescriptionIds: [payload.prescriptionId],
        scanMedication: { ...defautScanMedication },
        isCytotoxic: payload.isCytotoxic,
      };
    // PACK_SEARCH_BY_APN
    case JobPackActions.PACK_SEARCH_BY_APN:
      return {
        ...state,
        loading: true,
        prescriptionIds: [],
        scanMedication: { ...defautScanMedication },
        error: false,
        message: '',
      };
    case JobPackActions.PACK_SEARCH_BY_APN_SUCCESS:
      return {
        ...state,
        loading: false,
        prescriptionIds: payload?.data?.prescriptionIds || [],
        scanMedication: payload?.data || { ...defautScanMedication },
        isCytotoxic: payload?.isCytotoxic,
      };
    case JobPackActions.PACK_SEARCH_BY_APN_FAIL:
      return {
        ...state,
        loading: false,
        scanMedication: { ...defautScanMedication },
        prescriptionIds: [],
        error: true,
        message: payload.message,
      };
    // PACK_RECORD_VPB
    case JobPackActions.PACK_RECORD_VPB:
      return { ...state, loading: true, error: false, message: '' };
    case JobPackActions.PACK_RECORD_VPB_SUCCESS:
      return { ...state, loading: false };
    case JobPackActions.PACK_RECORD_VPB_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };
    // PACK_UPDATE_MATCH_APN_ID
    case JobPackActions.PACK_UPDATE_MATCH_APN_ID:
      return { ...state, scanMedication: { ...defautScanMedication }, prescriptionIds: [] };
    // PACK_RESET_DATA
    case JobPackActions.PACK_RESET_DATA:
      return initState;
    // PACK_GET_PRINTER_SETTING
    case JobPackActions.PACK_GET_PRINTER_SETTING:
      return { ...state, loading: true, error: false, message: '' };
    case JobPackActions.PACK_GET_PRINTER_SETTING_SUCCESS:
      return { ...state, loading: false, defaultPrinter: payload.data };
    case JobPackActions.PACK_GET_PRINTER_SETTING_FAIL:
      return { ...state, loading: false, error: true, message: payload.message, defaultPrinter: '' };
    // PRINTING
    case JobPackActions.PRINTING:
      return { ...state, printing: payload.printing };
    // PACK_CONFIRM_PRESCRIPTION
    case JobPackActions.PACK_CONFIRM_PRESCRIPTION:
      return { ...state, loading: true, error: false, message: '' };
    case JobPackActions.PACK_CONFIRM_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        dose: {
          ...state.dose,
          prescriptions: state.dose.prescriptions.map((p) => {
            if (payload.prescriptionIds.includes(p.id)) {
              return {
                ...p,
                isPackingConfirmed: payload.isPackingConfirmed,
                packingQty:
                  toNumber(payload.prescriptions.find((item) => item.prescriptionId === p.id)?.packingQty) || 0,
                qty: toNumber(payload.prescriptions.find((item) => item.prescriptionId === p.id)?.qty) || 0,
                isManually: payload.prescriptions.find((item) => item.prescriptionId === p.id)?.isManually,
              };
            }

            return { ...p };
          }),
        },
        scanMedication: { ...defautScanMedication },
      };
    case JobPackActions.PACK_CONFIRM_PRESCRIPTION_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };
    // PACK_RESET_APN_SEARCH_REF
    case JobPackActions.PACK_RESET_APN_SEARCH_REF:
      return { ...state, resetApnSearchRef: shortid() };
    case JobPackActions.SKIPPING_PRESCRIPTION:
      return { ...state, loading: true };
    case JobPackActions.SKIPPING_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        dose: {
          ...state.dose,
          prescriptions: state.dose.prescriptions.map((p) => {
            if (payload.prescriptionIds.includes(p.id)) {
              return {
                ...p,
                isSkip: true,
              };
            }
            return { ...p };
          }),
        },
      };
    case JobPackActions.REVERSE_SKIPPED_PRESCRIPTION:
      return { ...state, loading: true };
    case JobPackActions.REVERSE_SKIPPED_PRESCRIPTION_SUCCESS:
      return { ...state, loading: false };
    case JobPackActions.CLEAR_PRESCRIPTION:
      return { ...state, prescriptionIds: [] };
    case JobPackActions.ASSIGN_APN:
      return { ...state, loading: true };
    case JobPackActions.ASSIGN_APN_SUCCESS:
      return { ...state, loading: false };
    case JobPackActions.ASSIGN_APN_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}
