import { combineReducers } from 'redux';

import home from './home.reducers';
import permissions from './permissions.reducers';
import schedule from './schedule.reducers';
import calendar from './calendar.reducers';

const dashboardReducers = combineReducers({
  home,
  permissions,
  schedule,
  calendar,
});

export default dashboardReducers;
