import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const ADD_DOCTOR_TO_PATIENT = '@PATIENT/ADD_DOCTOR_TO_PATIENT';
export const ADD_DOCTOR_TO_PATIENT_SUCCESS = '@PATIENT/ADD_DOCTOR_TO_PATIENT_SUCCESS';
export const ADD_DOCTOR_TO_PATIENT_FAIL = '@PATIENT/ADD_DOCTOR_TO_PATIENT_FAIL';

export const REMOVE_DOCTOR_FROM_PATIENT = '@PATIENT/REMOVE_DOCTOR_FROM_PATIENT';
export const REMOVE_DOCTOR_FROM_PATIENT_SUCCESS = '@PATIENT/REMOVE_DOCTOR_FROM_PATIENT_SUCCESS';
export const REMOVE_DOCTOR_FROM_PATIENT_FAIL = '@PATIENT/REMOVE_DOCTOR_FROM_PATIENT_FAIL';

export const UPDATE_DOCTOR_FROM_PATIENT = '@PATIENT/UPDATE_DOCTOR_FROM_PATIENT';
export const UPDATE_DOCTOR_FROM_PATIENT_SUCCESS = '@PATIENT/UPDATE_DOCTOR_FROM_PATIENT_SUCCESS';
export const UPDATE_DOCTOR_FROM_PATIENT_FAIL = '@PATIENT/UPDATE_DOCTOR_FROM_PATIENT_FAIL';

export const addDoctorToPatient = ({ patientId, doctors }) => {
  return (dispatch) => {
    dispatch({ type: ADD_DOCTOR_TO_PATIENT });
    const postData = {
      doctors,
    };
    HttpService.post(`api/patients/${patientId}/doctors`, postData)
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'patient:ADD_DOCTOR_TO_PATIENT_SUCCESS',
          }),
        );
        dispatch({ type: ADD_DOCTOR_TO_PATIENT_SUCCESS });
        dispatch(AppActions.dataLoader_reload('PatientDoctors'));
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({
          type: ADD_DOCTOR_TO_PATIENT_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', 'Warning', errorMessages));
      });
  };
};

export const removeDoctorFromPatient = (patientId, doctorId) => {
	return dispatch => {
		dispatch({ type: REMOVE_DOCTOR_FROM_PATIENT });
		HttpService.delete(`api/patients/${patientId}/doctors/${doctorId}`)
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'patient:REMOVE_DOCTOR_FROM_PATIENT_SUCCESS'
					})
				);
				dispatch({ type: REMOVE_DOCTOR_FROM_PATIENT_SUCCESS });
				dispatch(AppActions.dataLoader_reload('PatientDoctors'));
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch({
					type: REMOVE_DOCTOR_FROM_PATIENT_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields }
				});
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

export const updateDoctorFromPatient = (patientId, doctor) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DOCTOR_FROM_PATIENT });
    HttpService.put(`api/patients/${patientId}/doctors/${doctor.doctorId}`, doctor)
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'patient:UPDATE_DOCTOR_FROM_PATIENT_SUCCESS',
          }),
        );
        dispatch({ type: UPDATE_DOCTOR_FROM_PATIENT_SUCCESS });
        dispatch(AppActions.dataLoader_reload('PatientDoctors'));
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({
          type: UPDATE_DOCTOR_FROM_PATIENT_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', 'Warning', errorMessages));
      });
  };
};
