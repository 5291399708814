import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const SET_ROLES_FILTER = '@ROLE/SET_ROLES_FILTER';
export const RESET_ROLES_FILTER = '@ROLE/RESET_ROLES_FILTER';

export const REMOVE_ROLE = '@ROLE/REMOVE_ROLE';
export const REMOVE_ROLE_SUCCESS = '@ROLE/REMOVE_ROLE_SUCCESS';
export const REMOVE_ROLE_FAIL = '@ROLE/REMOVE_ROLE_FAIL';

export const CLONE_ROLE = '@ROLE/CLONE_ROLE';
export const CLONE_ROLE_SUCCESS = '@ROLE/CLONE_ROLE_SUCCESS';
export const CLONE_ROLE_FAIL = '@ROLE/CLONE_ROLE_FAIL';

export const setRolesFilter = filter => {
	return dispatch => {
		dispatch({ type: SET_ROLES_FILTER, payload: { filter } });
	};
};

export const resetRolesFilter = () => {
	return dispatch => {
		dispatch({ type: RESET_ROLES_FILTER });
	};
};

export const removeRole = (role) => {
  return (dispatch) => {
    HttpService.abort('removeRole');
    dispatch({ type: REMOVE_ROLE });
    HttpService.delete(`api/roles/${role}`, { cancelToken: 'removeRole' })
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'role:ARCHIVE_ROLE_SUCCESS',
          }),
        );
        dispatch({ type: REMOVE_ROLE_SUCCESS });
        dispatch(AppActions.dataLoader_reload('GET:Roles'));
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({
          type: REMOVE_ROLE_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', 'Warning', errorMessages));
      });
  };
};

export const cloneRole = (roleId, data) => {
	return dispatch => {
		dispatch({ type: CLONE_ROLE });
		HttpService.post(`/api/roles/${roleId}/clone`, data)
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'role:CLONE_ROLE_SUCCESS'
					})
				);
				dispatch({ type: CLONE_ROLE_SUCCESS });
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch({
					type: CLONE_ROLE_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields }
				});
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};
