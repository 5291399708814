import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { diff } from 'deep-diff';
import { isFunction } from 'lodash';
import { HttpService } from 'app/services';
import { usePrevious } from 'react-use';
import * as AppActions from 'app/store/actions';
import * as Utils from 'app/utils/Utils';

const START_PAGE_INDEX = 1;

const defaultOptions = {
	loading: false,
	page: START_PAGE_INDEX,
	size: 20,
	sizes: [10, 20, 50, 100],
	total: 0,
	filter: {},
	sort: null,
	data: [],
	hasMore: true,
	// In some case, such as Job listing screen (src\app\main\job\components\JobManagement\JobManagement.js), we need to use post method to get data
	isPostRequest: false
};

const useDataLoader = options => {
	if (!options.url) throw new Error('WithDataLoader requires `url` option');
	if (!options.onLoad) throw new Error('WithDataLoader requires `onLoad` option');
	if (typeof options.onLoad !== 'function') throw new Error('WithDataLoader requires `onLoad` to be a function');
	if (options.onError && typeof options.onError !== 'function')
		throw new Error('WithDataLoader requires `onError` to be a function');
	const dispatch = useDispatch();

	const getRequestUrl = () => {
		return isFunction(options.url) ? options.url(options.filter) : options.url;
	};

	const rqMethod = options.method || 'GET';
	const rqUrl = getRequestUrl();
	const { name, lazy } = options;
	const reqKey = `${rqMethod}:${rqUrl}`;
	let dataLoaderId = reqKey;
	if (name) dataLoaderId = `${name}`;
	const { loading, page, size, sizes, total, filter, sort, data, reload, hasMore, isPostRequest } = useSelector(
		state => state.dataLoader.dataLoaders[dataLoaderId] || { ...defaultOptions, ...options }
	);

	const prevData = usePrevious(data);
	const prevPageIdx = usePrevious(page);
	const prevPageSize = usePrevious(size);
	const prevFilter = usePrevious(filter);
	const prevSort = usePrevious(sort);
	const prevRequestId = usePrevious(dataLoaderId);

	const fetch = () => {
		if (options.abort) HttpService.abort(reqKey);

		let qs = { page, size };
		if (options.qs && typeof options.qs === 'function') {
			qs = options.qs({
				page,
				size,
				filter,
				sort
			});
		}
		if (qs.query && /[&\/\\#,+()$~%.'":*?<>{}]/g.test(qs.query)) qs.query = encodeURIComponent(qs.query.trim());

		const targetUrl = getRequestUrl();
		if (!targetUrl) return;
		// const qsStr = qs.reduce((prev, cur) => {
		// 	return `${prev}&${cur}=${encodeURIComponent(qsStr[cur])}`;
		// }, '');
		dispatch(AppActions.dataLoader_fetchStart(dataLoaderId));

		let req = null;

		if (isPostRequest) {
			req = HttpService.post(targetUrl, qs, { cancelToken: dataLoaderId });
		} else {
			req = HttpService.get(targetUrl, qs, {
				paramsSerializer: params => Utils.parseParams(params),
				cancelToken: dataLoaderId
			});
		}

		req
			.then(resp => {
				const filterChanged = isFilterChanged();
				const sortChanged = isSortChanged();

				dispatch(AppActions.dataLoader_fetchSuccess(dataLoaderId));

				const { data: returnedData, total: dataTotal } = options.onLoad(resp);

				// by default, we use data returned from api
				let dataResult = returnedData;

				// Only concat data if dataLoader using lazy load, filter/sort doesnt change and page index must greater START PAGE
				if (!filterChanged && !sortChanged && lazy && page !== START_PAGE_INDEX) {
					// if filter changed, sort changed or not lazy request then use the returned data
					dataResult = (prevData || []).concat(returnedData);
				}

				setOptions({ data: dataResult, total: dataTotal, hasMore: returnedData.length >= size });
			})
			.catch(err => {
				if (err && err.message !== 'Request Aborted') {
					// setOptions({ data: [], total: 0 });
					if (options.onError) options.onError(err);
					dispatch(AppActions.dataLoader_fetchError(dataLoaderId, err));
				}
			})
			.finally(() => {
				dispatch(AppActions.dataLoader_fetchEnd(dataLoaderId));
			});
	};

	const loadMore = () => {
		// Check if there are still has more data and dataLoader is not loading state
		if (hasMore && !loading) {
			setPage(prevPageIdx + 1);
		}
	};

	const setOptions = o => dispatch(AppActions.dataLoader_setOptions(dataLoaderId, o));

	const setLoading = l => dispatch(AppActions.dataLoader_setOptions(dataLoaderId, { loading: l }));

	const setPage = p => dispatch(AppActions.dataLoader_setOptions(dataLoaderId, { page: p }));

	const setSize = s => dispatch(AppActions.dataLoader_setOptions(dataLoaderId, { size: s }));

	const setSizes = s => dispatch(AppActions.dataLoader_setOptions(dataLoaderId, { sizes: s }));

	const setTotal = t => dispatch(AppActions.dataLoader_setOptions(dataLoaderId, { total: t }));

	const setFilter = f => dispatch(AppActions.dataLoader_setOptions(dataLoaderId, { filter: f }));

	const setSort = s => dispatch(AppActions.dataLoader_setOptions(dataLoaderId, { sort: s }));

	const setData = d => dispatch(AppActions.dataLoader_setOptions(dataLoaderId, { data: d }));

	const isFilterChanged = () => prevFilter !== undefined && !!diff(prevFilter, filter);

	const isSortChanged = () => prevSort !== undefined && !!diff(prevSort, sort);

	const isChange = () => {
		const pageChanged = prevPageIdx !== undefined && prevPageIdx !== page;
		const sizeChanged = prevPageSize !== undefined && prevPageSize !== size;
		const filterChanged = isFilterChanged();
		const sortChanged = isSortChanged();
		return sortChanged || filterChanged || sizeChanged || pageChanged;
	};

	// Init request
	useEffect(() => {
		// create request instance to store state
		dispatch(AppActions.dataLoader_register(dataLoaderId, { ...defaultOptions, ...options }));

		return () => {
			HttpService.abort(reqKey);
			dispatch(AppActions.dataLoader_remove(dataLoaderId));
		};
		// eslint-disable-next-line
	}, []);

	// Handle reload
	useEffect(() => {
		if (reload) {
			// If the dataLoader is using Lazy load and current page index not START PAGE
			// If current page index is START PAGE then setPage wont trigger any changes
			if (lazy && prevPageIdx !== START_PAGE_INDEX) {
				setPage(START_PAGE_INDEX);
			} else {
				// re-fetch
				fetch();
			}
		}

		// eslint-disable-next-line
	}, [reload]);

	// Handle when the url change
	// useEffect(() => {
	// 	HttpService.abort(reqKey);
	// 	dispatch(AppActions.dataLoader_remove(prevRequestId));
	// 	dispatch(AppActions.dataLoader_register(dataLoaderId, { ...defaultOptions, ...options }));
	// }, [reqKey]);

	// // Handle lazy
	// useEffect(() => {
	// 	if (lazy && isChange()) {
	// 		fetch();
	// 	}

	// 	// eslint-disable-next-line
	// }, [lazy, page, size, filter, sort]);

	return {
		fetch,
		loading,
		page,
		size,
		sizes,
		total,
		filter,
		sort,
		data,
		setLoading,
		setData,
		setPage,
		setSize,
		setSizes,
		setTotal,
		setFilter,
		setSort,
		setOptions,
		isChange,
		loadMore
	};
};

export default useDataLoader;
