import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const SET_PRINT_PROFILE_FILTER = '@PRINT_PROFILE/SET_PRINT_PROFILE_FILTER';

export const REMOVE_PRINT_PROFILE = '@PRINT_PROFILE/REMOVE_PRINT_PROFILE';
export const REMOVE_PRINT_PROFILE_SUCCESS = '@PRINT_PROFILE/REMOVE_PRINT_PROFILE_SUCCESS';
export const REMOVE_PRINT_PROFILE_FAIL = '@PRINT_PROFILE/REMOVE_PRINT_PROFILE_FAIL';

export const setPrintProfileFilter = filter => {
	return dispatch => {
		dispatch({ type: SET_PRINT_PROFILE_FILTER, payload: { filter } });
	};
};

export const removePrintProfile = printProfileId => {
	return dispatch => {
		HttpService.abort(`removePrintProfile`);
		dispatch({ type: REMOVE_PRINT_PROFILE });
		HttpService.delete(`api/printprofiles/${printProfileId}`, { cancelToken: "removePrintProfile" })
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'printProfile:ARCHIVE_PRINT_PROFILE_SUCCESS'
					})
				);
				dispatch({ type: REMOVE_PRINT_PROFILE_SUCCESS });
				dispatch(AppActions.dataLoader_reload(`GET:/api/printprofiles`));
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch({ type: REMOVE_PRINT_PROFILE_FAIL, payload: { message: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};
