import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import i18next from 'i18next';
import { MENU_ENUM } from 'app/main/role/constants';
import en from '../i18n/en';

i18next.addResourceBundle('en', 'patientGroup', en);

const UpdatePatientGroupConfig = {
	settings: {
		layout: {
			config: {}
		},
		nav: {
			title: 'Update Patient Group'
		}
	},
	auth: authRoles.user,
	key: MENU_ENUM.PATIENT_GROUP,
	permission: PERMISSION.UPDATE,
	routes: [
		{
			path: '/patient-group/:id',
			component: React.lazy(() => import('./UpdatePatientGroup'))
		}
	]
};

export default UpdatePatientGroupConfig;
