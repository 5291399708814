import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const EDIT_MEDICARE_NUMBER = '@CLAIM/EDIT_MEDICARE_NUMBER';
export const EDIT_MEDICARE_NUMBER_SUCCESS = '@CLAIM/EDIT_MEDICARE_NUMBER_SUCCESS';
export const EDIT_MEDICARE_NUMBER_FAIL = '@CLAIM/EDIT_MEDICARE_NUMBER_FAIL';

export const editMedicare = (_patientId, _medicareNumber) => {
  return (dispatch) => {
    dispatch({ type: EDIT_MEDICARE_NUMBER });
    return HttpService.patch(`api/patients/${_patientId}/medicare-number`, { medicareNumber: _medicareNumber })
      .then(() => {
        dispatch({ type: EDIT_MEDICARE_NUMBER_SUCCESS });
      })
      .catch((error) => {
        const { errorMessages, errorTitle } = parseError(error);
        dispatch({
          type: EDIT_MEDICARE_NUMBER_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorTitle },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};
