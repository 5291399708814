const locale = {
  QS_TITLE: 'QScript',
  BUTTON_QSCRIPT: 'QScript',
  PACK_CYCLE: 'Pack Cycle',
  PACK_START_DATE: 'Pack Start Date {{required}}',
  SCRIPT_FILTER: 'Script Filters',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  MEDICATION: 'Medication',
  CURRENT_BALANCE: 'Current Balance',
  REQUIRE_BALANCE_PER_WEEK: '<p>Required Balance</p><p>Per Week</p>',
  LAST_DISPENSE: 'Last Dispense Date',
  BUTTON_DISPLAY_ALL_SCRIPT: 'Display All Script',
  BUTTON_HIDE_UNSELECTED: 'Hide Unselected',
  REPEAT_REMAINING: 'Repeats \n Remaining',
};

export default locale;
