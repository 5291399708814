import * as MedicationProfileActions from '../actions/update.medicationProfiles.actions';

const initialState = {
  data: [],
  selectedFoilIndex: -1,
  showPrescriptionForm: false,
  loading: false,
  submitting: false,
  submitSuccess: false,
  message: '',
  nextFoilLabel: null,
  packTypeDefault: 0,
};

const medicationProfiles = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MedicationProfileActions.GET_FOILS:
      return { ...state, loading: true };
    case MedicationProfileActions.GET_FOILS_SUCCESS:
      return { ...state, loading: false, data: payload.foils };
    case MedicationProfileActions.GET_FOILS_FAIL:
      return { ...state, loading: false, message: payload.message };
    case MedicationProfileActions.ADD_FOIL:
      return { ...state, submitting: true, submitSuccess: false };
    case MedicationProfileActions.ADD_FOIL_SUCCESS:
      return { ...state, submitting: false, submitSuccess: true, data: [...state.data, payload.newFoil] };
    case MedicationProfileActions.ADD_FOIL_FAIL:
      return { ...state, submitting: false, submitSuccess: false, message: payload.message };
    case MedicationProfileActions.UPDATE_FOIL:
      return { ...state, submitting: true, submitSuccess: false };
    case MedicationProfileActions.UPDATE_FOIL_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitSuccess: true,
        data: state.data.map((i) => {
          if (i.foilId === payload.data.foilId) {
            return { ...i, ...payload.data };
          }
          return i;
        }),
      };
    case MedicationProfileActions.UPDATE_FOIL_FAIL:
      return { ...state, submitting: false, submitSuccess: false, message: payload.message };
    case MedicationProfileActions.DELETE_FOIL:
      return { ...state, submitting: true, submitSuccess: false };
    case MedicationProfileActions.DELETE_FOIL_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitSuccess: true,
        data: state.data.filter((i) => i.foilId !== payload.foilId),
      };
    case MedicationProfileActions.DELETE_FOIL_FAIL:
      return { ...state, submitting: false, message: payload.message };
    case MedicationProfileActions.GET_NEXT_FOIL_LABEL:
      return { ...state, loading: true, submitSuccess: false };
    case MedicationProfileActions.GET_NEXT_FOIL_LABEL_SUCCESS:
      return { ...state, loading: false, nextFoilLabel: payload.nextLabel, packTypeDefault: payload.packTypeDefault };
    case MedicationProfileActions.GET_NEXT_FOIL_LABEL_FAIL:
      return { ...state, loading: false, message: payload.message };
    case MedicationProfileActions.RESET_NEXT_FOIL_LABEL:
      return { ...state, nextFoilLabel: null };
    case MedicationProfileActions.SET_SELECTED_FOIL_INDEX:
      return { ...state, selectedFoilIndex: payload.index };
    case MedicationProfileActions.SHOW_PRESCRIPTION_FORM:
      return { ...state, showPrescriptionForm: true };
    case MedicationProfileActions.HIDE_PRESCRIPTION_FORM:
      return { ...state, showPrescriptionForm: false };
    default:
      return state;
  }
};

export default medicationProfiles;
