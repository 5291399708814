// Reference: https://stackoverflow.com/questions/25777826/onclick-works-but-ondoubleclick-is-ignored-on-react-component
import { useState, useEffect, useRef } from 'react';

export default function useSimpleAndDoubleClick(actionSimpleClick, actionDoubleClick, delay = 200) {
	const [click, setClick] = useState(0);
	const params = useRef(null);

	useEffect(() => {
		const timer = setTimeout(() => {
			// simple click
			if (click === 1) actionSimpleClick(...params.current);
			setClick(0);
		}, delay);

		// the duration between this click and the previous one
		// is less than the value of delay = double-click
		if (click === 2) actionDoubleClick(...params.current);

		return () => clearTimeout(timer);
	}, [click]);

	return (...rest) => {
		setClick(prev => prev + 1);
		params.current = rest;
	};
}
