import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const MyRadio = props => {
	const { label, disabled, ...rest } = props;
	if (!label) {
		return <Radio color="primary" size="small" disabled={disabled} {...rest} />;
	}
	return (
		<FormControlLabel control={<Radio color="primary" size="small" {...rest} />} label={label} disabled={disabled} />
	);
};

export default MyRadio;
