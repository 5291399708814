import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { split } from 'lodash';
import { useHistory } from 'react-router';
import clsx from 'clsx';

import MessageSkeleton from './MessageSkeleton';

const useStyles = makeStyles((theme) => ({
  buttonMoreDetail: {
    color: theme.palette.primary.main,
    fontSize: '1.5rem',
    marginTop: theme.spacing(1),
  },
}));

export default function DataMirationMessage(props) {
  /** message schema
		{
			Message: string[]
			Type: number
		}
	*/
  const { message } = props;
  const classes = useStyles();
  const history = useHistory();

  const handeMoreDetail = (_handleClose) => () => {
    _handleClose();
    history.push('/home/notifications');
  };

  const parsedMessage = split(JSON.parse(message?.Message || ''), ',');

  return (
    <MessageSkeleton
      renderMessage={({ handleClose }) => (
        <div className="flex flex-col items-center">
          {parsedMessage.length < 3 && parsedMessage.map((m, index) => <p key={`message-${index}`}>{m}</p>)}
          {parsedMessage.length > 3 && parsedMessage.slice(0, 3).map((m, index) => <p key={`message-${index}`}>{m}</p>)}
          {parsedMessage.length > 3 && (
            <button
              type="button"
              onClick={handeMoreDetail(handleClose)}
              className={clsx(classes.buttonMoreDetail, 'text-center')}
            >
              More Detail
            </button>
          )}
        </div>
      )}
    />
  );
}
