import * as UserAction from '../actions/list.actions';

const defaultFilter = {
	roleIds: []
};

const initialState = {
	error: null,
	message: 'LIST_EMPTY_MESSAGE',
	filter: defaultFilter,
	submitting: false
};

const list = (state = initialState, action) => {
	switch (action.type) {
		case UserAction.SET_USERS_FILTER: {
			return { ...state, filter: action.payload.filter };
		}
		case UserAction.RESET_USERS_FILTER: {
			return { ...state, filter: defaultFilter };
		}
		case UserAction.REMOVE_USER: {
			return { ...state, submitting: true };
		}
		case UserAction.REMOVE_USER_SUCCESS: {
			return { ...state, submitting: false };
		}
		case UserAction.REMOVE_USER_FAIL: {
			return { ...state, submitting: false, error: action.payload.error, message: action.payload.message };
		}
		default: {
			return state;
		}
	}
};

export default list;
