import * as Actions from '../../actions/fuse';

const initialState = {
	foldedOpen: false,
	mobileOpen: false,
	subNavOpen: null,
	ipAddress: '',
};

const navbar = (state = initialState, action) => {
	switch (action.type) {
		case Actions.TOGGLE_FOLDED_NAVBAR: {
			return {
				...state,
				foldedOpen: !state.foldedOpen
			};
		}
		case Actions.OPEN_FOLDED_NAVBAR: {
			return {
				...state,
				foldedOpen: true
			};
		}
		case Actions.CLOSE_FOLDED_NAVBAR: {
			return {
				...state,
				foldedOpen: false
			};
		}
		case Actions.OPEN_SUB_NAVBAR: {
			return {
				...state,
				subNavOpen: action.payload
			};
		}
		case Actions.CLOSE_SUB_NAVBAR: {
			return {
				...state,
				subNavOpen: null
			};
		}
		case Actions.TOGGLE_MOBILE_NAVBAR: {
			return {
				...state,
				mobileOpen: !state.mobileOpen
			};
		}
		case Actions.OPEN_MOBILE_NAVBAR: {
			return {
				...state,
				mobileOpen: true
			};
		}
		case Actions.CLOSE_MOBILE_NAVBAR: {
			return {
				...state,
				mobileOpen: false
			};
		}
		case Actions.SET_IP_ADDRESS: {
			return {
				...state,
				ipAddress: action.payload
			};
		}
		default: {
			return state;
		}
	}
};

export default navbar;
