import { HttpService } from 'app/services';
import { parseError } from 'helpers/Error';
import * as AppActions from 'app/store/actions';
import { get, upperFirst, map, reduce } from 'lodash';
import { SORT_DIRECTION } from 'components/DataTable';

export const CHANGE_FILTER = '@Q_SCRIPT/CHANGE_FILTER';
export const CHANGE_SORT = '@Q_SCRIPT/CHANGE_SORT';
export const PRINT_REPORT_SUCCESS = '@Q_SCRIPT/PRINT_REPORT_SUCCESS';
export const PRINT_REPORT = '@Q_SCRIPT/PRINT_REPORT';
export const CLEAR_HISTORY_FILE = '@Q_SCRIPT/CLEAR_HISTORY_FILE';
export const DISPLAY_ALL_SCRIPT = '@Q_SCRIPT/DISPLAY_ALL_SCRIPT';
export const HIDE_UNSELECTED_SCRIPT = '@Q_SCRIPT/HIDE_UNSELECTED_SCRIPT';
export const CLEAR_HISTORY_DETAIL = '@Q_SCRIPT/CLEAR_HISTORY_DETAIL';
export const GET_FAVORITES = '@Q_SCRIPT/GET_FAVORITES';
export const GET_FAVORITES_SUCCESS = '@Q_SCRIPT/GET_FAVORITES_SUCCESS';
export const UPDATE_FAVORITE = '@Q_SCRIPT/UPDATE_FAVORITE';
export const UPDATE_FAVORITE_SUCCESS = '@Q_SCRIPT/UPDATE_FAVORITE_SUCCESS';
export const GET_INSUFFICIENT_PILL_BALANCE = '@Q_SCRIPT/INSUFFICIENT_PILL_BALANCE';
export const GET_INSUFFICIENT_PILL_BALANCE_SUCCESS = '@Q_SCRIPT/INSUFFICIENT_PILL_BALANCE_SUCCESS';

export function getFavorites(type) {
  return (dispatch) => {
    dispatch({ type: GET_FAVORITES });
    HttpService.get('api/favoriteItem', type)
      .then((resp) => {
        dispatch({ type: GET_FAVORITES_SUCCESS, payload: resp.data });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function updateFavorite(favoriteId, isFavorite) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FAVORITE });
    return HttpService.put(`api/favoriteItem/${favoriteId}`, { isFavorite })
      .then(() => {
        dispatch({ type: UPDATE_FAVORITE_SUCCESS });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function changeFilter(filter) {
	return (dispatch) => {
		dispatch({ type: CHANGE_FILTER, payload: filter });
	};
}
export function changeSort(so) {
  const sortData = {
    sortField: upperFirst(get(so, 'field')),
    sortOrder: get(so, 'direction') === SORT_DIRECTION.ASC ? 1 : -1,
  };
  return (dispatch) => {
    dispatch({ type: CHANGE_SORT, payload: sortData });
  };
}

export function generateQScriptReport(prescriptionIds) {
  return (dispatch, getState) => {
    const { filter, sort } = getState().qscript.list;
    const payload = { ...filter, ...sort, prescriptionIds };
    dispatch({ type: PRINT_REPORT });
    HttpService.post('api/quickdispense/qscript', payload)
      .then((resp) => {
        dispatch({ type: PRINT_REPORT_SUCCESS, payload: resp?.data });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export const displayAllScript = () => {
  return (dispatch) => {
    dispatch({ type: DISPLAY_ALL_SCRIPT });
  };
};

export const hideUnSelectedScript = () => {
  return (dispatch) => {
    dispatch({ type: HIDE_UNSELECTED_SCRIPT });
  };
};

export const clearHistoryFile = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_HISTORY_FILE });
  };
};

export const resetQScript = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_HISTORY_DETAIL });
  };
};

export function getInsufficientPillBalance() {
  return (dispatch, getState) => {
    const { filter } = getState().qscript.list;
    const payload = { ...filter };
    dispatch({ type: GET_INSUFFICIENT_PILL_BALANCE });
    HttpService.post('api/quickdispense/insufficient-pill-balance', payload)
      .then((resp) => {
        const allPillBalances = map(resp?.data, 'prescriptionId');
        const insufficientPillBalances = reduce(
          resp?.data,
          (pre, cur) => {
            if (cur.isInsufficientPillBalance) {
              pre.push(cur.prescriptionId);
            }
            return pre;
          },
          [],
        );
        dispatch({
          type: GET_INSUFFICIENT_PILL_BALANCE_SUCCESS,
          payload: { insufficientPillBalances, allPillBalances },
        });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}
