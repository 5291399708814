import * as UpdateDocumentsActions from '../actions/update.documents.actions';
import * as UpdateBaseActions from '../actions/update.base.actions';

const initialState = {
  selectedDocument: {
    documentId: '',
    description: '',
    resourceDocumentId: '',
  },
  submitting: false,
  error: null,
};

const updateDocument = (state = initialState, action) => {
  switch (action.type) {
    case UpdateDocumentsActions.SUBMIT_PATIENT_DOCUMENT:
      return {
        ...state,
        submitting: true,
        error: null,
      };
    case UpdateDocumentsActions.SUBMIT_PATIENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case UpdateDocumentsActions.SUBMIT_PATIENT_DOCUMENT_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload.message,
      };
    case UpdateDocumentsActions.UPDATE_PATIENT_DOCUMENT:
      return {
        ...state,
        submitting: true,
        error: null,
      };
    case UpdateDocumentsActions.UPDATE_PATIENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case UpdateDocumentsActions.UPDATE_PATIENT_DOCUMENT_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload.message,
      };
    case UpdateDocumentsActions.SET_SELECTED_DOCUMENT:
      return {
        ...state,
        selectedDocument: action.payload.document,
      };
    case UpdateBaseActions.CLEAR_PATIENT_DATA:
      return initialState;
    default:
      return state;
  }
};

export default updateDocument;
