import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { makeStyles } from '@material-ui/core/styles';
import Navigation from 'app/fuse-layouts/shared-components/Navigation';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
  appbar: {
    backgroundColor: theme.palette.background.default,
  },
}));

function NavbarLayout1(props) {
	const classes = useStyles();
	// const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	// const navbar = useSelector(({ fuse }) => fuse.navbar);
	// const { folded } = config.navbar;
	// const foldedAndOpened = folded && navbar.foldedOpen;

	return (
		<div className={clsx('flex flex-col h-full', props.className)}>
			<FuseScrollbars className={clsx(classes.content, 'flex-1 mt-20')} option={{ suppressScrollX: true }}>
				<Navigation layout="vertical" />
			</FuseScrollbars>
		</div>
	);
}

export default NavbarLayout1;
