import * as vpbActions from '../actions';
import { PACKING_STATUS_TYPES } from '../../constants';

const initialState = {
  filter: {
    patientGroupIds: [],
    weekCount: '1',
    patientIds: [],
    packStartDate: '',
    isShowPacked: true,
    isShowCeasedDeletedMed: false,
    isShowPackedPRN: false,
    packingStatus: [PACKING_STATUS_TYPES.ACTIVE],
    isDisplayInsufficentBalance: false,
  },
  printOptions: {
    isNegativeOnly: false,
  },
  favoriteItems: [],
  fileName: null,
  loading: false,
  fileUrl: null,
  printing: false,
  printingSuccess: false,
  importing: false,
  exporting: false,
  importSuccess: false,
};

const list = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case vpbActions.GET_FAVORITES:
      return { ...state, loading: true };
    case vpbActions.GET_FAVORITES_SUCCESS:
      return { ...state, favoriteItems: action.payload, loading: false };
    case vpbActions.UPDATE_FAVORITE:
      return { ...state, loading: true };
    case vpbActions.UPDATE_FAVORITE_SUCCESS:
      return { ...state, loading: false };
    case vpbActions.VPB_CHANGE_FILTER:
      return { ...state, filter: { ...state.filter, ...payload } };
    case vpbActions.VPB_CHANGE_PRINT_OPTIONS:
      return { ...state, printOptions: { ...state.printOptions, ...payload } };
    case vpbActions.VPB_RESET_DATA:
      return initialState;
    case vpbActions.VPB_RESET_DATA_PRINT:
      return { ...state, fileName: null, fileUrl: null };
    case vpbActions.VPB_IMPORT:
      return { ...state, importing: true, importSuccess: false };
    case vpbActions.VPB_IMPORT_SUCCESS:
      return { ...state, importing: false, importSuccess: true };
    case vpbActions.VPB_IMPORT_FAIL:
      return { ...state, importing: false, importSuccess: false };
    case vpbActions.VPB_EXPORT:
      return { ...state, exporting: true };
    case vpbActions.VPB_EXPORT_SUCCESS:
      return { ...state, exporting: false };
    case vpbActions.VPB_EXPORT_FAIL:
      return { ...state, loexportingading: false };
    case vpbActions.VPB_PRINT_FORECASE_REPORT:
    case vpbActions.VPB_PRINT_REPORT:
      return { ...state, printing: true, printingSuccess: false };
    case vpbActions.VPB_PRINT_FORECASE_REPORT_SUCCESS:
    case vpbActions.VPB_PRINT_REPORT_SUCCESS:
      return {
        ...state,
        printing: false,
        printingSuccess: true,
        fileName: payload?.fileName,
        fileUrl: payload?.fileUrl,
      };
    case vpbActions.VPB_PRINT_FORECASE_REPORT_FAIL:
    case vpbActions.VPB_PRINT_REPORT_FAIL:
      return { ...state, printing: false, printingSuccess: false };
    case vpbActions.VPB_CLEAR_HISTORY_FILE:
      return { ...state, fileName: null, fileUrl: null };
    case vpbActions.UPDATE_DISPLAY_INSUFFICENT_BALANCE:
      return {
        ...state,
        loading: true,
      };
    case vpbActions.UPDATE_DISPLAY_INSUFFICENT_BALANCE_SUCCESS:
      return {
        ...state,
        filter: { ...state.filter, isDisplayInsufficentBalance: action.payload?.isDisplayInsufficentBalance },
        loading: false,
      };
    default:
      return state;
  }
};

export default list;
