import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';
import { PRINT_ONLY } from '../../constants';

export const PRINT_FOIL = '@PRINT/PRINT_FOIL';
export const PRINT_FOIL_SUCCESS = '@PRINT/PRINT_FOIL_SUCCESS';
export const PRINT_FOIL_FAIL = '@PRINT/PRINT_FOIL_FAIL';

export const UPDATE_PRINT_FOIL_FORM = '@PRINT/UPDATE_PRINT_FOIL_FORM';

export const SET_QUICK_PRINT_FOIL = '@PRINT/SET_QUICK_PRINT_FOIL';
export const CLEAR_QUICK_PRINT_FOIL = '@PRINT/CLEAR_QUICK_PRINT_FOIL';

export const CREATE_JOB = '@PRINT/CREATE_JOB';
export const CREATE_JOB_SUCCESS = '@PRINT/CREATE_JOB_SUCCESS';
export const CREATE_JOB_FAIL = '@PRINT/CREATE_JOB_FAIL';

export const CLEAR_PRINT_FOIL_DATA = '@PRINT/CLEAR_PRINT_FOIL_DATA';

export const CLEAR_HISTORY_FILE = '@PRINT/CLEAR_HISTORY_FILE';

export const printFoil = prescriptionIds => {
	return (dispatch, getState) => {
		const { printFoilForm } = getState().print.printFoil;
		dispatch({ type: PRINT_FOIL });
		const postData = parsePrintFormToDBModel(printFoilForm, prescriptionIds);

		const urlRequest = postData.printLabel ? '/api/foils/printlabel' : '/api/foils/print';

		HttpService.post(urlRequest, postData)
			.then(resp => {
				if (resp.data) {
					dispatch({
						type: PRINT_FOIL_SUCCESS,
						payload: { data: resp.data }
					});
				} else {
					dispatch({ type: PRINT_FOIL_FAIL });
					dispatch(AppActions.createDialogMessage('info', 'print:NO_CONTENT_HEADER', 'print:NO_CONTENT_MESSAGE'));
				}
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch({
					type: PRINT_FOIL_FAIL,
					payload: { message: errorMessages, error: errorFields }
				});
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

export const updatePrintFoilForm = data => {
	return dispatch => {
		dispatch({ type: UPDATE_PRINT_FOIL_FORM, payload: { data } });
	};
};

export function clearPrintFoilData() {
	return dispatch => {
		dispatch({ type: CLEAR_PRINT_FOIL_DATA });
	};
}

export const setQuickPrintFoil = data => {
	return dispatch => {
		dispatch({ type: SET_QUICK_PRINT_FOIL, payload: { data } });
	};
};

export const createJob = (prescriptionIds = []) => {
	return (dispatch, getState) => {
		const { printFoilForm } = getState().print.printFoil;
		const postData = parsePrintFormToDBModel(printFoilForm, prescriptionIds);

		dispatch({ type: CREATE_JOB });
		return HttpService.post(`/api/packjobs/print`, postData)
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'print:JOB_CREATE_SUCCESS'
					})
				);
				dispatch({ type: CREATE_JOB_SUCCESS });
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch({
					type: CREATE_JOB_FAIL,
					payload: { message: errorMessages, error: errorFields }
				});
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

export const clearHistoryFile = () => {
	return dispatch => {
		dispatch({ type: CLEAR_HISTORY_FILE });
	};
};

const parsePrintFormToDBModel = (printFoilForm, prescriptionIds = []) => {
	const parsedData = {
		...printFoilForm,
		printPrescriptionIds: prescriptionIds.length > 0 ? prescriptionIds : undefined,
		isStandard: printFoilForm.printOnly === PRINT_ONLY.STANDARD,
		isShortCourse: printFoilForm.printOnly === PRINT_ONLY.SHORTCOURSE,
		isWarfarin: printFoilForm.printOnly === PRINT_ONLY.WARFARIN,
		isSinemet: printFoilForm.printOnly === PRINT_ONLY.SINEMET,
		isS8: printFoilForm.printOnly === PRINT_ONLY.S8,
		isPRN: printFoilForm.printOnly === PRINT_ONLY.PRN,
		isCytotoxic: printFoilForm.printOnly === PRINT_ONLY.CYTOTOXIC,
		isS4N: printFoilForm.printOnly === PRINT_ONLY.S4N,
		foildIds: printFoilForm.isPrintAll ? null : printFoilForm.foildIds,
		patientGroupId: printFoilForm.isPrintAll ? printFoilForm.patientGroupId : null,
		partWeekPack: printFoilForm.partWeekPack || 7,
		numberOfWeek: printFoilForm.partWeekPack ? 1 : printFoilForm.numberOfWeek,
		doseTimeOption: printFoilForm.doseTimeOption,
		foils: undefined
	};

	delete parsedData.printOnly;

	return parsedData;
};
