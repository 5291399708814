import * as AppActions from '../actions';

const initialState = {
	dataLoaders: {}
};

const dataLoader = (state = initialState, action) => {
	switch (action.type) {
		case AppActions.DATALOADER_FETCH_START: {
			return {
				...state,
				dataLoaders: {
					...state.dataLoaders,
					[action.payload.id]: {
						...state.dataLoaders[action.payload.id],
						loading: true
					}
				}
			};
		}
		case AppActions.DATALOADER_FETCH_END: {
			return state.dataLoaders[action.payload.id]
				? {
						...state,
						dataLoaders: {
							...state.dataLoaders,
							[action.payload.id]: {
								...state.dataLoaders[action.payload.id],
								loading: false
							}
						}
				  }
				: { ...state };
		}
		case AppActions.DATALOADER_FETCH_SUCCESS: {
			return state.dataLoaders[action.payload.id]
				? {
						...state,
						dataLoaders: {
							...state.dataLoaders,
							[action.payload.id]: {
								...state.dataLoaders[action.payload.id]
							}
						}
				  }
				: { ...state };
		}
		case AppActions.DATALOADER_FETCH_ERROR: {
			return {
				...state,
				dataLoaders: {
					...state.dataLoaders,
					[action.payload.id]: {
						...state.dataLoaders[action.payload.id],
						error: action.payload.data
					}
				}
			};
		}
		case AppActions.DATALOADER_REGISTER: {
			return {
				...state,
				dataLoaders: {
					...state.dataLoaders,
					[action.payload.id]: {
						...state.dataLoaders[action.payload.id],
						loading: false,
						...action.payload.options
					}
				}
			};
		}
		case AppActions.DATALOADER_REMOVE: {
			delete state.dataLoaders[action.payload.id];
			return state;
		}
		case AppActions.DATALOADER_SET_OPTIONS: {
			return state.dataLoaders[action.payload.id]
				? {
						...state,
						dataLoaders: {
							...state.dataLoaders,
							[action.payload.id]: {
								...state.dataLoaders[action.payload.id],
								...action.payload.options
							}
						}
				  }
				: { ...state };
		}
		case AppActions.DATALOADER_RELOAD: {
			return {
				...state,
				dataLoaders: {
					...state.dataLoaders,
					[action.payload.id]: {
						...state.dataLoaders[action.payload.id],
						reload: new Date().getTime()
					}
				}
			};
		}
		default: {
			return state;
		}
	}
};

export default dataLoader;
