const locale = {
  BUTTON_SHOW_LOG: 'Logs',
  TABLE_HEADER_ROLE_LEVEL: 'Role Level',
  DETAIL_ROLE_LEVEL: 'Role Level',
  DETAIL_CHARTS: 'Charts',
  DETAIL_ID: 'ID',
  DETAIL_HEIGHT: 'Height',
  DETAIL_WIDTH: 'Width',
  DETAIL_FILTER: 'Filter',
  DETAIL_ORDER: 'Order',
  BUTTON_ADD_MORE: 'Add more',
  BUTTON_DELETE: 'Delete',
  UPDATE_DASHBOARD_PERMISSION_SUCCESS: 'Dashboard permission has been updated successfully',
  BUTTON_PRINT: 'Print',
  BUTTON_DONE: 'Done',
  CALENDAR_TITLE: 'Title',
  CALENDAR_BUTTON_TODAY: 'Today',
  CALENDAR_ADD_DETAILS: 'Details',
  CALENDAR_REPEAT: 'Repeat',
  CREATE_CALENDAR_SUCCESS: 'Calendar has been created successfully',
  CREATE_CALENDAR_FAIL: 'Calendar has been create fail',
  CALENDAR_DIALOG_TITLE: 'Add {{type}}',
  DELETE_SCHEDULE_MESSAGE: 'Are you sure want to delete {{type}}?',
  DELETE_SCHEDULE_TITLE: 'Delete {{type}}',
  SCHEDULE_TITLE: 'Schedule',
  EDIT_DIALOG_SCHEDULE: 'Edit {{type}}',
  BUTTON_SAVE_CHANGE: 'Save Changes',
  SCHEDULE_TITLE_TITLE: 'Title',
  SCHEDULE_TITLE_DETAIL: 'Detail',
};

export default locale;
