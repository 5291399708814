import { isAfter } from 'date-fns';
import AppConfig from 'app/Config';
import * as UpdateDetailsActions from '../actions/update.details.actions';
import * as UpdateBaseActions from '../actions/update.base.actions';
import * as UpdateAllergiesActions from '../actions/update.allergies.actions';

const initialState = {
  data: {
    patientGroupType: '',
    patientGroupId: '',
    patientGroupName: '',
    area: '',
    facilityWard: '',
    room: '',
    urn: '',
    ihi: '',
    photoTakenDate: null,
    suspendFromDate: null,
    suspendToDate: null,
    medicareNumber: '',
    dvaNum: '',
    diagnosis: [],
    gender: '',
    daaFunding: '',
    email: '',
    dob: null,
    firstName: '',
    lastName: '',
    preferredName: '',
    packingStatus: '',
    patientStatus: '',
    photoId: '',
    street: '',
    suburb: '',
    state: '',
    country: '',
    postalCode: '',
    ward: '',
    lastCheckedDate: '',
    phone: '',
    packType: '',
    doctorName: '',
    concessionNum: '',
    safetyNetNum: '',
    diabeticNum: '',
    repatNum: '',
    externalPatientId: '',
    mediCareExpiry: null,
    dateImported: null,
    title: '',
    concession: 0,
    isVisionImpaired: 'false',
    isNoAllergy: false,
    allergies: [],
    days30Dispensing: 'false',
    isAnySimilarNamePatient: false,
  },
  __loading: false,
  deleteLoading: false,
  error: null,
  message: '',
};

const updateDetails = (state = initialState, action) => {
  switch (action.type) {
    case UpdateDetailsActions.UPDATE_PAITENT_PACKING_STATUS:
      return { ...state, data: { ...state.data, packingStatus: action.payload.packingStatus } };
    case UpdateBaseActions.GET_PATIENT_DETAIL_SUCCESS:
      return {
        ...initialState,
        data: {
          patientGroupType: action.payload.data.patientGroupType,
          patientGroupId: action.payload.data.patientGroupId,
          patientGroupName: action.payload.data.patientGroupName,
          area: action.payload.data.area,
          facilityWard: action.payload.data.facilityWard,
          room: action.payload.data.room,
          urn: action.payload.data.urn,
          ihi: action.payload.data.ihi,
          photoTakenDate: action.payload.data.photoTakenDate,
          suspendFromDate: action.payload.data.suspendFromDate,
          suspendToDate: action.payload.data.suspendToDate,
          medicareNumber: action.payload.data.medicareNumber,
          dvaNum: action.payload.data.dvaNum,
          diagnosis: action.payload.data.diagnosis,
          gender: action.payload.data.gender,
          daaFunding: action.payload.data.daaFunding.toString(),
          email: action.payload.data.email,
          dob: isAfter(new Date(action.payload.data.dob), AppConfig.minDate) ? action.payload.data.dob : null,
          firstName: action.payload.data.firstName,
          lastName: action.payload.data.lastName,
          preferredName: action.payload.data.preferredName,
          packingStatus: action.payload.data.packingStatus.toString(),
          patientStatus: action.payload.data.patientStatus.toString(),
          photoId: action.payload.data.photoId,
          packType: action.payload.data.packType,
          street: action.payload.data.street || '',
          suburb: action.payload.data.suburb || '',
          state: action.payload.data.state || '',
          country: action.payload.data.country || '',
          postalCode: action.payload.data.postalCode || '',
          ward: action.payload.data.ward || '',
          lastCheckedDate: action.payload.data.lastCheckedDate || '',
          phone: action.payload.data.phone || '',
          doctorName: action.payload.data.doctorName || '',
          concessionNum: action.payload.data.concessionNum || '',
          safetyNetNum: action.payload.data.safetyNetNum || '',
          diabeticNum: action.payload.data.diabeticNum || '',
          repatNum: action.payload.data.repatNum || '',
          externalPatientId: action.payload.data.externalPatientId,
          mediCareExpiry: action.payload.data.mediCareExpiry,
          dateImported: action.payload.data.dateImported,
          title: action.payload.data.title || '',
          concession: action.payload.data.concession.toString(),
          distribution: action.payload.data.distribution?.toString(),
          isVisionImpaired:
            action.payload.data.isVisionImpaired || action.payload.data.visionImpaired ? 'true' : 'false',
          isNoAllergy: action.payload.data.isNoAllergy,
          allergies: action.payload.data.allergies,
          days30Dispensing:
            action.payload.data.days30Dispensing || action.payload.data.days30Dispensing ? 'true' : 'false',
          isAnySimilarNamePatient: action.payload.data.isAnySimilarNamePatient,
        },
      };
    case UpdateDetailsActions.UPDATE_PATIENT_DETAILS_START:
      return { ...state, __loading: true };
    case UpdateDetailsActions.UPDATE_PATIENT_DETAILS_SUCCESS:
      return { ...state, __loading: false, data: action.payload.data };
    case UpdateDetailsActions.UPDATE_PATIENT_DETAILS_FAILED:
      return { ...state, __loading: false, error: action.payload.error, message: action.payload.message };
    case UpdateDetailsActions.UPDATE_PATIENT_DETAILS_DATA:
      return { ...state, data: { ...state.data, ...action.payload.data } };
    case UpdateDetailsActions.UPDATE_REMOVE_PATIENT:
      return { ...state, deleteLoading: true };
    case UpdateDetailsActions.UPDATE_REMOVE_PATIENT_SUCCESS:
      return { ...state, deleteLoading: false };
    case UpdateDetailsActions.UPDATE_REMOVE_PATIENT_FAIL:
      return { ...state, deleteLoading: false, error: action.payload };
    case UpdateBaseActions.CLEAR_PATIENT_DATA:
      return initialState;
    case UpdateAllergiesActions.UPDATE_NO_ALLERGY_SUCCESS:
      return { ...state, data: { ...state.data, isNoAllergy: action.payload } };
    default:
      return state;
  }
};

export default updateDetails;
