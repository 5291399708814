import * as UserUpdateActions from '../actions/update.actions';

const initialState = {
	id: '',
	data: {
		lastName: '',
		firstName: '',
		email: '',
		userName: '',
		status: '0',
		roleIds: [],
		pharmacyIds: [],
		originalUserName: '',
		originalEmail: '',
		avatarId: ''
	},
	loading: false,
	submitting: false,
	error: null,
	message: ''
};

const update = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UserUpdateActions.GET_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UserUpdateActions.GET_USER_SUCCESS:
      return {
        ...state,
        id: payload.data.id,
        loading: false,
        data: payload.data,
      };
    case UserUpdateActions.GET_USER_FAIL:
      return {
        ...state,
        loading: false,
        message: payload.message,
        error: payload.error,
      };
    case UserUpdateActions.CHANGE_USER_UPDATE_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
      };
    case UserUpdateActions.UPDATE_USER:
      return {
        ...state,
        submitting: true,
        error: null,
      };
    case UserUpdateActions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case UserUpdateActions.UPDATE_USER_FAIL:
      return {
        ...state,
        submitting: false,
        error: payload.error,
        message: payload.message,
      };
    case UserUpdateActions.CLEAR_UPDATE_FORM:
      return initialState;
    case UserUpdateActions.CHANGE_PASSWORD:
      return {
        ...state,
        submitting: true,
        error: null,
      };
    case UserUpdateActions.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        submitting: false,
        error: payload.error,
        message: payload.message,
      };
    case UserUpdateActions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case UserUpdateActions.RESEND_PASSWORD_EMAIL:
      return {
        ...state,
        submitting: true,
        error: null,
        message: '',
      };
    case UserUpdateActions.RESEND_PASSWORD_EMAIL_FAIL:
      return {
        ...state,
        submitting: false,
        error: payload.error,
        message: payload.message,
      };
    case UserUpdateActions.RESEND_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    default: {
      return state;
    }
  }
};

export default update;
