import * as UpdateAllergiesActions from '../actions/update.allergies.actions';
import * as UpdateBaseActions from '../actions/update.base.actions';

const initialState = {
	selectedAllergy: {
		drugCode: '',
		allergyId: '',
		reaction: ''
	},
	submitting: false,
	error: null
};

const updateAllergies = (state = initialState, action) => {
	switch (action.type) {
		case UpdateAllergiesActions.ADD_ALLERGY:
			return {
				...state,
				submitting: true,
				error: null
			};
		case UpdateAllergiesActions.DELETE_ALLERGY:
			return {
				...state,
				submitting: true,
				error: null
			};
		case UpdateAllergiesActions.ADD_ALLERGY_FAIL:
			return {
				...state,
				submitting: false,
				error: action.payload.message
			};
		case UpdateAllergiesActions.DELETE_ALLERGY_FAIL:
			return {
				...state,
				submitting: false,
				error: action.payload.message
			};
		case UpdateAllergiesActions.ADD_ALLERGY_SUCCESS:
			return {
				...state,
				submitting: false
			};
		case UpdateAllergiesActions.DELETE_ALLERGY_SUCCESS:
			return {
				...state,
				submitting: false
			};
		case UpdateAllergiesActions.UPDATE_ALLERGY:
			return {
				...state,
				submitting: true,
				error: null
			};
		case UpdateAllergiesActions.UPDATE_ALLERGY_SUCCESS:
			return {
				...state,
				submitting: false
			};
		case UpdateAllergiesActions.UPDATE_ALLERGY_FAIL:
			return {
				...state,
				submitting: false,
				error: action.payload.message
			};
		case UpdateAllergiesActions.SET_SELECTED_ALLERGY:
			return {
				...state,
				selectedAllergy: action.payload.allergy
			};
		case UpdateBaseActions.CLEAR_PATIENT_DATA:
			return initialState;
		default:
			return state;
	}
};

export default updateAllergies;
