import * as UpdatePreferredBrandsActions from '../actions/update.preferredBrands.actions';

const initialState = {
	list: [],
	submitting: false
};

const preferredBrand = (state = initialState, action) => {
	switch (action.type) {
		case UpdatePreferredBrandsActions.GET_PREFERRED_BRANDS:
			return {
				...state,
				list: action.payload.data
			};
		case UpdatePreferredBrandsActions.ADD_PREFERRED_BRANDS:
			return {
				...state,
				list: [...state.list, action.payload.drugId]
			};
		case UpdatePreferredBrandsActions.REMOVE_PREFERRED_BRANDS:
			return {
				...state,
				list: state.list.filter(item => item !== action.payload.drugId)
			};
		case UpdatePreferredBrandsActions.UPDATE_PREFERRED_BRANDS:
			return {
				...state,
				submitting: true
			};
		case UpdatePreferredBrandsActions.UPDATE_PREFERRED_BRANDS_SUCCESS:
			return {
				...state,
				submitting: false
			};
		case UpdatePreferredBrandsActions.UPDATE_PREFERRED_BRANDS_FAIL:
			return {
				...state,
				submitting: false
			};
		default:
			return state;
	}
};

export default preferredBrand;
