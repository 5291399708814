export const DATALOADER_FETCH_START = '@DATALOADER/FETCH_START';
export const DATALOADER_FETCH_END = '@DATALOADER/FETCH_END';
export const DATALOADER_FETCH_SUCCESS = '@DATALOADER/FETCH_SUCCESS';
export const DATALOADER_FETCH_ERROR = '@DATALOADER/FETCH_ERROR';
export const DATALOADER_REGISTER = '@DATALOADER/REGISTER';
export const DATALOADER_SET_OPTIONS = '@DATALOADER/SET_OPTIONS';
export const DATALOADER_RELOAD = '@DATALOADER/RELOAD';
export const DATALOADER_REMOVE = '@DATALOADER/REMOVE';

export function dataLoader_fetchStart(id) {
	return {
		type: DATALOADER_FETCH_START,
		payload: { id }
	};
}

export function dataLoader_fetchEnd(id) {
	return {
		type: DATALOADER_FETCH_END,
		payload: { id }
	};
}

export function dataLoader_fetchSuccess(id) {
	return {
		type: DATALOADER_FETCH_SUCCESS,
		payload: { id }
	};
}

export function dataLoader_fetchError(id, data) {
	return {
		type: DATALOADER_FETCH_ERROR,
		payload: { id, data }
	};
}

export function dataLoader_register(id, options) {
	return {
		type: DATALOADER_REGISTER,
		payload: { id, options }
	};
}

export function dataLoader_remove(id) {
	return {
		type: DATALOADER_REMOVE,
		payload: { id }
	};
}

export function dataLoader_setOptions(id, options) {
	return {
		type: DATALOADER_SET_OPTIONS,
		payload: { id, options }
	};
}

export function dataLoader_reload(id) {
	return {
		type: DATALOADER_RELOAD,
		payload: { id }
	};
}
