import { primary, secondary, red } from '@fuse/colors';
import _ from '@lodash';
import { createMuiTheme } from '@material-ui/core/styles';
import qs from 'qs';

/**
 * SETTINGS DEFAULTS
 */
export const defaultSettings = {
	customScrollbars: false,
	animations: true,
	direction: 'ltr',
	theme: {
		main: 'default',
		navbar: 'mainThemeDark',
		toolbar: 'mainThemeLight',
		footer: 'mainThemeDark'
	}
};

export function getParsedQuerySettings() {
	const parsedQueryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });

	if (parsedQueryString && parsedQueryString.defaultSettings) {
		return JSON.parse(parsedQueryString.defaultSettings);
	}
	return {};

	// Generating route params from settings
	/* const settings = qs.stringify({
        defaultSettings: JSON.stringify(defaultSettings, {strictNullHandling: true})
    });
    console.info(settings); */
}

/**
 * THEME DEFAULTS
 */

const inputPadding = 12;
const tabMinHeight = 30;
const defaultFontSize = '1.3rem';
const defaultLetterSpacing = 0.3;
const spacing = (multiply = 1) => 8 * multiply;

export const defaultThemeOptions = {
	typography: {
		fontFamily: ['Montserrat', 'NHaasGroteskDSPro', 'Calibri', 'Roboto'].join(','),
		letterSpacing: 0.5,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 600,
		useNextVariants: true,
		suppressDeprecationWarnings: true
	},
	overrides: {
		MuiButton: {
			root: {
				boxShadow: 'none !important',
				textTransform: 'none',
				minWidth: 100,
				lineHeight: 1.25,
				color: '#414141',
				letterSpacing: defaultLetterSpacing,
				fontSize: defaultFontSize
			},
			// Name of the styleSheet
			// containedSizeLarge: {
			// 	// Name of the rule
			// 	padding: '15px 28px'
			// },
			contained: {
				backgroundColor: '#ffffff',
				'&.Mui-disabled': {
					backgroundColor: '#ebebeb',
					'&:hover': {
						cursor: 'no-drop'
					}
				},
				'&:hover': {
					'&.Mui-disabled': { backgroundColor: '#ebebeb' }
				}
			},
			iconSizeMedium: {
				'& > .svg-inline--fa:first-child': {
					fontSize: 'inherit'
				}
			}
		},
		MuiInputLabel: {
			outlined: {
				transform: `translate(${inputPadding}px, ${inputPadding + 2}px) scale(1)`
			}
		},
		MuiIcon: {
			root: {
				fontSize: '1.8rem'
			}
		},
		MuiOutlinedInput: {
			input: {
				padding: `${inputPadding}px`
			}
		},
		MuiInputBase: {
			root: {
				backgroundColor: '#ffffff',
				'&.Mui-disabled': {
					'& input:hover, & textarea:hover': {
						cursor: 'no-drop'
					}
				},
				'&.MuiOutlinedInput-root': {
					'&.Mui-disabled': {
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: '#eaeaea'
						}
					}
					// '& .MuiInputAdornment-root': {
					// 	'&.MuiInputAdornment-positionEnd': {
					// 		'& MuiIcon-root': {
					// 			fontSize: '1.8rem'
					// 		}
					// 	}
					// }
				}
			}
		},
		// MuiTextField: {
		// 	root: {
		// 		'&[readOnly]': {
		// 			'& fieldset': {
		// 				display: 'none'
		// 			}
		// 		}
		// 	}
		// },
		MuiFormLabel: {
			root: {
				'&.Mui-disabled': {
					color: '#e0e0e0'
				}
			}
		},
		MuiFormControlLabel: {
			root: {
				'&.Mui-disabled:hover': {
					cursor: 'no-drop'
				}
			}
		},
		MuiTableRow: {
			root: {
				'&.Mui-selected': {
					backgroundColor: primary['400']
				}
			}
		},
		MuiTableCell: {
			root: {
				padding: '8px'
			}
		},
		MuiPaper: {
			root: {
				boxShadow: 'none !important',
				'&.MuiMenu-paper': {
					border: 'solid 1px #ccc'
				}
			}
		},
		MuiPopover: {
			paper: {
				border: 'solid 1px #ccc'
			}
		},
		MuiTab: {
			root: {
				letterSpacing: defaultLetterSpacing,
				textTransform: 'none',
				lineHeight: 1,
				padding: `${spacing(0.9)}px ${spacing(1.5)}px`,
				minHeight: tabMinHeight,
				borderWidth: 1,
				borderColor: '#cccccc',
				borderStyle: 'solid',
				fontSize: defaultFontSize,
				'&:hover': {
					backgroundColor: '#ececec'
				},
				'&:first-child': {
					borderTopLeftRadius: 3,
					borderBottomLeftRadius: 3
				},
				'&:last-child': {
					borderTopRightRadius: 3,
					borderBottomRightRadius: 3
				},
				'&.Mui-selected': {
					backgroundColor: primary[600],
					'& .MuiTab-wrapper': {
						color: primary.contrastText
					}
				}
			}
			// wrapper: {
			// 	position: 'relative',
			// 	top: 1
			// }
		},
		MuiTabs: {
			root: {
				minHeight: tabMinHeight
			},
			indicator: {
				display: 'none'
			}
		},
		MuiDialogTitle: {
			root: {
				padding: `${spacing(1.5)}px ${spacing(6)}px`,
				borderBottom: 'solid 1px #d7d7d7',
				'& > *': {
					fontSize: `1.6rem`
				}
			}
		},
		MuiDialogContent: {
			root: {
				padding: `${spacing(3)}px ${spacing(6)}px`
			}
		},
		MuiDialogActions: {
			root: {
				backgroundColor: '#f4f4f4',
				padding: `${spacing(1.5)}px ${spacing(6)}px`
			}
		},
		MuiIconButton: {
			root: {
				fontSize: '1.8rem',
				padding: 8
			}
		},
		MuiCheckbox: {
			root: {
				padding: 5,
				marginLeft: 4
			}
		},
		MuiRadio: {
			root: {
				padding: 5,
				marginLeft: 4
			}
		}
	}

	// shadows: ['none']
};

export const mustHaveThemeOptions = {
	typography: {
		htmlFontSize: 10,
		body1: {
			fontSize: '1.4rem'
		},
		body2: {
			fontSize: '1.4rem'
		}
	}
};

export const defaultThemes = {
	default: {
		palette: {
			type: 'light',
			primary,
			secondary,
			error: red
		},
		status: {
			error: '#b73a31'
		}
	},
	defaultDark: {
		palette: {
			type: 'dark',
			primary,
			secondary,
			error: red
		},
		status: {
			error: '#b73a31'
		}
	}
};

export function extendThemeWithMixins(obj) {
	const theme = createMuiTheme(obj);
	return {
		border: (width = 1) => ({
			borderWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider
		}),
		borderLeft: (width = 1) => ({
			borderLeftWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider
		}),
		borderRight: (width = 1) => ({
			borderRightWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider
		}),
		borderTop: (width = 1) => ({
			borderTopWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider
		}),
		borderBottom: (width = 1) => ({
			borderBottomWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider
		})
	};
}

export function mainThemeVariations(theme) {
	return {
		mainThemeDark: createMuiTheme(
			_.merge({}, defaultThemeOptions, theme, {
				palette: {
					type: 'dark',
					background: {
						paper: '#1E2125',
						default: '#00222f'
					}
				},
				...mustHaveThemeOptions
			})
		),
		mainThemeLight: createMuiTheme(
			_.merge({}, defaultThemeOptions, theme, {
				palette: {
					type: 'light',
					background: {
						paper: '#FFFFFF',
						default: '#FFFFFF'
					}
				},
				...mustHaveThemeOptions
			})
		)
	};
}
