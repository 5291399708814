import * as ClaimActions from '../actions';

const initialState = {
  submitting: false,
  error: null,
  message: '',
};

const list = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case ClaimActions.EDIT_MEDICARE_NUMBER:
      return { ...state, submitting: true };
    case ClaimActions.EDIT_MEDICARE_NUMBER_SUCCESS:
      return { ...state, submitting: false };
    case ClaimActions.EDIT_MEDICARE_NUMBER_FAIL:
      return { ...state, submitting: false };
    default:
      return state;
  }
};

export default list;
