const locale = {
  TITLE: 'Role Management',
  HEADER_TITLE: 'Role Management',
  HEADER_SEARCH_TEXT_PLACEHOLDER: 'Search roles...',
  ROLE_NAME: 'Role',
  ACCESS_CONTROL: 'Access Control',
  ACTIONS: 'Actions',
  CERATE_DATE: 'Date Created',
  LAST_UPDATE_DATE: 'Last Updated',
  USER_COUNT: 'User Count',
  ARCHIVE_ROLE_CONFIRM_CONTENT: 'Are you sure you want to delete {{name}} role?',
  ARCHIVE_ROLE_CONFIRM_TITLE: 'Delete Role',
  ARCHIVE_ROLE_SUCCESS: 'Role has been deleted successfully',
  CLONE_ROLE_SUCCESS: 'Clone role successfully',
  HEADER_BUTTON_NEW_FULL: 'Add Role',
  HEADER_BUTTON_NEW_SHORT: 'New',
  NAME: 'Name',
  ACCESSIBLE: 'Accessible',
  CREATE: 'Create',
  UPDATE: 'Update',
  DELETE: 'Delete',
  ADD_ROLE_SUCCESS: 'Role has been added successfully',
  UPDATE_ROLE_SUCCESS: 'Role has been updated successfully',
  PERMISSION: 'Permission',
  NONE: 'None',
  MODAL_CLONE_ROLE_TITLE: 'Clone Role',
  BUTTON_CLONE_ROLE: 'Clone',
  BUTTON_USER: 'View Users',
  CHANGE_COMMENT_REQUIRED: 'Comments for Change of Medication Required',
  ROLE_USER_ASSIGNED: 'Users assigned to the Role',
};

export default locale;
