const locale = {
  FILTER_SCRIPT_FORECASE: 'Script Forecast',
  BUTTON_PRINT_REQUEST: 'Print Request',
  BUTTON_APPLY: 'Apply',
  TABLE_HEADER_FIRST_NAME: 'First Name',
  TABLE_HEADER_LAST_NAME: 'Last Name',
  TABLE_HEADER_MEDICATION: 'Medication',
  TABLE_HEADER_DIRECTION: 'Direction',
  TABLE_HEADER_CURRENT_BALANCE: 'Current Balance',
  TABLE_HEADER_REPEAT_REMAINING: 'Repeats Remaining',
  TABLE_HEADER_PATIENT_GROUP: 'Patient Group',
  TABLE_HEADER_DOCTOR: 'Doctor',
  TABLE_HEADER_LAST_DISPENSE_IMPORT: 'Last Dispense Import',
};

export default locale;
