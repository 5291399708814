import * as QscriptAction from '../actions';

const initialState = {
  filter: {
    patientGroupIds: [],
    patientIds: [],
    qScriptFilters: [],
    packCycle: 1,
    packStartDate: new Date().toDateString(),
  },
  sort: {
    sortField: 'LastName',
    sortOrder: 1,
  },
  favoriteItems: [],
  isPrinting: false,
  loading: false,
  fileName: null,
  fileUrl: null,
  isDisplay: false,
  insufficientPillBalances: [],
  allPillBalances: [],
};

const list = (state = initialState, action) => {
  switch (action.type) {
    case QscriptAction.GET_FAVORITES:
      return { ...state, loading: true };
    case QscriptAction.GET_FAVORITES_SUCCESS:
      return { ...state, favoriteItems: action.payload, loading: false };
    case QscriptAction.UPDATE_FAVORITE:
      return { ...state, loading: true };
    case QscriptAction.UPDATE_FAVORITE_SUCCESS:
      return { ...state, loading: false };
    case QscriptAction.CHANGE_FILTER:
      return { ...state, filter: { ...state.filter, ...action.payload } };
    case QscriptAction.CHANGE_SORT:
      return { ...state, sort: { ...state.sort, ...action.payload } };
    case QscriptAction.PRINT_REPORT:
      return { ...state, isPrinting: true };
    case QscriptAction.PRINT_REPORT_SUCCESS:
      return {
        ...state,
        isPrinting: false,
        fileName: action.payload?.fileName,
        fileUrl: action.payload?.fileUrl,
      };
    case QscriptAction.DISPLAY_ALL_SCRIPT:
      return { ...state, isDisplay: true };
    case QscriptAction.HIDE_UNSELECTED_SCRIPT:
      return { ...state, isDisplay: false };
    case QscriptAction.CLEAR_HISTORY_FILE:
      return { ...state, fileName: null, fileUrl: null };
    case QscriptAction.CLEAR_HISTORY_DETAIL:
      return initialState;
    case QscriptAction.GET_INSUFFICIENT_PILL_BALANCE:
      return { ...state, loading: true };
    case QscriptAction.GET_INSUFFICIENT_PILL_BALANCE_SUCCESS:
      return {
        ...state,
        insufficientPillBalances: action.payload.insufficientPillBalances,
        allPillBalances: action.payload.allPillBalances,
        loading: false,
      };
    default: {
      return state;
    }
  }
};

export default list;
