import * as DashboardActions from '../actions';

const initState = {
	data: [],
	loading: false,
	message: null
};

const homeReducer = (state = initState, actions) => {
	const { type, payload } = actions;

	switch (type) {
		// DASHBOARD_GET_LIST
		case DashboardActions.DASHBOARD_GET_LIST:
			return { ...state, loading: true, message: null };
		case DashboardActions.DASHBOARD_GET_LIST_SUCCESS:
			return { ...state, loading: false, data: payload };
		case DashboardActions.DASHBOARD_GET_LIST_FAIL:
			return { ...state, loading: false, message: payload.message };
		default:
			return state;
	}
};

export default homeReducer;
