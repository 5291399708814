import React from 'react';
import i18next from 'i18next';
import en from '../i18n/en';

i18next.addResourceBundle('en', 'qScript', en);

const QScriptListConfig = {
	settings: {
		layout: {
			config: {}
		},
		nav: {
			title: 'QScript'
		}
	},
	auth: null,
	key: null,
	permission: null,
	routes: [
		{
			path: '/q-script/list',
			component: React.lazy(() => import('./QScriptList'))
		}
	]
};

export default QScriptListConfig;
