import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import * as authActions from 'app/auth/store/actions';

import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { hasIn, head } from 'lodash';

import clsx from 'clsx';
import { AuthService } from 'app/services';
import { makeStyles } from '@material-ui/core';
import Config from '../../Config';

const PARENT_WIDTH = 202;
const WIDTH = 250;

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  userMenu: {
    position: 'absolute',
    visibility: 'visible',
    background: '#ffffff',
    cursor: 'default',
    border: 'solid 1px #ccc',
    left: '20%',
    width: PARENT_WIDTH,
  },
  settingsMenu: {
    position: 'absolute',
    background: '#ffffff',
    cursor: 'default',
    top: '0px',
    border: 'solid 1px #ccc',
    left: -PARENT_WIDTH,
    visibility: 'hidden',
  },
  showSettingMenu: {
    visibility: 'visible',
  },
}));

function UserMenu() {
  const isTouchScreen = hasIn(window, 'ontouchend');
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const { isSuperAdmin } = useSelector((state) => state.auth.user.data);
  const classes = useStyles();

  const parentRef = useRef(null);
  const subMenuRef = useRef(null);
  const inMagicTriangle = useRef(false);
  const pointA = useRef(null);
  const pointB = useRef(null);
  const pointC = useRef(null);
  const previousX = useRef(null);

  const [userMenu, setUserMenu] = useState(null);
  const [settingsMenu, setSettingsMenu] = useState(null);

  const area = (A, B, C) => {
    return Math.abs((A.x * (B.y - C.y) + B.x * (C.y - A.y) + C.x * (A.y - B.y)) / 2);
  };

  const pointInTriangle = (D, A, B, C) => {
    const ABD = area(A, B, D);
    const BDC = area(B, D, C);
    const CAD = area(C, A, D);
    const ABC = area(A, B, C);
    if (ABC === ABD + BDC + CAD) {
      return true;
    }
    return false;
  };

  const onMouseMove = (ev) => {
    if (!inMagicTriangle.current) {
      pointA.current = { x: ev.pageX, y: ev.pageY };
      pointB.current = {
        x: subMenuRef.current?.getBoundingClientRect().x,
        y: subMenuRef.current?.getBoundingClientRect().y,
      };
      pointC.current = {
        x: subMenuRef.current?.getBoundingClientRect().x,
        y: subMenuRef.current?.getBoundingClientRect().bottom,
      };
      inMagicTriangle.current = true;
    } else {
      const pointD = { x: ev.pageX, y: ev.pageY };
      if (ev.pageX < previousX.current || !pointInTriangle(pointD, pointA.current, pointB.current, pointC.current)) {
        inMagicTriangle.current = false;
      }
      previousX.current = ev.pageX;
    }
  };

  // }, []);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

	const userMenuClose = () => {
		setUserMenu(null);
	};

  const settingsMenuClick = (event) => {
    setSettingsMenu(event.currentTarget);
  };

  const settingsMenuClose = () => {
    setSettingsMenu(null);
  };

  const closeAllMenu = () => {
    userMenuClose();
    settingsMenuClose();
  };

  const logout = () => {
    AuthService.clearToken();
    dispatch(authActions.logoutUser());
    closeAllMenu();
  };

  const hideSubMenu = () => {
    if (userMenu) {
      settingsMenuClose();
      inMagicTriangle.current = false;
    }
  };

  useEffect(() => {
    if (parentRef) {
      hideSubMenu();
    }
  }, [parentRef]);

  useEffect(() => {
    const navigationItems = document.querySelectorAll('div.parent-item');

    navigationItems.forEach((n) => n.addEventListener('mouseenter', hideSubMenu));
    if (isTouchScreen) navigationItems.forEach((n) => n.addEventListener('touchend', hideSubMenu));

    return () => {
      navigationItems.forEach((n) => n.removeEventListener('mouseenter', hideSubMenu));
      if (isTouchScreen) navigationItems.forEach((n) => n.removeEventListener('touchend', hideSubMenu));
    };
  }, []);

  return (
    <>
      <div
        className={clsx(classes.container)}
        onMouseEnter={(e) => userMenuClick(e)}
        onMouseLeave={() => userMenuClose()}
      >
        <Button className="h-64" onClick={userMenuClick}>
          {user.data.photoURL ? (
            <Avatar
              className=""
              alt="user photo"
              src={`${Config.REACT_APP_API_DOMAIN}/files/editor/${user.data.photoURL}`}
            />
          ) : (
            <Avatar className="">
              {(user.data.firstName && user.data.lastName && `${user.data.firstName[0]}${user.data.lastName[0]}`) || ''}
            </Avatar>
          )}

          <div className="hidden md:flex flex-col mx-12 items-start">
            <Typography component="span" className="normal-case font-600 flex">
              {user.data.displayName}
            </Typography>
            <Typography className="text-11 capitalize" color="textSecondary">
              <b>{user.data.currentPharmacyName}</b> - {head(user.data.roles)}
            </Typography>
          </div>

          <Icon className="text-16 hidden sm:flex" variant="action">
            keyboard_arrow_down
          </Icon>
        </Button>

        {userMenu && (
          <div className={classes.userMenu}>
            <MenuItem
              component={Link}
              to={`/pharmacy/${user.data.currentPharmacyId}/profile`}
              onMouseEnter={() => settingsMenuClose()}
              className={clsx(isSuperAdmin ? 'hidden' : '')}
              role="button"
            >
              <ListItemIcon className="min-w-32">
                <Icon style={{ fontSize: '2.2rem' }}>account_circle</Icon>
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </MenuItem>
            <MenuItem component={Link} to="/apps/mail" onClick={userMenuClose} role="button" className="hidden">
              <ListItemIcon className="min-w-40">
                <Icon>mail</Icon>
              </ListItemIcon>
              <ListItemText primary="Inbox" />
            </MenuItem>
            <div
              className={clsx('relative', 'parent-item')}
              ref={parentRef}
              onMouseEnter={(e) => settingsMenuClick(e)}
              onMouseLeave={() => !inMagicTriangle.current && settingsMenuClose()}
              onMouseMove={(e) => onMouseMove(e)}
            >
              <MenuItem>
                <ListItemIcon className="min-w-32">
                  <Icon style={{ fontSize: '2.2rem' }}>settings</Icon>
                </ListItemIcon>
                <ListItemText primary="Settings" />
                <ListItemIcon className="min-w-40 justify-end">
                  <Icon>keyboard_arrow_right</Icon>
                </ListItemIcon>
              </MenuItem>
              <div
                className={clsx(classes.settingsMenu, userMenu && settingsMenu && classes.showSettingMenu)}
                ref={subMenuRef}
              >
                <MenuItem component={Link} to="/pharmacy/redirect" onClick={closeAllMenu} role="button">
                  <ListItemText primary="Pharmacy Setting" />
                </MenuItem>
                <MenuItem component={Link} to="/dashboard/permissions/list" onClick={closeAllMenu} role="button">
                  <ListItemText primary="Dashboard Permissions" />
                </MenuItem>
                <MenuItem component={Link} to="/print-profile/list" onClick={closeAllMenu} role="button">
                  <ListItemText primary="Print Options" />
                </MenuItem>
              </div>
            </div>
            <MenuItem onClick={logout} onMouseEnter={() => settingsMenuClose()}>
              <ListItemIcon className="min-w-32">
                <Icon style={{ fontSize: '2.2rem' }}>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </div>
        )}
      </div>
    </>
  );
}

export default UserMenu;
