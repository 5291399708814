import * as USerCreateActions from '../actions/create.actions';

const initialState = {
	id: '',
	data: {
		lastName: '',
		firstName: '',
		email: '',
		dob: '',
		gender: '',
		userName: '',
		roleIds: [],
		avatarId: '',
		status: '1'
	},
	submitting: false,
	error: null,
	message: ''
};

const create = (state = initialState, action) => {
	switch (action.type) {
		case USerCreateActions.CREATE_USER: {
			return {
				...state,
				error: null,
				message: '',
				submitting: true
			};
		}
		case USerCreateActions.CREATE_USER_SUCCESS: {
			return {
				...state,
				id: action.payload.userId,
				submitting: false
			};
		}
		case USerCreateActions.CREATE_USER_FAIL: {
			return {
				...state,
				message: action.payload.message,
				error: action.payload.error,
				submitting: false
			};
		}
		case USerCreateActions.CHANGE_USER_FORM:
			return {
				...state,
				data: {
					...state.data,
					...action.payload.data
				}
			};
		case USerCreateActions.CLEAR_CREATE_FORM:
			return initialState;
		default: {
			return state;
		}
	}
};

export default create;
