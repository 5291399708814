import * as PharmacyMigrateActions from '../actions/migrate.actions';

const initState = {
	loading: false,
	submit: false,
	message: '',
	data: []
};

const migrate = (state = initState, action) => {
	switch (action.type) {
		case PharmacyMigrateActions.GET_MIGRATE:
			return { ...state, loading: true };
		case PharmacyMigrateActions.GET_MIGRATE_SUCCESS:
			return { ...state, loading: false, data: action.payload.data };
		case PharmacyMigrateActions.GET_MIGRATE_FAIL:
			return { ...state, message: action.payload.message };
		case PharmacyMigrateActions.MIGRATE_PATIENT:
			return { ...state, submit: true };
		case PharmacyMigrateActions.MIGRATE_PATIENT_SUCCESS:
			return { ...state, submit: false };
		default:
			return state;
	}
};

export default migrate;
