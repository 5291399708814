// http://mcg.mbitson.com/
const palette = {
	50: '#f6e7e6',
	100: '#e9c4c1',
	200: '#db9d98',
	300: '#cd756f',
	400: '#c25850',
	500: '#b73a31',
	600: '#b0342c',
	700: '#a72c25',
	800: '#9f251f',
	900: '#901813',
	A100: '#ffc5c3',
	A200: '#ff9490',
	A400: '#ff635d',
	A700: '#ff4a44',
	contrastText: '#ffffff'
};

export default palette;
