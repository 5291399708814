export const MENU = [
  {
    key: 'patient',
    label: 'Patient Management',
  },
  {
    key: 'isChangeCommentRequired',
    label: 'Comments for Change of Medication Required',
  },
  {
    key: 'brandSwap',
    label: 'Brand Swap',
  },
  {
    key: 'printFoil',
    label: 'Pack Creator',
  },
  {
    key: 'job',
    label: 'Pack Management',
  },
  {
    key: 'vpb',
    label: 'Virtual Pill Balance',
  },
  {
    key: 'dispenseTracker',
    label: 'Dispense Tracker',
  },
  {
    key: 'report',
    label: 'Reporting',
  },
  {
    key: 'claim',
    label: 'PPA Claim Reports',
  },
  {
    key: 'signing-sheets',
    label: 'Signing Sheet',
  },
  {
    key: 'patientGroup',
    label: 'Patient Group',
  },
  {
    key: 'drug',
    label: 'Drugs',
  },
  {
    key: 'doctor',
    label: 'Doctors',
  },
  {
    key: 'direction',
    label: 'Directions',
  },
  {
    key: 'printProfile',
    label: 'Print Options',
  },
  {
    key: 'user',
    label: 'User',
  },
  {
    key: 'role',
    label: 'Roles',
  },
  {
    key: 'pharmacy',
    label: 'Pharmacy',
  },
  // { key: 'sync', label: 'Sync' },
];

export const MENU_ENUM = {
  HOME: 'home',
  PATIENT: 'patient',
  DRUG: 'drug',
  PATIENT_GROUP: 'patientGroup',
  DOCTOR: 'doctor',
  DIRECTION: 'direction',
  PRINT_FOIL: 'printFoil',
  REPORT: 'report',
  PRINT_PROFILE: 'printProfile',
  ROLE: 'role',
  USER: 'user',
  BRAND_SWAP: 'brandSwap',
  CLAIM: 'Claim',
  PHARMACY: 'pharmacy',
  JOB: 'job',
  VPB: 'vpb',
  DISPENSE_TRACKER: 'dispenseTracker',
  // SYNC: 'sync',
  FEEDBACK: 'Feed Back',
};

export const ADMINISTRATOR_ID = 'pharmacy_administrator';
