import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
	button: {
		// '& > span': { position: 'relative', top: 1 },
		'& > span': { paddingTop: 1 },
		// '& > span > span': { position: 'relative', top: -1 },
		'&.small': {
			padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`,
			'& > span > span > svg': { fontSize: 11 }
		},
		'&.medium': {
			padding: theme.spacing(1.35),
			'& > span > span > svg, & .svg-inline--fa:first-child': { fontSize: 15 }
		},
		'td &.medium': {
			padding: theme.spacing(0.5)
		},
		'&.large': {
			padding: theme.spacing(2)
		}
	},
	disabled: {
		'&.Mui-disabled': {
			cursor: 'not-allowed',
			pointerEvents: 'unset'
		}
	}
}));

const MyButton = React.forwardRef((props, ref) => {
	const { themes } = useSelector(({ fuse }) => fuse.settings);
	const { progress = false, children, style, theme, className, ...rest } = props;
	const [myStyle] = useState({});
	const classes = useStyles();
	const btnTheme = themes[theme] || themes.default;

	const buildButton = () => (
		<Button
			style={{ ...myStyle, ...style }}
			{...rest}
			ref={ref}
			classes={{ disabled: classes.disabled, ...rest.classes }}
			className={clsx(className, classes.button, rest.size)}
		>
			{progress && <CircularProgress size={18} />}
			{!progress && children}
		</Button>
	);

	return <ThemeProvider theme={btnTheme}>{buildButton()}</ThemeProvider>;
});

MyButton.defaultProps = {
	size: 'small'
};

export default MyButton;
