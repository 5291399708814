import * as UpdateApnActions from '../actions/update.drugApn.actions';

const initialState = {
	selectedApn: {
		drugApn: '',
		drugApnId: ''
	},
	submitting: false,
	error: null
};

const drugApn = (state = initialState, action) => {
	switch (action.type) {
		case UpdateApnActions.SET_SELECTED_APN:
			return {
				...state,
				selectedApn: {
					...state.selectedApn,
					...action.payload.data
				}
			};
		case UpdateApnActions.CREATE_DRUG_APN:
			return {
				...state,
				submitting: true
			};
		case UpdateApnActions.CREATE_DRUG_APN_SUCCESS:
			return {
				...state,
				submitting: false
			};
		case UpdateApnActions.CREATE_DRUG_APN_FAIL:
			return {
				...state,
				submitting: false,
				error: action.payload.error
			};
		case UpdateApnActions.UPDATE_DRUG_APN:
			return {
				...state,
				submitting: true
			};
		case UpdateApnActions.UPDATE_DRUG_APN_SUCCESS:
			return {
				...state,
				submitting: false
			};
		case UpdateApnActions.UPDATE_DRUG_APN_FAIL:
			return {
				...state,
				submitting: false,
				error: action.payload.error
			};
		case UpdateApnActions.ARCHIVE_DRUG_APN:
			return {
				...state,
				submitting: true
			};
		case UpdateApnActions.ARCHIVE_DRUG_APN_SUCCESS:
			return {
				...state,
				submitting: false
			};
		case UpdateApnActions.ARCHIVE_DRUG_APN_FAIL:
			return {
				...state,
				submitting: false,
				error: action.payload.error
			};
		case UpdateApnActions.RESET_DRUG_APN:
			return {
				...state,
				selectedApn: initialState.selectedApn
			};
		default:
			return state;
	}
};

export default drugApn;
