import fs from 'fs';
import path from 'path';
import unzipper from 'unzipper';
import { get } from 'lodash';
import history from '@history';

import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';
import { JOB_STATUS } from '../../constants';

export const PRINT_JOB = '@JOB/PRINT_JOB';
export const PRINT_JOB_SUCCESS = '@JOB/PRINT_JOB_SUCCESS';
export const PRINT_JOB_FAIL = '@JOB/PRINT_JOB_FAIL';

export const DELETE_JOB = '@JOB/DELETE_JOB';
export const DELETE_JOB_SUCCESS = '@JOB/DELETE_JOB_SUCCESS';
export const DELETE_JOB_FAIL = '@JOB/DELETE_JOB_FAIL';

export const RESET_DATA = '@JOB/RESET_DATA';
export const RESET_FILE_DATA = '@JOB/RESET_FILE_DATA';

export const GENERATE_DATA = '@JOB/GENERATE_DATA';
export const GENERATE_DATA_SUCCESS = '@JOB/GENERATE_DATA_SUCCESS';
export const GENERATE_DATA_FAIL = '@JOB/GENERATE_DATA_FAIL';

export const PRINT_AND_PACK = '@JOB/PRINT_AND_PACK';
export const PRINT_AND_PACK_SUCCESS = '@JOB/PRINT_AND_PACK_SUCCESS';
export const PRINT_AND_PACK_FAIL = '@JOB/PRINT_AND_PACK_FAIL';

export const PRINT_LABEL = '@JOB/PRINT_LABEL';
export const PRINT_LABEL_SUCCESS = '@JOB/PRINT_LABEL_SUCCESS';
export const PRINT_LABEL_FAIL = '@JOB/PRINT_LABEL_FAIL';

export const GET_JOB_SUMMARY_DONE = '@JOB/GET_JOB_SUMMARY_DONE';

export const CHECK_IS_BARCODE = '@JOB/CHECK_IS_BARCODE';
export const GET_BARCODE_LIST = '@JOB/GET_BARCODE_LIST';

export const CLEAR_BARCODE = '@JOB/CLEAR_BARCODE';

export const QUICKKING_JOB_SUCCESS = '@JOB/QUICKKING_JOB_SUCCESS';

export const previewJob = (jobIds, clientCode, packType) => {
	return dispatch => {
		dispatch({ type: PRINT_JOB, payload: { clientCode, packType } });
		HttpService.post('/api/packjobs/pdf', { ids: jobIds })
			.then(resp => {
				dispatch({ type: PRINT_JOB_SUCCESS, payload: resp.data });
			})
			.catch(err => {
				const { errorFields, errorMessages } = parseError(err);
				dispatch({ type: PRINT_JOB_FAIL, payload: { message: errorMessages.join('\n'), error: errorFields } });
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
};

export const bulkPrintJob = (jobIds, isPacking = false, clientCode, packType) => {
	return dispatch => {
		dispatch({ type: PRINT_JOB, payload: { clientCode, packType } });
		HttpService.post('/api/packjobs/pdf', { ids: jobIds, isPacking })
			.then(resp => {
				dispatch({ type: PRINT_JOB_SUCCESS, payload: resp.data });
			})
			.catch(err => {
				const { errorFields, errorMessages } = parseError(err);
				dispatch({ type: PRINT_JOB_FAIL, payload: { message: errorMessages.join('\n'), error: errorFields } });
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
};

export const bulkRobotPackJob = (jobIds, clientCode, packType) => {
  return (dispatch) => {
    dispatch({ type: PRINT_JOB, payload: { clientCode, packType } });
    HttpService.post('/api/packjobs/robotpack', { ids: jobIds })
      .then((resp) => {
        dispatch({ type: PRINT_AND_PACK_SUCCESS, payload: resp.data });
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({ type: PRINT_AND_PACK_FAIL, payload: { message: errorMessages.join('\n'), error: errorFields } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const resetData = () => {
	return dispatch => {
		dispatch({ type: RESET_DATA });
	};
};

export const resetFileData = () => {
	return dispatch => {
		dispatch({ type: RESET_FILE_DATA });
	};
};

export const deleteJob = (jobIds) => {
  return (dispatch) => {
    dispatch({ type: DELETE_JOB });
    HttpService.put('/api/packjobs', { ids: jobIds })
      .then(() => {
        dispatch({ type: DELETE_JOB_SUCCESS });
        dispatch(
          AppActions.showMessage({
            message: 'job:DELETE_JOB_MESSAGE_SUCCESS',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({ type: DELETE_JOB_FAIL, payload: { message: errorMessages.join('\n'), error: errorFields } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const reverseJob = (jobIds) => {
  return (dispatch) => {
    dispatch({ type: DELETE_JOB });
    HttpService.put('/api/packjobs/reverse', { ids: jobIds })
      .then(() => {
        dispatch({ type: DELETE_JOB_SUCCESS });
        dispatch(
          AppActions.showMessage({
            message: 'job:DELETE_JOB_MESSAGE_REVERSE',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({ type: DELETE_JOB_FAIL, payload: { message: errorMessages.join('\n'), error: errorFields } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const generateDataFile = (jobIds, currentPharmacyId) => {
	return dispatch => {
		dispatch({ type: GENERATE_DATA });
		let savePath;
		HttpService.get(`api/pharmacies/${currentPharmacyId}/configuration`)
			.then(resp => {
				savePath = !resp.data.alpacaFileExportFolder
					? ''
					: path.join(resp.data.alpacaFileExportFolder, 'alpaca-exported.zip');
			})
			.finally(() => {
				if (!savePath) {
					savePath = window.electron.dialog.showSaveDialogSync(null, {
						filters: [{ extensions: ['zip'] }]
					});
					if (!savePath) {
						dispatch({
							type: GENERATE_DATA_SUCCESS
						});
						return;
					}
				}
				HttpService.downloadFile('/api/packjobs/alpaca-file', 'post', { JobIds: jobIds }).then(
					async response => {
						const buffer = Buffer.from(await response.data.arrayBuffer());
						fs.writeFile(savePath, buffer, async error => {
							if (error) {
								const { errorFields, errorMessages } = parseError(error);
								dispatch({
									type: GENERATE_DATA_FAIL,
									payload: { message: errorMessages.join('\n'), error: errorFields }
								});
								dispatch(
									AppActions.showMessage({
										message: errorMessages,
										autoHideDuration: 4000,
										anchorOrigin: {
											vertical: 'top',
											horizontal: 'right'
										},
										variant: 'error'
									})
								);
							} else {
								const zip = fs.createReadStream(savePath).pipe(unzipper.Parse({ forceStream: true }));
								// eslint-disable-next-line no-restricted-syntax
								for await (const entry of zip) {
									const fileName = entry.path;

									const dirPath = path.dirname(savePath);
									let filePath = path.join(dirPath, fileName);
									const ext = path.extname(fileName);
									const name = path.basename(fileName, ext);

									let i = 1;
									while (fs.existsSync(filePath)) {
										const newName = `${name} (${i})${ext}`;
										filePath = path.join(dirPath, newName);
										i += 1;
									}
									entry.pipe(fs.createWriteStream(filePath));
								}
								fs.unlinkSync(savePath);
								dispatch({ type: GENERATE_DATA_SUCCESS });
								dispatch(
									AppActions.showMessage({
										message: 'job:GENERATE_DATA_SUCCESS',
										autoHideDuration: 4000,
										anchorOrigin: {
											vertical: 'top',
											horizontal: 'right'
										},
										variant: 'success'
									})
								);
							}
						});
					},
					err => {
						const { errorFields, errorMessages } = parseError(err);
						dispatch({
							type: GENERATE_DATA_FAIL,
							payload: { message: errorMessages.join('\n'), error: errorFields }
						});
						dispatch(
							AppActions.showMessage({
								message: errorMessages,
								autoHideDuration: 4000,
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right'
								},
								variant: 'error'
							})
						);
					}
				);
			});
	};
};

export const printAndPack = (jobIds, clientCode, packType) => {
	return dispatch => {
		dispatch({ type: PRINT_AND_PACK, payload: { clientCode, packType } });
		HttpService.post('/api/packjobs/complete-printing', { ids: jobIds })
			.then(resp => {
				dispatch({ type: PRINT_AND_PACK_SUCCESS, payload: resp.data });
			})
			.catch(err => {
				const { errorFields, errorMessages } = parseError(err);
				dispatch({ type: PRINT_AND_PACK_FAIL, payload: { message: errorMessages.join('\n'), error: errorFields } });
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
};

export const printLabel = (jobIds, startPosiion) => {
	return dispatch => {
		dispatch({ type: PRINT_LABEL });
		HttpService.post('/api/packjobs/print-label', {
			jobIds,
			startPosiion
		})
			.then(resp => {
				dispatch({ type: PRINT_LABEL_SUCCESS, payload: resp.data });
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch({ type: PRINT_LABEL_FAIL, payload: { message: errorMessages.join('\n'), error: errorFields } });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

export const getJobSummary = filter => {
	return dispatch => {
		const _filter = {};
		if (get(filter, 'createdDateFrom')) _filter.createdDateFrom = get(filter, 'createdDateFrom');
		if (get(filter, 'createdDateTo')) _filter.createdDateTo = get(filter, 'createdDateTo');

		HttpService.post('/api/packjobs/summary', { pageIndex: 1, pageSize: 99999, ..._filter }).then(resp => {
			dispatch({ type: GET_JOB_SUMMARY_DONE, payload: resp.data });
		});
	};
};

export const getBarcodeList = (barcode, jobPhase) => {
  return (dispatch) => {
    if (barcode) {
      const code = barcode;
      dispatch({ type: CHECK_IS_BARCODE, payload: barcode });
      if (jobPhase === JOB_STATUS.CHECKING) {
        HttpService.get(`api/packjobs/barcode/${code}/checking`)
          .then((resp) => {
            dispatch({ type: GET_BARCODE_LIST, payload: resp?.data });
          })
          .catch((error) => {
            const errorMessages = error.response?.data;
            dispatch(
              AppActions.createDialogMessage(
                'error',
                error.response?.status === 400 ? 'Warning' : 'Error',
                errorMessages,
              ),
            );
          });
      } else {
        let url = `api/packjobs/barcode/${code}`;
        if (jobPhase === JOB_STATUS.PACKING) {
          url = `${url}?isPacking=true`;
        }
        HttpService.get(url)
          .then((resp) => {
            if (jobPhase === JOB_STATUS.PACKING) {
              const jobs = resp?.data ?? { packJobs: [] };
              const correctJob = jobs.packJobs.find(
                (s) => s.status.toString() === JOB_STATUS.CORRECT && s.barcode === barcode,
              );
              if (correctJob != null) {
                history.push(`/job/correct/${correctJob.jobId}?tab=${JOB_STATUS.PACKING}`);
              } else {
                dispatch({ type: GET_BARCODE_LIST, payload: resp?.data });
              }
            } else {
              dispatch({ type: GET_BARCODE_LIST, payload: resp?.data });
            }
          })
          .catch((error) => {
            const errorMessages = error.response?.data;
            dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
          });
      }
    }
  };
};

export const clearBarcode = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_BARCODE });
  };
};
