// http://mcg.mbitson.com/
const palette = {
	50: '#e0f4fc',
	100: '#b3e3f7',
	200: '#80d0f1',
	300: '#4dbdeb',
	400: '#26aee7',
	500: '#00a0e3',
	600: '#0098e0',
	700: '#008edc',
	800: '#0084d8',
	900: '#0073d0',
	A100: '#f8fcff',
	A200: '#c5e2ff',
	A400: '#92c8ff',
	A700: '#79bbff',
	contrastText: '#ffffff'
};

export default palette;
