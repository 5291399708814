import * as DirectionUpdateActions from '../actions/update.actions';

const initialState = {
	id: '',
	data: {
		code: '',
		instruction: '',
		description: ''
	},
	loading: false,
	submitting: false,
	error: null,
	message: ''
};

const update = (state = initialState, action) => {
	switch (action.type) {
		case DirectionUpdateActions.GET_DIRECTION:
			return {
				...state,
				loading: true
			};
		case DirectionUpdateActions.GET_DIRECTION_SUCCESS:
			return {
				...state,
				id: action.payload.data.directionId,
				loading: false,
				data: {
					code: action.payload.data.code,
					instruction: action.payload.data.instruction,
					description: action.payload.data.description
				}
			};
		case DirectionUpdateActions.GET_DIRECTION_FAIL:
			return {
				...state,
				loading: false,
				message: action.payload.message,
				error: action.payload.error
			};
		case DirectionUpdateActions.CHANGE_DIRECTION_UPDATE_FORM:
			return {
				...state,
				data: {
					...state.data,
					...action.payload.data
				}
			};
		case DirectionUpdateActions.UPDATE_DIRECTION:
			return {
				...state,
				submitting: true
			};
		case DirectionUpdateActions.UPDATE_DIRECTION_SUCCESS:
			return {
				...state,
				submitting: false
			};
		case DirectionUpdateActions.UPDATE_DIRECTION_FAIL:
			return {
				...state,
				submitting: false,
				error: action.payload.error,
				message: action.payload.message
			};
		case DirectionUpdateActions.CLEAR_UPDATE_FORM:
			return initialState;
		default: {
			return state;
		}
	}
};

export default update;
