import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';
import i18n from 'i18n';


export const START_DATA_MIGRATION = '@PHARMACY/DATA_UPLOAD_START_DATA_MIGRATION';
export const END_DATA_MIGRATION = '@PHARMACY/DATA_UPLOAD_END_DATA_MIGRATION';

export const START_DISPENSE_UPLOAD = '@PHARMACY/DATA_UPLOAD_START_DISPENSE_UPLOAD';
export const END_DISPENSE_UPLOAD = '@PHARMACY/DATA_UPLOAD_END_DISPENSE_UPLOAD';

export const START_REQUEST_SYNC = '@PHARMACY/START_REQUEST_SYNC';
export const END_REQUEST_SYNC = '@PHARMACY/END_REQUEST_SYNC';

export function startDataMigration(pharmacyId) {
  return (dispatch) => {
    dispatch({ type: START_DATA_MIGRATION });
    HttpService.put(`/api/pharmacies/${pharmacyId}/data-migrate`)
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'pharmacy:DATA_UPLOAD_DATA_MIGRATION_MESSAGE',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
        dispatch({ type: END_DATA_MIGRATION });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: END_DATA_MIGRATION });
        if (errorMessages == "MigrationProcessCompleted") {
          dispatch(
            AppActions.createDialogMessage(
              'info',
              'Info',
              {
                message: 'pharmacy:MIGRATION_PROCESS_COMPLETED',
                options: {
                  code: 'code',
                  medication: 'medication',
                },
              },
              {
                dialogId: 'SEARCH_APN_MESSAGE_SAME_GENERIC_TITLE',
                options: {
                  buttons: [
                    {
                      label: i18n.t('NO'),
                    },
                    {
                      label: i18n.t('YES'),
                      color: 'primary',
                      variant: 'contained',
                    },
                  ],
                },
                callback: (label) => {
                  if (label === i18n.t('YES')) {
                    HttpService.put(`/api/pharmacies/${pharmacyId}/data-migrate?forcedUpdate=true`)
                      .then(() => {
                        dispatch(
                          AppActions.showMessage({
                            message: 'Reset the pharmacy database success, please wait to process to reset the database complete',
                            autoHideDuration: 4000,
                            anchorOrigin: {
                              vertical: 'top',
                              horizontal: 'right',
                            },
                            variant: 'success',
                          }),
                        );
                      })
                  }
                  dispatch(AppActions.closeDialogMessage('SEARCH_APN_MESSAGE_SAME_GENERIC_TITLE'));
                },
              },
            ),
          );
        } else {
          dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
        }
      });
  };
}

export function startDispenseUpload(pharmacyId) {
	return dispatch => {
		dispatch({ type: START_DISPENSE_UPLOAD });
		HttpService.put(`/api/pharmacies/${pharmacyId}/upload-dispense`)
			.then(resp => {
				dispatch(
					AppActions.showMessage({
						message: resp?.message,
						autoHideDuration: 4000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right'
						},
						variant: 'success'
					})
				);
				dispatch({ type: END_DISPENSE_UPLOAD });
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch({ type: END_DISPENSE_UPLOAD });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
}

export function requestSyncData(pharmacyId) {
	return dispatch => {
		dispatch({ type: START_REQUEST_SYNC });
		HttpService.put(`/api/pharmacies/${pharmacyId}/sync`)
			.then(() => {
				dispatch({ type: END_REQUEST_SYNC });
				dispatch(
					AppActions.showMessage({
						message: 'pharmacy:REQUEST_SYNC_MESSAGE',
						autoHideDuration: 4000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right'
						},
						variant: 'success'
					})
				);
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch({ type: END_REQUEST_SYNC });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
}
