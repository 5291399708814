import * as PatientAction from '../actions/list.actions';
import { PACKING_STATUS, PACKING_STATUS_TYPES } from '../../constants';
import { map, includes, filter } from 'lodash';

const defaultFilter = {
  patientGroupIds: [],
  patientIds: [],
  packingStatus: [],
};

const initialState = {
  data: [],
  error: null,
  loading: false,
  total: 0,
  message: 'No data to display',
  filter: defaultFilter,
  summary: {
    total: 0,
    totalActive: 0,
    totalSuspended: 0,
    totalNewlyImported: 0,
    totalInactive: 0,
  },
  allPatient: [],
  isLoadedInitPackingStatus: false,
};

const list = (state = initialState, action) => {
  switch (action.type) {
    // IMPORT_DISPENSE_PATIENT
    case PatientAction.IMPORT_DISPENSE_PATIENT:
      return { ...state, loading: true };
    case PatientAction.IMPORT_DISPENSE_PATIENT_SUCCESS:
      return { ...state, loading: false };
    case PatientAction.IMPORT_DISPENSE_PATIENT_FAIL:
      return { ...state, loading: false, message: action.payload.message };
    // REMOVE_PATIENT
    case PatientAction.REMOVE_PATIENT:
      return { ...state, loading: true };
    case PatientAction.REMOVE_PATIENT_SUCCESS:
      return { ...state, loading: false };
    case PatientAction.REMOVE_PATIENT_FAIL:
      return { ...state, loading: false, error: action.payload };
    // SET_PATIENT_FILTER
    case PatientAction.SET_PATIENT_FILTER:
      return { ...state, filter: { ...state.filter, ...action.payload.filter } };
    // RESET_PATIENT_FILTER
    case PatientAction.RESET_PATIENT_FILTER:
      return {
        ...state,
        filter: {
          patientGroupIds: [],
          patientIds: [],
          packingStatus: state.filter.packingStatus,
        },
      };
    // SET_LIST_MESSAGE
    case PatientAction.SET_LIST_MESSAGE:
      return { ...state, message: action.payload.message };
    // GET_PATIENT_SUMMARY
    case PatientAction.GET_PATIENT_SUMMARY:
      return { ...state, summary: { ...state.summary, ...action.payload } };
    case PatientAction.GET_ALL_PATIENT:
      return { ...state, allPatient: action.payload.data, total: action.payload.total };
    case PatientAction.GET_PACKING_STATUS_OPTIONS:
      return {
        ...state,
        filter: {
          ...filter,
          packingStatus: map(
            filter(PACKING_STATUS, (o) => includes(action.payload.toString(), o.value)),
            (v) => v.value,
          ),
        },
        isLoadedInitPackingStatus: true,
      };
    default:
      return state;
  }
};

export default list;
