import * as DrugActions from '../actions';

const initialState = {
	data: [],
	loadingDrug: false,
	preferredDrugs: [],
	loadingPreferredDrug: false,
	message: '',
	detail: {}
};

const search = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case DrugActions.GET_DRUGS:
			return { ...state, loadingDrug: true };
		case DrugActions.GET_DRUGS_SUCCESS:
			return { ...state, loadingDrug: false, data: payload.data };
		case DrugActions.GET_DRUGS_FAIL:
			return { ...state, loadingDrug: false, message: payload.message };
		case DrugActions.GET_PREFERRED_DRUGS:
			return { ...state, loadingPreferredDrug: true };
		case DrugActions.GET_PREFERRED_DRUGS_SUCCESS:
			return { ...state, loadingPreferredDrug: false, preferredDrugs: payload.data };
		case DrugActions.GET_PREFERRED_DRUGS_FAIL:
			return { ...state, loadingPreferredDrug: false, message: payload.message };
		case DrugActions.GET_DRUG_DETAIL:
			return { ...state, gettingDrugDetail: true };
		case DrugActions.GET_DRUG_DETAIL_SUCCESS:
			return { ...state, gettingDrugDetail: false, detail: payload.data };
		case DrugActions.GET_DRUG_DETAIL_FAIL:
			return { ...state, gettingDrugDetail: false, message: payload.message };
		default:
			return state;
	}
};

export default search;
