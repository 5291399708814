import FuseUtils from '@fuse/utils';
import { AuthService } from 'app/services';
import AppContext from 'app/AppContext';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';

class FuseAuthorization extends Component {
	constructor(props, context) {
		super(props);
		const { routes, cacheRoutes } = context;
		this.state = {
			accessGranted: true,
			routes: [...cacheRoutes, ...routes]
		};
	}

	componentDidMount() {
		if (!this.state.accessGranted) {
			this.redirectRoute();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.accessGranted !== this.state.accessGranted;
	}

	componentDidUpdate() {
		if (!this.state.accessGranted) {
			this.redirectRoute();
		}
	}

	static getDerivedStateFromProps(props, state) {
		const { location, userRole, permission } = props;
		const { pathname } = location;

		const matched = matchRoutes(state.routes, pathname)[0];

		const hasPermission = FuseUtils.hasPermission(
			matched.route.auth,
			userRole,
			matched.route.key,
			matched.route.permission,
			permission
		);

		return {
			accessGranted: matched ? hasPermission : true
		};
	}

	redirectRoute() {
		const { location, userRole, history } = this.props;
		const { pathname, state } = location;
		const redirectUrl = state && state.redirectUrl ? state.redirectUrl : '/un-authorized';
		const isAuth = AuthService.isAuth();

		/*
        User is guest / unlogin
        Redirect to Login Page
        */
		if (!userRole || userRole.length === 0 || !isAuth) {
			history.push({
				pathname: '/auth/login',
				state: { redirectUrl: pathname }
			});
		} else {
			/*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or redirectUrl
        */
			history.push({
				pathname: redirectUrl
			});
		}
	}

	render() {
		// console.info('Fuse Authorization rendered', accessGranted);
		return this.state.accessGranted ? <>{this.props.children}</> : null;
	}
}

function mapStateToProps({ auth }) {
	return {
		userRole: auth.user.role,
		permission: auth.user.permission
	};
}

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(FuseAuthorization));
