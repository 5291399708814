import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import i18next from 'i18next';
import { MENU_ENUM } from 'app/main/role/constants';
import en from '../i18n/en';

i18next.addResourceBundle('en', 'report', en);

const AnalyticReportConfig = {
	settings: {
		layout: {
			config: {}
		},
		nav: {
			title: 'Summary Reports'
		}
	},
	auth: authRoles.user,
	key: MENU_ENUM.REPORT,
	permission: PERMISSION.READ,
	routes: [
		{
			path: '/analytic-report',
			component: React.lazy(() => import('./AnalyticReport'))
		}
	]
};

export default AnalyticReportConfig;
