/* eslint-disable no-else-return */
/* eslint-disable no-plusplus */
import React, { useRef, useEffect, useMemo } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { split, filter, map, head, hasIn } from 'lodash';
import { useLocation } from 'react-router';
import clsx from 'clsx';

import FuseNavItem from '@fuse/core/FuseNavigation/FuseNavItem';
import * as Actions from 'app/store/actions';
import FuseUtils from '@fuse/utils';
import { PERMISSION } from 'app/auth';

const PARENT_WIDTH = 245;
const WIDTH = 250;

const useStyles = makeStyles(theme => ({
	item: {
		height: 50,
		width: 'calc(100% - 16px)',
		borderRadius: '25px',
		padding: 15,
		marginLeft: 6,
		'&.active': {
			backgroundColor: theme.palette.primary.main,
			color: `${theme.palette.primary.contrastText}!important`,
			transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
			'& .list-item-text-primary': {
				color: 'inherit'
			},
			'& .list-item-icon': {
				color: 'inherit'
			}
		},
		'& .list-item-icon': {
			marginRight: 16,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start'
		},
		'& .list-item-text': {
			color: '#ffffff'
		},
		color: theme.palette.text.primary,
		cursor: 'pointer',
		textDecoration: 'none!important'
	},
	subMenu: {
		left: PARENT_WIDTH,
		position: 'fixed',
		visibility: 'hidden',
		padding: `${theme.spacing(1)}px 0`,
		width: 0,
		backgroundColor: theme.palette.background.default
		// transition: `${theme.transitions.create(['width'], {
		// 	easing: theme.transitions.easing.sharp,
		// 	duration: theme.transitions.duration.shorter
		// })}, ${theme.transitions.create(['visibility'], {
		// 	easing: theme.transitions.easing.sharp,
		// 	duration: theme.transitions.duration.shortest
		// })}`
	},
	showSubMenu: {
		visibility: 'visible',
		width: WIDTH
	}
}));

function Parent(props) {
	const isTouchScreen = hasIn(window, 'ontouchend');
	const classes = useStyles();
	const { item } = props;
	const { t } = useTranslation('navigation');
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const navbar = useSelector(({ fuse }) => fuse.navbar);
	const navbarRef = useRef(navbar);
	const { role, permission } = useSelector(({ auth }) => auth.user);
	const theme = useTheme();
	const parentRef = useRef(null);
	const subMenuRef = useRef(null);
	const inMagicTriangle = useRef(false);
	const pointA = useRef(null);
	const pointB = useRef(null);
	const pointC = useRef(null);
	const previousX = useRef(null);
	const childrenModules = useMemo(() =>
		map(item.children, c => head(filter(split(c.url, '/'), u => !!u)), [item.children])
	);
	const isActive = useMemo(() => {
		for (let i = 0; i < childrenModules.length; i++) {
			if (pathname.includes(childrenModules[i])) return true;
		}

		return false;
	}, [pathname, item.children]);

	// Reference: https://kipalog.com/posts/Implement-Tam-giac-ma-thuat-cua-Amazon
	const area = (A, B, C) => {
		return Math.abs((A.x * (B.y - C.y) + B.x * (C.y - A.y) + C.x * (A.y - B.y)) / 2);
	};

	const pointInTriangle = (D, A, B, C) => {
		const ABD = area(A, B, D);
		const BDC = area(B, D, C);
		const CAD = area(C, A, D);
		const ABC = area(A, B, C);
		if (ABC === ABD + BDC + CAD) {
			return true;
		}
		return false;
	};

	const onMouseMove = ev => {
		if (!inMagicTriangle.current) {
			pointA.current = { x: ev.pageX, y: ev.pageY };
			pointB.current = {
				x: subMenuRef.current?.getBoundingClientRect().x,
				y: subMenuRef.current?.getBoundingClientRect().y
			};
			pointC.current = {
				x: subMenuRef.current?.getBoundingClientRect().x,
				y: subMenuRef.current?.getBoundingClientRect().bottom
			};
			inMagicTriangle.current = true;
		} else {
			const pointD = { x: ev.pageX, y: ev.pageY };
			if (ev.pageX < previousX.current || !pointInTriangle(pointD, pointA.current, pointB.current, pointC.current)) {
				inMagicTriangle.current = false;
			}
			previousX.current = ev.pageX;
		}
	};

	const hideSubMenu = () => {
		if (item.id === navbarRef.current?.subNavOpen) {
			dispatch(Actions.navbarCloseSub());
			inMagicTriangle.current = false;
		}
	};

	useEffect(() => {
		if (!navbar.foldedOpen) {
			hideSubMenu();
		}
	}, [navbar.foldedOpen]);

	useEffect(() => {
		navbarRef.current = navbar;
	}, [navbar]);

	useEffect(() => {
		const navigationItems = document.querySelectorAll('ul.navigation > .list-item');

		navigationItems.forEach(n => n.addEventListener('mouseenter', hideSubMenu));
		if (isTouchScreen) navigationItems.forEach(n => n.addEventListener('touchend', hideSubMenu));

		return () => {
			navigationItems.forEach(n => n.removeEventListener('mouseenter', hideSubMenu));
			if (isTouchScreen) navigationItems.forEach(n => n.removeEventListener('touchend', hideSubMenu));
		};
	}, []);

	const subNavStyle = () => {
		const parentTop = parentRef.current?.getBoundingClientRect().y;
		const subHeight = subMenuRef.current?.getBoundingClientRect().height;
		if (parentTop + subHeight >= window.innerHeight) {
			return { top: window.innerHeight - subHeight || 0 };
		}

		return { top: parentTop - 4 || 0 };
	};

	return (
		<div
			className="relative"
			ref={parentRef}
			onMouseEnter={() => dispatch(Actions.navbarOpenSub(item.id))}
			onMouseLeave={() => !inMagicTriangle.current && dispatch(Actions.navbarCloseSub())}
			onMouseMove={onMouseMove}
			onTouchEnd={() => {
				if (!navbar.foldedOpen) {
					window.requestAnimationFrame(() => {
						dispatch(Actions.navbarOpenFolded());
						dispatch(Actions.navbarOpenSub(item.id));
					});
				} else {
					window.requestAnimationFrame(() => {
						dispatch(Actions.navbarCloseSub());
						dispatch(Actions.navbarCloseFolded());
					});
				}
			}}
		>
			<ListItem button className={clsx(classes.item, isActive && 'active', 'list-item')}>
				{item.icon && typeof item.icon !== 'string' && <div className="list-item-icon">{item.icon}</div>}

				<ListItemText
					className={clsx('list-item-text')}
					primary={item.translate ? t(item.translate) : item.title}
					classes={{ primary: 'text-15 list-item-text-primary' }}
				/>

				<ArrowRightIcon />
			</ListItem>
			{Array.isArray(item.children) && (
				<div
					id={`sub-nav-${item.id}`}
					ref={subMenuRef}
					className={clsx(classes.subMenu, navbar.foldedOpen && navbar.subNavOpen === item.id && classes.showSubMenu)}
					style={{
						...subNavStyle(),
						transitionDelay: navbar.subNavOpen === item.id ? `${theme.transitions.duration.shorter}ms` : '0ms'
					}}
				>
					{item.children.map(_item => {
						if (!FuseUtils.hasPermission(item.auth, role, item.id, PERMISSION.READ, permission)) {
							return undefined;
						}
						return <FuseNavItem key={_item.id} type={`vertical-${_item.type}`} item={_item} nestedLevel={0} />;
					})}
				</div>
			)}
		</div>
	);
}

export default Parent;
