import * as UpdateBaseActions from '../actions/update.base.actions';
import * as UpdateDetailsActions from '../actions/update.details.actions';

const initialState = {
	data: {
		imageId: '',
		drugName: ''
	}
};

const updateHeader = (state = initialState, action) => {
	switch (action.type) {
		case UpdateBaseActions.GET_DRUG_SUCCESS:
			return {
				...initialState,
				data: {
					imageId: action.payload.data.imageId,
					drugName: action.payload.data.drugName
				}
			};
		case UpdateDetailsActions.UPDATE_DRUG_DETAILS_SUCCESS:
			return {
				...initialState,
				data: {
					imageId: action.payload.data.imageId,
					drugName: action.payload.data.drugName
				}
			};
		case UpdateBaseActions.CLEAR_DRUG_DATA:
			return initialState;
		default:
			return state;
	}
};

export default updateHeader;
