import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const SUBMIT_PATIENT_DOCUMENT = '@PATIENT/SUBMIT_PATIENT_DOCUMENT';
export const SUBMIT_PATIENT_DOCUMENT_SUCCESS = '@PATIENT/SUBMIT_PATIENT_DOCUMENT_SUCCESS';
export const SUBMIT_PATIENT_DOCUMENT_FAIL = '@PATIENT/SUBMIT_PATIENT_DOCUMENT_FAIL';

export const DELETE_PATIENT_DOCUMENT = '@PATIENT/DELETE_PATIENT_DOCUMENT';
export const DELETE_PATIENT_DOCUMENT_SUCCESS = '@PATIENT/DELETE_PATIENT_DOCUMENT_SUCCESS';
export const DELETE_PATIENT_DOCUMENT_FAIL = '@PATIENT/DELETE_PATIENT_DOCUMENT_FAIL';

export const UPDATE_PATIENT_DOCUMENT = '@PATIENT/UPDATE_PATIENT_DOCUMENT';
export const UPDATE_PATIENT_DOCUMENT_SUCCESS = '@PATIENT/UPDATE_PATIENT_DOCUMENT_SUCCESS';
export const UPDATE_PATIENT_DOCUMENT_FAIL = '@PATIENT/UPDATE_PATIENT_DOCUMENT_FAIL';

export const SET_SELECTED_DOCUMENT = '@PATIENT/SET_SELECTED_DOCUMENT';

export function addDocument({ description, resourceDocumentId }) {
  return (dispatch, getState) => {
    const { patientId } = getState().patient.update.base.data;
    dispatch({ type: SUBMIT_PATIENT_DOCUMENT });
    HttpService.post(`/api/patients/${patientId}/documents`, {
      resourceDocumentId: resourceDocumentId,
      description: description,
    })
      .then((resp) => {
        dispatch({ type: SUBMIT_PATIENT_DOCUMENT_SUCCESS });
        dispatch(
          AppActions.showMessage({
            message: 'Document has been added successfully',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
        dispatch(AppActions.dataLoader_reload('PatientDocuments'));
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: SUBMIT_PATIENT_DOCUMENT_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function deleteDocument(documentId) {
  return (dispatch, getState) => {
    const { patientId } = getState().patient.update.base.data;
    dispatch({ type: DELETE_PATIENT_DOCUMENT });
    HttpService.delete(`/api/patients/${patientId}/document/${documentId}`)
      .then(() => {
        dispatch({ type: DELETE_PATIENT_DOCUMENT_SUCCESS, payload: { documentId } });
        dispatch(
          AppActions.showMessage({
            message: 'Document has been deleted successfully',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
        dispatch(AppActions.dataLoader_reload('PatientDocuments'));
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: DELETE_PATIENT_DOCUMENT_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function updateDocument({ documentId, description, resourceDocumentId }) {
  return (dispatch, getState) => {
    const { patientId } = getState().patient.update.base.data;
    dispatch({ type: UPDATE_PATIENT_DOCUMENT });
    HttpService.put(`/api/patients/${patientId}/documents/${documentId}`, {
      resourceDocumentId: resourceDocumentId,
      description: description,
    })
      .then(() => {
        dispatch({ type: UPDATE_PATIENT_DOCUMENT_SUCCESS });
        dispatch(
          AppActions.showMessage({
            message: 'Document has been updated successfully',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
        dispatch(AppActions.dataLoader_reload('PatientDocuments'));
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: UPDATE_PATIENT_DOCUMENT_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function setSelectedDocument(document) {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_DOCUMENT, payload: { document } });
  };
}
