const locale = {
  APPLICATIONS: 'Applications',
  HOME: 'Home',
  PATIENT: 'Patients',
  DRUG: 'Drugs',
  PATIENT_GROUP: 'Patient Group',
  DOCTOR: 'Doctors',
  DIRECTION: 'Directions',
  PRINT_FOIL: 'Pack Creator',
  REPORT: 'Reporting',
  PRINT_PROFILE: 'Print Options',
  ROLE: 'Roles',
  USER: 'User',
  BRAND_SWAP: 'Brand Swap',
  PPA_CLAIM_REPORT: 'PPA Claim Reports',
  PHARMACY: 'Pharmacy',
  JOB_MANAGEMENT: 'Pack Management',
  VIRTUAL_PILL_BALANCE: 'Virtual Pill Balance',
  DISPENSE_TRACKER: 'Dispense Tracker',
  ADMINISTRATION: 'Administration',
  SIGNING_SHEETS: 'Signing Sheets',
  PATIENT_REPORT: 'Patient Reports',
  SUMMARY_REPORT: 'Summary Reports',
  SCRIPT_REQUEST_REPORT: 'Script Request Reports',
  DASHBOARD_PERMISSIONS: 'Dashboard Permissions',
  MYPAK_HELP: 'Help',
  LOG_OUT: 'Logout',
  Q_SCRIPT: 'QScript',
  SYNC_DATA: 'Synced',
  NO_SYNC_DATA: 'Not Synced',
  HELP_CENTRE: 'Help Centre',
  CONTACT_SUPPORT: 'Contact Support',
  WHAT_NEW: "What's New",
  GIVE_FEEDBACK: 'Give Feedback',
};

export default locale;
