/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
import { useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useDialogMessage from './useDialogMessage';

const MESSAGE = 'All unsaved data will be lost. Are you sure you want to continue?';
const TITLE = 'Warning';

const EXCLUDED_URL = ['/auth/login'];

/**
 * MC-898977
 * This hook will show warning dialog when
 * + user navigate to other route
 * + cancel action
 * + change tab
 * while user create or update something
 */
/**
 * Note: do not render hidden input in view mode
 */
export default function useWarning(options = { reload: false, success: false }) {
	const history = useHistory();
	const dialog = useDialogMessage('warning');
	const { t } = useTranslation();
	const isNeedWarning = useRef(false);
	/**
	 * Note for isNeedRedirect: We will prioritize the route that user want to go
	 * than the route that component want to go. Reference Drug module.
	 */
	const isNeedRedirect = useRef(true);
	const submitButtonRef = useRef(null);
	const formRef = useRef(null);
	const hiddenItemValueRef = useRef(new Map()); // Hold value of customized components like react-select
	const unblockRef = useRef(null);
	const pathnameRef = useRef(null);

	useEffect(() => {
		const formChangeEvent = () => {
			setIsNeedWarning(true);
		};

		if (formRef && formRef.current && formRef.current.addEventListener) {
			formRef.current.addEventListener('change', formChangeEvent);
		}

		if (!hiddenItemValueRef.current.size) getHiddenItems();

		return () => {
			cleanUp();
			if (formRef && formRef.current && formRef.current.removeEventListener) {
				formRef.current.removeEventListener('change', formChangeEvent);
			}
		};
	}, [formRef.current]);

	useEffect(() => {
		/**
		 * To keep list hidden input to sync with update form have edit mode
		 * Example:
		 * + src\app\main\direction\update\UpdateDirection.js
		 * + src\app\main\drug\update\UpdateDrug.js
		 */
		options?.reload && getHiddenItems();

		return () => {
			cleanUp();
		};
	}, [options?.reload]);

	useEffect(() => {
		/** When submit successfully we should execute cleanUp to do other thing
		 * such as moving to other route or back action...
		 * Example:
		 * + src\app\main\drug\create\CreateDrug.js
		 * + src\app\main\patient\create\CreatePatient.js
		 */
		if (options?.success) {
			cleanUp();
			if (unblockRef.current && pathnameRef.current) {
				unblockRef.current();
				history.push(pathnameRef.current);
			}
		}
	}, [options?.success]);

	// About route change, it will be listened in this effect
	useEffect(() => {
		const unblock = history.block(tx => {
			pathnameRef.current = `${tx.pathname}${tx.search}`;

			if (EXCLUDED_URL.includes(tx.pathname) || !checkNeedWarning()) return true;

			setIsNeedRedirect(false);
			warning(() => {
				unblock();
				history.push(`${tx.pathname}${tx.search}`);
			});

			return false;
		});

		unblockRef.current = unblock;

		return () => {
			unblock();
		};
	}, [isNeedWarning.current, checkNeedWarning]);

	// About cancel action or change tab action, it should be wrappped by this function
	const functionWrapper = useCallback(
		f => (...args) => {
			if (!checkNeedWarning()) f(...args);
			else warning(() => f(...args));
		},
		[isNeedWarning.current, checkNeedWarning]
	);

	function warning(callback) {
		dialog
			.confirm(MESSAGE, TITLE, {
				buttons: [{ label: t('BUTTON_CANCEL') }, { label: t('BUTTON_CONFIRM'), variant: 'contained', color: 'primary' }]
			})
			.on(label => {
				if (label === t('BUTTON_CONFIRM')) {
					cleanUp();
					callback && callback();
					// submitButtonRef && submitButtonRef.current && submitButtonRef.current.click();
				}

				dialog.close();
			});
	}

	function checkNeedWarning() {
		if (isNeedWarning.current) return true;
		if (!hiddenItemValueRef.current.size) return false;

		let hiddenItems = [];
		if (formRef && formRef.current && formRef.current.querySelectorAll) {
			hiddenItems = formRef.current.querySelectorAll('input[type=hidden]');
		}

		for (let i = 0; i < hiddenItems.length; i++) {
			if (
				hiddenItemValueRef.current.has(hiddenItems[i].name) &&
				hiddenItemValueRef.current.get(hiddenItems[i].name) !== hiddenItems[i].value
			) {
				return true;
			}
		}

		return false;
	}

	function getHiddenItems() {
		/**
		 * In some forms like prescription, we using FuseAnimateGroup to make animation
		 * it will delay render component in short time so we will use setTimeout without
		 * time to make querySelectorAll happen in next event cycle instead of immediately.
		 */
		setTimeout(() => {
			formRef?.current
				?.querySelectorAll('input[type=hidden]')
				?.forEach(n => hiddenItemValueRef.current.set(n.name, n.value));
		});
	}

	function onChangeWrapper(_func) {
		/**
		 * In some case such as checkbox in PermissionTable (src\app\main\role\components\PermissionTable\PermissionTable.js)
		 * event input of form do not work when data change. So we will use this function to wrap onChange function.
		 */
		return (...args) => {
			_func(...args);
			setIsNeedWarning(true);
		};
	}

	function setIsNeedWarning(value) {
		isNeedWarning.current = value;
	}

	function setIsNeedRedirect(value) {
		isNeedRedirect.current = value;
	}

	function cleanUp() {
		setIsNeedWarning(false);
		hiddenItemValueRef.current = new Map();
	}

	return {
		isNeedRedirect,
		formRef,
		submitButtonRef, // From MC-430926 we do not using this ref
		functionWrapper,
		onChangeWrapper,
		setIsNeedWarning
	};
}
