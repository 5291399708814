import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';
import _ from 'lodash';

export const GET_PATIENTS = '@PATIENT/GET_PATIENTS';
export const GET_PATIENTS_SUCCESS = '@PATIENT/GET_PATIENTS_SUCCESS';
export const GET_PATIENTS_FAILED = '@PATIENT/GET_PATIENTS_FAILED';

export const REMOVE_PATIENT = '@PATIENT/REMOVE_PATIENT';
export const REMOVE_PATIENT_SUCCESS = '@PATIENT/REMOVE_PATIENT_SUCCESS';
export const REMOVE_PATIENT_FAIL = '@PATIENT/REMOVE_PATIENT_FAIL';

export const IMPORT_DISPENSE_PATIENT = '@PATIENT/IMPORT_DISPENSE_PATIENT';
export const IMPORT_DISPENSE_PATIENT_SUCCESS = '@PATIENT/IMPORT_DISPENSE_PATIENT_SUCCESS';
export const IMPORT_DISPENSE_PATIENT_FAIL = '@PATIENT/IMPORT_DISPENSE_PATIENT_FAIL';

export const IMPORT_DISPENSE_UPDATE_MAX_DAYS = '@PATIENT/IMPORT_DISPENSE_UPDATE_MAX_DAYS';

export const SET_PATIENT_PAGING = '@PATIENT/SET_PATIENT_PAGING';
export const RESET_PATIENT_PAGING = '@PATIENT/RESET_PATIENT_PAGING';
export const RESET_PATIENT_PAGE_INDEX = '@PATIENT/RESET_PATIENT_PAGE_INDEX';

export const SET_PATIENT_FILTER = '@PATIENT/SET_PATIENT_FILTER';
export const RESET_PATIENT_FILTER = '@PATIENT/RESET_PATIENT_FILTER';

export const SET_LIST_MESSAGE = '@PATIENT/SET_LIST_MESSAGE';

export const GET_PATIENT_SUMMARY = '@PATIENT/GET_SUMMARY';

export const GET_ALL_PATIENT = '@PATIENT/GET_ALL_PATIENT';

export const GET_PACKING_STATUS_OPTIONS = '@PATIENT_GET_PACKING_STATUS_OPTIONS';

export const IMPORT_DISPENSE_UPDATE_DAY_IMPROT_DISPENSE = '@PATIENT/IMPORT_DISPENSE_UPDATE_DAY_IMPROT_DISPENSE';
export const IMPORT_DISPENSE_UPDATE_DAY_IMPROT_DISPENSE_FAIL =
  '@PATIENT/IMPORT_DISPENSE_UPDATE_DAY_IMPROT_DISPENSE_FAIL';

export const getPatients = () => {
	return (dispatch, getState) => {
		const { pageIndex, pageSize } = getState().patient.list.paging;
		const { filter } = getState().patient.list;
		dispatch({ type: GET_PATIENTS });
		HttpService.abort('getPatients');
		HttpService.get(
			'/api/patients',
			{
				...(pageIndex && { pageIndex }),
				...(pageSize && { pageSize }),
				...(filter.textSearch && { query: filter.textSearch })
			},
			{ cancelToken: 'getPatients' }
		)
			.then(resp => {
				dispatch({ type: GET_PATIENTS_SUCCESS, payload: { resp } });
				if (!resp.total) {
					if (_.compact(_.map(filter, k => k)).length) {
						dispatch(setListMessage('PATIENT_LIST_NO_SEARCH_RESULT'));
					} else {
						dispatch(setListMessage('PATIENT_LIST_NO_DATA'));
					}
				} else {
					dispatch(setListMessage(''));
				}
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch({ type: GET_PATIENTS_FAILED, payload: { message: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

export const removePatient = patientId => {
	return dispatch => {
		dispatch(AppActions.closeDialogMessage('patient-archive'));
		const deleteApi = `api/patients/${patientId}`;
		HttpService.abort(`removePatient`);
		HttpService.delete(deleteApi, { cancelToken: `removePatient` })
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'patient:ARCHIVE_PATIENT_SUCCESS'
					})
				);
				dispatch({ type: REMOVE_PATIENT_SUCCESS });
				// dispatch(AppActions.dataLoader_reload(`GET:/api/patients`));
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch({ type: REMOVE_PATIENT_FAIL, payload: { message: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

export const setPatientFilter = (filter, userId) => {
  return (dispatch) => {
    // dispatch({ type: RESET_PATIENT_PAGE_INDEX });
    HttpService.put(`/api/users/${userId}/packing-status-option`, { packingStatuses: filter.packingStatus })
      .then(() => {
        dispatch({ type: SET_PATIENT_FILTER, payload: { filter } });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const resetPatientFilter = () => {
	return dispatch => {
		dispatch({ type: RESET_PATIENT_FILTER });
	};
};

export const setListMessage = message => {
	return dispatch => {
		dispatch({ type: SET_LIST_MESSAGE, payload: { message } });
	};
};

export const importDispensePatient = data => {
	return dispatch => {
		dispatch({ type: IMPORT_DISPENSE_PATIENT });
		return HttpService.post('/api/patients/dispense', data)
			.then(resp => {
				dispatch({ type: IMPORT_DISPENSE_PATIENT_SUCCESS });
				dispatch(
					AppActions.showMessage({
						message: resp?.message || 'patient:IMPORT_DISPENSE_PATIENT_SUCCESS'
					})
				);

				return true;
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch({ type: IMPORT_DISPENSE_PATIENT_FAIL, payload: { message: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

export const importDispenseUpdateMaxDays = (maxDaysToCheckScript) => {
  return (dispatch, getState) => {
    const { currentPharmacyId } = getState().auth.user.data;
    dispatch({ type: IMPORT_DISPENSE_UPDATE_MAX_DAYS });
    return HttpService.put(`/api/pharmacies/${currentPharmacyId}/configuration-maxdate`, {
      maxDaysToCheckScript,
    }).catch((err) => {
      const { errorMessages, errorTitle } = parseError(err);
      dispatch({
        type: IMPORT_DISPENSE_UPDATE_DAY_IMPROT_DISPENSE_FAIL,
        payload: { message: errorMessages.join('\n') },
      });
      dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
    });
  };
};

export const importDispenseUpdateDayImportDispense = (daysImportNewDrugsDispensed) => {
  return (dispatch, getState) => {
    const { currentPharmacyId } = getState().auth.user.data;
    dispatch({ type: IMPORT_DISPENSE_UPDATE_DAY_IMPROT_DISPENSE });
    return HttpService.put(`/api/pharmacies/${currentPharmacyId}/configuration-dayimportdispense`, {
      daysImportNewDrugsDispensed,
    }).catch((err) => {
      const { errorMessages, errorTitle } = parseError(err);
      dispatch({ type: IMPORT_DISPENSE_PATIENT_FAIL, payload: { message: errorMessages.join('\n') } });
      dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
    });
  };
};

export const getPatientSummary = filter => {
	return dispatch => {
		HttpService.post('/api/patients/patient-summary', filter).then(res => {
			dispatch({ type: GET_PATIENT_SUMMARY, payload: res.data });
		});
	};
};

export const getAllPatient = (filter) => {
  return (dispatch) => {
    HttpService.post('/api/patients/selection-items', filter).then((resp) => {
      dispatch({ type: GET_ALL_PATIENT, payload: resp });
    });
  };
};

export const getPackingStatusOptions = (userId) => {
  return (dispatch) => {
    HttpService.get(`/api/users/${userId}/packing-status-option`)
      .then((resp) => {
        dispatch({ type: GET_PACKING_STATUS_OPTIONS, payload: resp.data?.packingStatuses });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};
