import * as DashboardActions from '../actions';

const DEFAULT_DATA = {
	id: null,
	roleLevel: '',
	charts: []
};

const initState = {
	data: { ...DEFAULT_DATA },
	loading: false,
	message: null
};

const permissionsReducer = (state = initState, actions) => {
	const { type, payload } = actions;

	switch (type) {
		// GET_PERMISSION_DETAIL
		case DashboardActions.GET_PERMISSION_DETAIL:
			return { ...state, loading: true, message: null, errors: null };
		case DashboardActions.GET_PERMISSION_DETAIL_SUCCESS:
			return { ...state, loading: false, data: payload };
		case DashboardActions.GET_PERMISSION_DETAIL_FAIL:
			return { ...state, loading: false, message: payload.message };
		// UPDATE_PERMISSION_DETAIL
		case DashboardActions.UPDATE_PERMISSION_DETAIL:
			return { ...state, loading: true, message: null, errors: null };
		case DashboardActions.UPDATE_PERMISSION_DETAIL_SUCCESS:
			return { ...state, loading: false, data: payload };
		case DashboardActions.UPDATE_PERMISSION_DETAIL_FAIL:
			return { ...state, loading: false, message: payload.message };
		// RESET
		case DashboardActions.RESET:
			return { ...state, data: { ...DEFAULT_DATA } };
		default:
			return state;
	}
};

export default permissionsReducer;
