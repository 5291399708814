import React from 'react';
import { TextField, FormControl, InputLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	readonlyText: {
		padding: props =>
			props.inline ? `${theme.spacing(0.625)}px 0` : `${theme.spacing(1.875)}px 0 ${theme.spacing(0.625)}px`,
		width: '100%',
		flex: 1,
		wordBreak: 'break-all'
	},
	input: {
		display: 'flex',
		'& > label': {
			overflow: 'hidden',
			position: 'relative',
			transform: 'none',
			alignItems: 'center',
			display: 'flex',
			color: '#414141',
			paddingTop: props => (props.multiline ? theme.spacing(0.85) : 0)
		},
		'& input, & textarea': {
			padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
			fontWeight: 600,
			fontSize: 12,
			'&.Mui-disabled': {
				background: '#f2f2f2'
			}
		},
		'& fieldset': {
			borderRadius: props => (props.inline ? 2 : 4)
		}
	},
	inline: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: props => (props.multiline ? 'start' : 'unset'),
		'& > label': {
			width: props => (props.labelSize !== undefined ? `${props.labelSize}px` : '200px'),
			paddingTop: props => (props.multiline ? theme.spacing(0.85) : 0)
		},
		'& .MuiOutlinedInput-multiline': {
			padding: 0
		},
		'& fieldset': {
			borderRadius: props => (props.inline ? 2 : 4)
		}
	},
	stacked: {
		flexDirection: 'column',
		'& > label': {
			paddingBottom: theme.spacing(0.625)
		}
	}
}));

const MyTextField = React.forwardRef((props, ref) => {
	const { readOnly, inline, labelSize, labelPosition, value, bold = true, ...rest } = props;

	let inputValue = value ?? '';
	if (Array.isArray(value) && !value.length) inputValue = '';

	const classes = useStyles(props);

	const isValueEmpty = inputValue === '';

  if (labelPosition === 'stacked') {
    const { label, ...others } = rest;
    return (
      <FormControl fullWidth className={clsx(classes.input, classes.stacked)}>
        <InputLabel required={others.required}>{label}</InputLabel>
        {!readOnly && (
          <TextField
            inputProps={{
              autoComplete: 'off',
            }}
            className="flex-1"
            value={inputValue}
            {...others}
            ref={ref}
          />
        )}
        {readOnly && (
          <Typography className={clsx(classes.readonlyText, 'cursor-default')}>
            {isValueEmpty ? '-' : inputValue}
          </Typography>
        )}
      </FormControl>
    );
  }

  if (inline) {
    const { label, labelClass, ...others } = rest;
    return (
      <FormControl fullWidth className={clsx(classes.input, classes.inline)}>
        <InputLabel className={clsx(labelClass, bold && 'font-bold')} required={others.required}>
          {label}
        </InputLabel>
        {!readOnly && (
          <TextField
            inputProps={{
              autoComplete: 'off',
            }}
            className="flex-1"
            value={inputValue}
            {...others}
            ref={ref}
          />
        )}
        {readOnly && (
          <Typography className={clsx(classes.readonlyText, 'cursor-default')}>
            {isValueEmpty ? '-' : inputValue}
          </Typography>
        )}
      </FormControl>
    );
  }

	if (readOnly) {
		return (
			<FormControl fullWidth>
				<InputLabel shrink>{rest.label}</InputLabel>
				<Typography className={clsx(classes.readonlyText, 'cursor-default')}>
					{isValueEmpty ? '-' : inputValue}
				</Typography>
			</FormControl>
		);
	}

  return (
    <TextField
      value={inputValue}
      inputProps={{
        autoComplete: 'off',
      }}
      {...rest}
      ref={ref}
    />
  );
});

export default MyTextField;
