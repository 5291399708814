import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import i18next from 'i18next';
import { MENU_ENUM } from 'app/main/role/constants';
import en from './i18n/en';

i18next.addResourceBundle('en', 'print', en);
const PrintConfig = {
	settings: {
		layout: {
			config: {}
		},
		nav: {
			title: 'Pack Creator'
		}
	},
	auth: authRoles.user,
	key: MENU_ENUM.PRINT_FOIL,
	permission: PERMISSION.READ,
	routes: [
		{
			path: '/print-foil',
			component: React.lazy(() => import('./Print'))
		}
	]
};

export default PrintConfig;
