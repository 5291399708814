const locale = {
  TITLE: 'RESET YOUR PASSWORD',
  PASSWORD_LABEL: 'Password',
  PASSWORD_REQUIRE_ERROR: 'Password is required',
  PASSWORD_CONFIRM_LABEL: 'Confirm Password',
  PASSWORD_CONFIRM_INVALID_ERROR: 'Password (Confirm) does not match',
  BUTTON_RESET_PASSWORD: 'RESET PASSWORD',
  LINK_BACK_TO_LOGIN: 'Go back to login',
  REQUEST_FAIL_DIALOG_TITLE: 'Opps! Something has happened',
  REQUEST_SUCCESS_MESSAGE: 'Password has been reset successfully!',
};

export default locale;
