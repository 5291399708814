import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const GET_PRINT_PROFILE = '@PRINT_PROFILE/GET_PRINT_PROFILE_DETAILS';
export const GET_PRINT_PROFILE_SUCCESS = '@PRINT_PROFILE/GET_PRINT_PROFILE_SUCCESS';
export const GET_PRINT_PROFILE_FAIL = '@PRINT_PROFILE/GET_PRINT_PROFILE_FAIL';

export const UPDATE_PRINT_PROFILE = '@PRINT_PROFILE/UPDATE_PRINT_PROFILE';
export const UPDATE_PRINT_PROFILE_SUCCESS = '@PRINT_PROFILE/UPDATE_PRINT_PROFILE_SUCCESS';
export const UPDATE_PRINT_PROFILE_FAIL = '@PRINT_PROFILE/UPDATE_PRINT_PROFILE_FAIL';

export const CHANGE_PRINT_PROFILE_UPDATE_FORM = '@PRINT_PROFILE/CHANGE_PRINT_PROFILE_UPDATE_FORM';

export const CLEAR_UPDATE_FORM = '@PRINT_PROFILE/CLEAR_UPDATE_FORM';

export const TEST_PRINT = '@PRINT_PROFILE/TEST_PRINT';
export const TEST_PRINT_SUCCESS = '@PRINT_PROFILE/TEST_PRINT_SUCCESS';
export const TEST_PRINT_FAIL = '@PRINT_PROFILE/TEST_PRINT_FAIL';

export function getPrintProfileDetails(printProfileId) {
	return dispatch => {
		dispatch({ type: GET_PRINT_PROFILE });
		HttpService.get(`api/printprofiles/${printProfileId}`)
			.then(resp => {
				dispatch({ type: GET_PRINT_PROFILE_SUCCESS, payload: { data: parseToFormObject(resp.data) } });
			})
			.catch(err => {
				const { errorFields, errorMessages } = parseError(err);
				dispatch({
					type: GET_PRINT_PROFILE_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields }
				});
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
}

export function updatePrintProfile() {
	return (dispatch, getState) => {
		const { data, id } = getState().printProfile.update;
		const postData = {
			name: data.name,
			topMargin: data.topMargin || '0',
			leftMargin: data.leftMargin || '0',
			width: data.width || '0',
			height: data.height || '0',
			foilDrugNameLength: data.foilDrugNameLength || '0',
			alpacaFoilDirectionLength: data.alpacaFoilDirectionLength || '0',
			isPackDateOnFoil: data.isPackDateOnFoil,
			isPackStartDate: data.isPackStartDate,
			isDrugWarning: data.isDrugWarning,
			isSamAsDrugInfo: data.isSamAsDrugInfo,
			isPackDoseTimeHeading: data.isPackDoseTimeHeading,
			isPackBarcode: data.isPackBarcode,
			isUnpackedPrnMedArea: data.isUnpackedPrnMedArea
		};
		dispatch({ type: UPDATE_PRINT_PROFILE });

		HttpService.put(`api/printprofiles/${id}`, postData)
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'printProfile:UPDATE_PRINT_PROFILE_SUCCESS'
					})
				);
				dispatch({ type: UPDATE_PRINT_PROFILE_SUCCESS, payload: { data: postData } });
			})
			.catch(err => {
				const { errorFields, errorMessages } = parseError(err);
				dispatch({
					type: UPDATE_PRINT_PROFILE_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields }
				});
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
}

export function clearUpdateForm() {
	return dispatch => {
		dispatch({ type: CLEAR_UPDATE_FORM });
	};
}

export function changePrintProfileUpdateForm(data) {
	return dispatch => {
		dispatch({ type: CHANGE_PRINT_PROFILE_UPDATE_FORM, payload: { data } });
	};
}

export const parseToFormObject = data => {
	return {
		clientCode: data.clientCode,
		packType: data.packType,
		printProfileId: data.printProfileId,
		name: data.name,
		topMargin: data.topMargin.toString(),
		leftMargin: data.leftMargin.toString(),
		width: data.width.toString(),
		height: data.height.toString(),
		foilDrugNameLength: data.foilDrugNameLength.toString(),
		alpacaFoilDirectionLength: data.alpacaFoilDirectionLength.toString(),
		isPackDateOnFoil: data.isPackDateOnFoil,
		isPackStartDate: data.isPackStartDate,
		isDrugWarning: data.isDrugWarning,
		isSamAsDrugInfo: data.isSamAsDrugInfo,
		isPackDoseTimeHeading: data.isPackDoseTimeHeading,
		isPackBarcode: data.isPackBarcode,
		isUnpackedPrnMedArea: data.isUnpackedPrnMedArea
	};
};

export const testPrint = () => {
  return (dispatch, getState) => {
    const { data } = getState().printProfile.update;
    const printOption = {
      packType: data.packType,
    };

    dispatch({ type: TEST_PRINT });

    HttpService.post('api/printprofiles/test-print', printOption)
      .then((resp) => {
        if (resp.data) {
          dispatch({
            type: TEST_PRINT_SUCCESS,
            payload: { data: resp.data },
          });
        } else {
          dispatch({ type: TEST_PRINT_FAIL });
          dispatch(AppActions.createDialogMessage('info', 'print:NO_CONTENT_HEADER', 'print:NO_CONTENT_MESSAGE'));
        }
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: TEST_PRINT_FAIL,
          payload: { message: errorMessages, error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};
