import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const QUICK_ADD_DRUG = '@DRUG/QUICK_ADD_DRUG';
export const QUICK_ADD_DRUG_SUCCESS = '@DRUG/QUICK_ADD_DRUG_SUCCESS';
export const QUICK_ADD_DRUG_FAIL = '@DRUG/QUICK_ADD_DRUG_FAIL';

export const quickAddDrug = data => {
	return dispatch => {
		dispatch({ type: QUICK_ADD_DRUG });
		HttpService.post(`api/drugs`, data)
			.then(resp => {
				dispatch({ type: QUICK_ADD_DRUG_SUCCESS, payload: { data: resp.data } });
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch({
					type: QUICK_ADD_DRUG_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields }
				});
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};
