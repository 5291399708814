const locale = {
  DAA_CLAIM_TEMPLATE: 'DAA',
  IDAA_CLAIM_TEMPLATE: 'IDAA',
  DAA_NOTE:
    'Patients with PPA Funding status: PPA Various, Literacy/Language Issues, Physical Disability, Cognitive Difficulties',
  IDAA_NOTE: 'Patients with PPA Funding status: Aboriginal/Torres Strait Islander',
  EXPORT_TO_EXCEL: 'Export to Excel',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  MEDICARE_NUMBER: 'Medicare/DVA Number',
  DATE_OF_SERVICE: 'Date of Service',
  REASON_FOR_EXCLUSION: 'Reason for Exclusion',
  REASON_MESSAGE_OVERLAPPED: 'Supplied within 7 days of another',
  REASON_MESSAGE_NO_MEDICARE: 'No Medicare/DVA Number',
  REMOVE_ENTRY: 'Remove Entry',
  FROM_DATE: 'From date',
  TO_DATE: 'To date',
  NUMBER_OF_PATIENT: 'Number of patient: {{numberOfPatient}}/{{total}}',
  INCLUDE_INACTIVE_PATIENT: 'Include inactive patient',
  MEDICARE_NUMBER_NOTE: 'Medicare will show unless: Patient DVA number entered (will overwrite Medicare number)',
  PATIENT_NAME_NOTE: 'Patient Name is not included in Excel Export',
  BLISTER_PACK_PATIENT_NOTE:
    'Please note for blister pack Patients: If 2 weeks is being packed in the one blister pack, you will need 	to select the tick box in each patient profile to display 2nd week Start date',
  BRAND_NAME: 'Brand Name',
  GENERIC_NAME: 'Generic Name',
  FORM: 'Form',
  STRENGTH: 'Strength',
  DOSE: 'Dose',
  DOSE_REGIMEN: 'Dose Regimen',
  TOTAL_SERVICES: 'Total Services',
  TOTAL_ELIGIBLE_SERVICES: 'Total Eligible Services',
  NUMBER_OF_PATIENTS: 'Number of Patients',
  EXPORT_NOTE: 'Ensure every patient has a Medicare or DVA number recorded',
  EDIT_MEDICARE_NUMBER: 'Eidt',
  MEDICARE_NUMBER_LABEL: 'Medicare/DVA Number',
};

export default locale;
