const locale = {
  TITLE: 'Reports',
  HEADER_TITLE: 'Reports & Signing Sheets',
  SELECT_PATIENT: 'Select Patient',
  SELECTED_PATIENT: 'Selected Patients',
  SELECT_PATIENT_GROUP: 'Select Patient Group',
  SECTION_SELECTION: 'Selection',
  PATIENT_GROUP: 'Patient Group',
  WARD: 'Ward',
  PATIENT_HEADER_FIRST_NAME: 'First Name',
  PATIENT_HEADER_LAST_NAME: 'Last Name',
  PATIENT_SEARCH_PLACEHOLDER: 'Search...',
  REPORT_SEARCH_PLACEHOLDER: 'Search report...',
  PATIENT_HEADER_ROOM: 'Room',
  REPORT_HEADER_NAME: 'Report Title',
  BUTTON_OPTIONS: 'Options',
  BUTTON_PRINT: 'Print',
  BUTTON_EXPORT_CSV: 'Export as CSV',
  REPORT_OPTIONS_DIALOG_HEADER: 'Signing Sheet Options',
  PRINT_REPORT_SUCCESS: 'Report has been printed',
  VALIDATE_OPTION_MESSAGE: 'Please provide required options',
  FROM_DATE_OPTION: 'From Date',
  TO_DATE_OPTION: 'To Date',
  SIGNING_SHEET_START_DATE: 'Signing Sheet Start Date',
  REMOVE_ADMINISTRATION_DATES: 'Remove administration dates',
  PRINT_CARE_ADMIN: 'Include signing option for Care Worker',
  PRINT_WARFARIN: 'Print warfarin on Regular Signing Sheets',
  PRINT_INSULIN: 'Print insulin on Regular Signing Sheets',
  PRINT_ORDER: 'Print Order',
  DO_NOT_PRINT_INDICATIONS: "Don't print indications in Patient Med Summary",
  EXCLUDE_SHORTCOURSE_LABEL: 'Exclude Shortcourse',
  EXCLUDE_S8_LABEL: 'Exclude S8',
  EXCLUDE_PRN_LABEL: 'Exclude PRN',
  HIDE_DOCTOR_SIGNATURE: 'Hide doctor signature area in Patient Check Sheet',
  PRINT_DATE: 'Print Date',
  NO_REPORT_MESSAGE: 'There is no Report.',
  NO_CONTENT_TITLE: 'No content',
  NO_CONTENT_MESSAGE: 'This report has no content to print.',
  TITLE_TAB_REPORT: 'Report',
  TITLE_TAB_SIGNING_SHEET: 'Signing Sheet',
  INCLUDE_SUSPENDED_PATIENT: 'Include Suspended Patients',
  TOOLTIP_SUSPENDED_PATIENT: 'Suspended Patient',
  REPORT_OPTIONS: 'Report Options',
  PACKED_PRESCRIPTION_SECTION: 'Packed',
  UNPACKED_PRESCRIPTION_SECTION: 'Non-Packed',
  PACKED_PRN_PRESCRIPTION_SECTION: 'Packed PRN',
  UNPACKED_PRN_PRESCRIPTION_SECTION: 'Non-Packed PRN',
  DRUG_STATUS: 'Drug Status to include:',
  PATIENT_MED_SUMMARY: 'Indications on Patient Med Summary',
};

export default locale;
