import * as UpdatePatientContactActions from '../actions/update.contacts.actions';

const initialState = {
	selectedContact: {
		contactId: '',
		firstName: '',
		lastName: '',
		street: '',
		phone: '',
		address: '',
		state: '',
		email: '',
		notes: ''
	},
	submitting: false,
	error: null
};

const updateNote = (state = initialState, action) => {
	switch (action.type) {
		case UpdatePatientContactActions.CREATE_CONTACT:
			return {
				...state,
				submitting: true,
				error: null
			};
		case UpdatePatientContactActions.CREATE_CONTACT_SUCCESS:
			return {
				...state,
				submitting: false
			};
		case UpdatePatientContactActions.CREATE_CONTACT_FAIL:
			return {
				...state,
				submitting: false,
				error: action.payload.message
			};
		case UpdatePatientContactActions.ARCHIVE_CONTACT:
			return {
				...state,
				submitting: true,
				error: null
			};
		case UpdatePatientContactActions.ARCHIVE_CONTACT_SUCCESS:
			return {
				...state,
				submitting: false
			};
		case UpdatePatientContactActions.ARCHIVE_CONTACT_FAIL:
			return {
				...state,
				submitting: false,
				error: action.payload.message
			};
		case UpdatePatientContactActions.SET_CONTACT_FORM:
			return {
				...state,
				selectedContact: action.payload.contact
			};
		case UpdatePatientContactActions.UPDATE_CONTACT:
			return {
				...state,
				submitting: true,
				error: null
			};
		case UpdatePatientContactActions.UPDATE_CONTACT_SUCCESS:
			return {
				...state,
				submitting: false
			};
		case UpdatePatientContactActions.UPDATE_CONTACT_FAIL:
			return {
				...state,
				submitting: false,
				error: action.payload.message
			};
		default:
			return state;
	}
};

export default updateNote;
