/* eslint-disable no-plusplus */
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles';
import NavbarMobileToggleFab from 'app/fuse-layouts/shared-components/NavbarMobileToggleFab';
import * as Actions from 'app/store/actions';
import clsx from 'clsx';
import React, { useRef, useEffect, useMemo } from 'react';
import throttle from 'lodash/throttle';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import NavbarLayout1 from './NavbarLayout1';

const navbarWidth = 245;

const useStyles = makeStyles(theme => ({
	wrapper: {
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		zIndex: 4,
		[theme.breakpoints.up('xs')]: {
			width: navbarWidth,
			minWidth: navbarWidth
		}
	},
	wrapperFolded: {
		[theme.breakpoints.up('xs')]: {
			width: 64,
			minWidth: 64
		}
	},
	navbar: {
		display: 'flex',
		// overflow: 'hidden',
		flexDirection: 'column',
		flex: '1 1 auto',
		width: navbarWidth,
		minWidth: navbarWidth,
		height: '100%',
		zIndex: 4,
		transition: theme.transitions.create(['width', 'min-width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.shorter
		})
		// boxShadow: theme.shadows[3]
	},
	left: {
		left: 0
	},
	right: {
		right: 0
	},
	folded: {
		position: 'absolute',
		width: 64,
		minWidth: 64,
		top: 0,
		bottom: 0
	},
	foldedAndOpened: {
		width: `${navbarWidth}px!important`,
		minWidth: `${navbarWidth}px!important`
	},
	navbarContent: {
		flex: '1 1 auto'
	},
	foldedAndClosed: {
		'& $navbarContent': {
			'& .logo-icon': {
				width: 32,
				height: 32
			},
			'& .logo-text': {
				opacity: 0
			},
			'& .react-badge': {
				opacity: 0
			},
			'& .list-item-text, & .arrow-icon, & .item-badge': {
				opacity: 0,
				visibility: 'hidden'
			},
			'& .list-subheader .list-subheader-text': {
				opacity: 0
			},
			'& .list-subheader:before': {
				content: '""',
				display: 'block',
				position: 'absolute',
				minWidth: 16,
				borderTop: '2px solid',
				opacity: 0.2
			},
			'& .collapse-children': {
				display: 'none'
			},
			'& .user': {
				'& .username, & .email': {
					opacity: 0
				},
				'& .avatar': {
					width: 40,
					height: 40,
					top: 32,
					padding: 0
				}
			},
			'& .list-item.active': {
				marginLeft: 6,
				width: 50,
				padding: 15,
				borderRadius: '25px',
				transition: theme.transitions.create(['width'], {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.shorter
				}),
				'&.square': {
					borderRadius: 0,
					marginLeft: 0,
					paddingLeft: 24,
					width: '100%'
				}
			}
		}
	}
}));

function NavbarWrapperLayout1() {
	const dispatch = useDispatch();
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const navbarTheme = useSelector(({ fuse }) => fuse.settings.navbarTheme);
	const navbar = useSelector(({ fuse }) => fuse.navbar);
	const navBarStateRef = useRef(navbar);
	const navRef = useRef(null);
	const childNavRef = useRef(null);
	const prevNavbar = usePrevious(navbar);
	const theme = useTheme();

	const classes = useStyles();

	const { folded } = config.navbar;
	const foldedAndClosed = folded && !navbar.foldedOpen;
	const foldedAndOpened = folded && navbar.foldedOpen;

	const isTouchScreen = useMemo(() => {
		return 'ontouchstart' in window;
	}, [window]);

	// Reference: http://dangkhoahome.blogspot.com/2013/05/kiem-tra-1-iem-co-nam-trong-giac-loi.html
	function pnpoly(nvert, vertx, verty, testx, testy) {
		let c = false;
		for (let i = 0, j = nvert - 1; i < nvert; j = i++) {
			if (
				verty[i] > testy !== verty[j] > testy &&
				testx < ((vertx[j] - vertx[i]) * (testy - verty[i])) / (verty[j] - verty[i]) + vertx[i]
			)
				c = !c;
		}
		return c;
	}

	function toggleNav() {
		childNavRef.current.style.width = `inherit`;
		childNavRef.current.style.minWidth = `inherit`;

		dispatch(Actions.navbarCloseFolded());
	}

	useEffect(() => {
		// In some screen, browser may freeze because of heavy render, it may lost event onMouseLeave on navigation
		// so navigation will not close although mouse had moved out navigation.
		// Solution: will check current mouse postion. If mouse has moved out the navigation, we will trigger close navigation.
		const closeNav = throttle(ev => {
			if (!navRef.current?.getBoundingClientRect || !navRef.current?.querySelector) return;

			const {
				top: navTop,
				bottom: navBottom,
				left: navLeft,
				right: navRight
			} = navRef.current?.getBoundingClientRect(); // Main nav
			const { top: subTop, bottom: subBottom, left: subLeft, right: subRight } = navRef.current
				?.querySelector(`#sub-nav-${navBarStateRef.current.subNavOpen}`)
				?.getBoundingClientRect() || { top: 0, bottom: 0, left: 0, right: 0 }; // Sub nav

			let mainPadWidth = 0;
			if (navBarStateRef.current.foldedOpen) mainPadWidth = navbarWidth;

			const isInMainNav = pnpoly(
				4,
				[navLeft, navRight + mainPadWidth, navRight + mainPadWidth, navLeft],
				[navTop, navTop, navBottom, navBottom],
				ev.pageX,
				ev.pageY
			);

			const inSubNav = pnpoly(
				4,
				[subLeft, subRight + navbarWidth, subRight + navbarWidth, subLeft],
				[subTop, subTop, subBottom, subBottom],
				ev.pageX,
				ev.pageY
			);

			if (!navBarStateRef.current.subNavOpen && !isInMainNav && navBarStateRef.current.foldedOpen) {
				window.requestAnimationFrame(toggleNav);
			}

			if (navBarStateRef.current.subNavOpen && !inSubNav && !isInMainNav && navBarStateRef.current.foldedOpen) {
				dispatch(Actions.navbarCloseSub());
				window.requestAnimationFrame(toggleNav);
			}
		}, 100);

		document.addEventListener('mousemove', closeNav);

		return () => {
			document.removeEventListener('mousemove', closeNav);
		};
	}, []);

	useEffect(() => {
		navBarStateRef.current = navbar;
	}, [navbar]);

	return (
		<>
			<ThemeProvider theme={navbarTheme}>
				<div id="fuse-navbar" ref={navRef} className={clsx(classes.wrapper, folded && classes.wrapperFolded)}>
					{/* <Hidden mdDown> */}
					<div
						ref={childNavRef}
						className={clsx(
							classes.navbar,
							classes[config.navbar.position],
							folded && classes.folded,
							foldedAndOpened && classes.foldedAndOpened,
							foldedAndClosed && classes.foldedAndClosed
						)}
						onMouseEnter={() => foldedAndClosed && dispatch(Actions.navbarOpenFolded())}
						onMouseLeave={() => foldedAndOpened && dispatch(Actions.navbarCloseFolded())}
						style={{
							backgroundColor: navbarTheme.palette.background.default,
							transitionDelay: prevNavbar?.subNavOpen
								? `${isTouchScreen ? 0 : theme.transitions.duration.standard}ms`
								: '0ms'
						}}
					>
						<NavbarLayout1 className={classes.navbarContent} />
					</div>
					{/* </Hidden> */}

					{/* <Hidden lgUp>
						<Drawer
							anchor={config.navbar.position}
							variant="temporary"
							open={navbar.mobileOpen}
							classes={{
								paper: classes.navbar
							}}
							onClose={() => dispatch(Actions.navbarCloseMobile())}
							ModalProps={{
								keepMounted: true // Better open performance on mobile.
							}}
						>
							<NavbarLayout1 className={classes.navbarContent} />
						</Drawer>
					</Hidden> */}
				</div>
			</ThemeProvider>

			{config.navbar.display && !config.toolbar.display && (
				<Hidden lgUp>
					<NavbarMobileToggleFab />
				</Hidden>
			)}
		</>
	);
}

export default NavbarWrapperLayout1;
