import * as PrintSigningSheetActions from '../actions/print.actions';

const defaultQuickPrintReport = {
	daaFunding: 0,
	facilityWard: '',
	firstName: '',
	lastName: '',
	packingStatus: 0,
	patientGroupId: '',
	patientGroupName: '',
	patientId: '',
	patientStatus: 0,
	photoId: '',
	room: '',
};

const initialState = {
	data: {
		patientGroupIds: [],
		wards: [],
		listPatientIds: [],
		printOrder: 0,
		fromDate: new Date(),
		reportTitle: '',
		isRemoveAdminDate: false,
		isPrintCareAdmin: false,
		isPrintWafarin: false,
		isPrintInsulin: false,
		printDate: new Date(),
		reportSettingId: '',
		reportCode: '',
	},
	submitting: false,
	error: null,
	message: '',
	fileName: '',
	fileUrl: '',
	quickPrintReport: defaultQuickPrintReport,
};

const print = (state = initialState, action) => {
  switch (action.type) {
    case PrintSigningSheetActions.PRINT_REPORT:
      return {
        ...state,
        submitting: true,
        error: null,
        message: '',
        fileName: '',
        fileUrl: '',
      };
    case PrintSigningSheetActions.PRINT_REPORT_SUCCESS:
      return {
        ...state,
        submitting: false,
        fileName: action.payload.data.fileName,
        fileUrl: action.payload.data.fileUrl,
      };
    case PrintSigningSheetActions.PRINT_REPORT_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload.error,
        message: action.payload.message,
      };
    case PrintSigningSheetActions.CHANGE_PRINT_REPORT_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };
    case PrintSigningSheetActions.RESET_PRINT_REPORT_FORM:
      return initialState;
    case PrintSigningSheetActions.SET_QUICK_PRINT_REPORT:
      return {
        ...state,
        data: { ...state.data, listPatientIds: [action.payload.data.patientId] },
        quickPrintReport: action.payload.data,
      };

    case PrintSigningSheetActions.CLEAR_HISTORY_FILE:
      return { ...state, fileName: null, fileUrl: null };
    case PrintSigningSheetActions.GET_HISTORY_OPTIONS:
      return {
        ...state,
        data: {
          ...state.data,
          isRemoveAdminDate: action.payload.data?.removeAdminDates,
          isPrintCareAdmin: action.payload.data?.includeOptionForCareWorker,
          isPrintInsulin: action.payload.data?.printInsulinOnRegularSheets,
          isPrintWafarin: action.payload.data?.printWarfarinOnRegularSheets,
        },
      };
    default: {
      return state;
    }
  }
};

export default print;
