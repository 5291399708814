import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  alertContent: {
    alignItems: 'center',
    '& a': {
      color: theme.palette.primary.main,
    },
  },
}));

export default function MessageSkeleton(props) {
  const { renderMessage, AlertProps } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (renderMessage) {
      setOpen(true);
    }
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  }, [renderMessage]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      TransitionComponent={Slide}
      open={open}
      onClose={handleClose}
    >
      <Alert
        classes={{ message: clsx(classes.alertContent) }}
        icon={<span />}
        severity="success"
        variant="standard"
        onClose={handleClose}
        {...AlertProps}
      >
        {renderMessage({ handleClose })}
      </Alert>
    </Snackbar>
  );
}
