import trim from 'lodash/trim';
import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

import { CUSTOM_OPTION_VALUE } from '../../constants';

export const CREATE_PHARMACY = '@PHARMACY/CREATE_PHARMACY';
export const CREATE_PHARMACY_SUCCESS = '@PHARMACY/CREATE_PHARMACY_SUCCESS';
export const CREATE_PHARMACY_FAIL = '@PHARMACY/CREATE_PHARMACY_FAIL';
export const CLEAR_CREATE_FORM = '@PHARMACY/CLEAR_CREATE_FORM';
export const CHANGE_PHARMACY_CREATE_FORM = '@PHARMACY/CHANGE_PHARMACY_CREATE_FORM';

export const createPharmacy = () => {
	return (dispatch, getState) => {
		const { data } = getState().pharmacy.create;
		dispatch({ type: CREATE_PHARMACY });
		return HttpService.post('/api/pharmacies', parseDataToModel(data))
			.then(res => {
				if (!res.isSuccess) throw res;
				dispatch({ type: CREATE_PHARMACY_SUCCESS, payload: { pharmacyId: res.data?.id || null } });
				dispatch(
					AppActions.showMessage({
						message: 'pharmacy:ADD_PHARMACY_SUCCESS',
						autoHideDuration: 4000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right'
						},
						variant: 'success'
					})
				);
			})
			.catch(err => {
				const { errorFields, errorMessages } = parseError(err);
				dispatch({ type: CREATE_PHARMACY_FAIL, payload: { message: errorMessages.join('\n'), error: errorFields } });
				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
			});
	};
};

export function clearCreateForm() {
	return dispatch => {
		dispatch({ type: CLEAR_CREATE_FORM });
	};
}

export function changePharmacyForm(data) {
	return dispatch => {
		dispatch({ type: CHANGE_PHARMACY_CREATE_FORM, payload: { data } });
	};
}

export function parseDataToModel(data) {
	return {
		pharmacyCode: trim(data.pharmacyCode),
		name: trim(data.name),
		phoneNumber: data.phoneNumber || [],
		fax: data.fax || [],
		email: data.email || [],
		pharmacyGuid: trim(data.pharmacyGuid),
		approvalNumber: trim(data.approvalNumber),
		street: trim(data.street),
		suburb: trim(data.suburb),
		state: trim(data.state),
		postalCode: trim(data.postalCode),
		isDoseAdd: data.isDoseAdd === 'true',
		timezone: data.timezone || '',
		isIntegrator: data.isIntegrator === 'true',
		integratorGuids: data.integratorGuids || [],
		logoId: data.logoId === CUSTOM_OPTION_VALUE ? null : data.logoId
	};
}
