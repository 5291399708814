import React from 'react';

import MessageSkeleton from './MessageSkeleton';

export default function AppBarMessage(props) {
  /** message schema
		{
			Message: string
			Type: number
		}
	*/
  const { message } = props;

  return <MessageSkeleton renderMessage={() => message.Message} />;
}
