import toString from 'lodash/toString';
import get from 'lodash/get';
import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';
import { parseDataToModel } from './create.actions';

export const GET_PHARMACY_DETAIL = '@PHARMACY/GET_PHARMACY_DETAIL';
export const GET_PHARMACY_DETAIL_SUCCESS = '@PHARMACY/GET_PHARMACY_DETAIL_SUCCESS';
export const GET_PHARMACY_DETAIL_FAIL = '@PHARMACY/GET_PHARMACY_DETAIL_FAIL';

export const UPDATE_PHARMACY = '@PHARMACY/UPDATE_PHARMACY';
export const UPDATE_PHARMACY_SUCCESS = '@PHARMACY/UPDATE_PHARMACY_SUCCESS';
export const UPDATE_PHARMACY_FAIL = '@PHARMACY/UPDATE_PHARMACY_FAIL';

export const DELETE_PHARMACY = '@PHARMACY/DELETE_PHARMACY';
export const DELETE_PHARMACY_SUCCESS = '@PHARMACY/DELETE_PHARMACY_SUCCESS';
export const DELETE_PHARMACY_FAIL = '@PHARMACY/DELETE_PHARMACY_FAIL';

export const RESEND_EMAIL_START = '@PHARMACY/RESEND_EMAIL_START';
export const RESEND_EMAIL_DONE = '@PHARMACY/RESEND_EMAIL_DONE';

export const CHANGE_PHARMACY_UPDATE_FORM = '@PHARMACY/CHANGE_PHARMACY_UPDATE_FORM';

export const CLEAR_UPDATE_DETAIL_FORM = '@PHARMACY/CLEAR_UPDATE_DETAIL_FORM';

export const getPharmacyDetail = (pharmacyId, callback) => {
	return (dispatch) => {
		dispatch({ type: GET_PHARMACY_DETAIL });
		HttpService.get(`api/pharmacies/${pharmacyId}`)
			.then((resp) => {
				dispatch({
					type: GET_PHARMACY_DETAIL_SUCCESS,
					payload: { data: parseModelToData(resp.data), id: resp.data.pharmacyId },
				});
			})
			.catch((err) => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch({
					type: GET_PHARMACY_DETAIL_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields },
				});
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages, { callback }));
			});
	};
};

export const updatePharmacy = (_id, _data) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_PHARMACY });
		HttpService.put(`api/pharmacies/${_id}`, parseDataToModel(_data))
			.then((resp) => {
				if (!resp.isSuccess) throw resp;
				dispatch(
					AppActions.showMessage({
						message: 'pharmacy:UPDATE_PHARMACY_SUCCESS',
						autoHideDuration: 4000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				);
				dispatch({ type: UPDATE_PHARMACY_SUCCESS, payload: { data: parseModelToData(resp.data) } });
			})
			.catch((err) => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch({
					type: UPDATE_PHARMACY_FAIL,
					payload: { message: errorMessages.join('\n'), error: errorFields },
				});
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

export function clearUpdateDetailForm() {
	return (dispatch) => {
		dispatch({ type: CLEAR_UPDATE_DETAIL_FORM });
	};
}

export function changePharmacyUpdateForm(data) {
	return (dispatch) => {
		dispatch({ type: CHANGE_PHARMACY_UPDATE_FORM, payload: { data } });
	};
}

export function parseModelToData(data) {
	return {
		pharmacyCode: data.pharmacyCode,
		name: data.name,
		phoneNumber: data.phoneNumber || [],
		fax: data.fax || [],
		email: data.email || [],
		pharmacyGuid: data.pharmacyGuid,
		approvalNumber: data.approvalNumber || '',
		street: data.street || get(data, 'address.street', null) || '',
		suburb: data.suburb || get(data, 'address.suburb', null) || '',
		state: data.state || get(data, 'address.state', null) || '',
		postalCode: data.postalCode || get(data, 'address.postalCode', null) || '',
		isDoseAdd: toString(data.isDoseAdd), // Parse to match with option value
		timezone: data.timezone || '',
		isIntegrator: toString(data.isIntegrator || false),
		integratorGuids: data.integratorGuids || [],
		logoId: data.logoId || null,
	};
}

export function resendInviteEmail(pharmacyId) {
	return (dispatch) => {
		dispatch({ type: RESEND_EMAIL_START });
		HttpService.get(`/api/pharmacies/invite/${pharmacyId}`)
			.then(() => {
				dispatch({ type: RESEND_EMAIL_DONE });
				dispatch(
					AppActions.showMessage({
						message: 'pharmacy:RESEND_INVITE_EMAIL_MESSAGE',
						autoHideDuration: 4000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				);
			})
			.catch((err) => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch({ type: RESEND_EMAIL_DONE });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
}

export const deletePharmacy = (pharmacyId) => {
	return (dispatch) => {
		dispatch({ type: DELETE_PHARMACY });
		return HttpService.delete(`/api/pharmacies/${pharmacyId}`)
			.then(() => {
				dispatch(
					AppActions.showMessage({
						message: 'pharmacy:DELETE_PHARMACY_SUCCESS',
					})
				);
				dispatch({ type: DELETE_PHARMACY_SUCCESS });
				return true;
			})
			.catch((err) => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch({ type: DELETE_PHARMACY_FAIL, payload: { message: errorMessages.join('\n') } });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};
