import * as DrugAction from '../actions/list.actions';

const defaultFilter = {
	textSearch: ''
};

const initialState = {
	error: null,
	message: 'No data to display',
	filter: defaultFilter
};

const list = (state = initialState, action) => {
	switch (action.type) {
		case DrugAction.REMOVE_DRUG: {
			return { ...state, loading: true };
		}
		case DrugAction.REMOVE_DRUG_SUCCESS: {
			return { ...state, loading: false };
		}
		case DrugAction.REMOVE_DRUG_FAIL: {
			return { ...state, loading: false, error: action.payload };
		}
		case DrugAction.SET_DRUG_FILTER: {
			return { ...state, filter: action.payload.filter };
		}
		case DrugAction.RESET_DRUG_FILTER: {
			return { ...state, filter: defaultFilter };
		}
		case DrugAction.SET_LIST_MESSAGE: {
			return { ...state, message: action.payload.message };
		}
		default: {
			return state;
		}
	}
};

export default list;
