import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';
import i18n from 'i18n';

export const CHECK_GET_DETAIL = '@JOB_CHECK/GET_DETAIL';
export const CHECK_GET_DETAIL_SUCCESS = '@JOB_CHECK/GET_DETAIL_SUCCESS';
export const CHECK_GET_DETAIL_FAIL = '@JOB_CHECK/GET_DETAIL_FAIL';

export const CHECK_GET_DOSE = '@JOB_CHECK/GET_DOSE';
export const CHECK_GET_DOSE_SUCCESS = '@JOB_CHECK/GET_DOSE_SUCCESS';
export const CHECK_GET_DOSE_FAIL = '@JOB_CHECK/GET_DOSE_FAIL';

export const CHECK_SHOW_PRESCRIPTION = '@JOB_CHECK/CHECK_SHOW_PRESCRIPTION';

export const CHECK_SEARCH_BY_APN = '@JOB_CHECK/CHECK_SEARCH_BY_APN';
export const CHECK_SEARCH_BY_APN_SUCCESS = '@JOB_CHECK/CHECK_SEARCH_BY_APN_SUCCESS';
export const CHECK_SEARCH_BY_APN_FAIL = '@JOB_CHECK/CHECK_SEARCH_BY_APN_FAIL';

export const CHECK_CONFIRM_MEDICATION = '@JOB_CHECK/CHECK_CONFIRM_MEDICATION';
export const CHECK_CONFIRM_MEDICATION_SUCCESS = '@JOB_CHECK/CHECK_CONFIRM_MEDICATION_SUCCESS';
export const CHECK_CONFIRM_MEDICATION_FAIL = '@JOB_CHECK/CHECK_CONFIRM_MEDICATION_FAIL';

export const CHECK_REJECT_MEDICATION = '@JOB_CHECK/CHECK_REJECT_MEDICATION';
export const CHECK_REJECT_MEDICATION_SUCCESS = '@JOB_CHECK/CHECK_REJECT_MEDICATION_SUCCESS';
export const CHECK_REJECT_MEDICATION_FAIL = '@JOB_CHECK/CHECK_REJECT_MEDICATION_FAIL';

export const CHECK_CONFIRM_PASS = '@JOB_CHECK/CHECK_CONFIRM_PASS';
export const CHECK_CONFIRM_PASS_SUCCESS = '@JOB_CHECK/CHECK_CONFIRM_PASS_SUCCESS';
export const CHECK_CONFIRM_PASS_FAIL = '@JOB_CHECK/CHECK_CONFIRM_PASS_FAIL';

export const CHECK_CORRECTION_REQUIRED = '@JOB_CHECK/CHECK_CORRECTION_REQUIRED';
export const CHECK_CORRECTION_REQUIRED_SUCCESS = '@JOB_CHECK/CHECK_CORRECTION_REQUIRED_SUCCESS';
export const CHECK_CORRECTION_REQUIRED_FAIL = '@JOB_CHECK/CHECK_CORRECTION_REQUIRED_FAIL';

export const CHECK_RESET_DATA = '@JOB_CHECK/CHECK_RESET_DATA';

export function getJob(jobId) {
	return dispatch => {
		dispatch({ type: CHECK_GET_DETAIL });
		HttpService.get(`/api/packjobs/${jobId}`)
			.then(resp => {
				dispatch({ type: CHECK_GET_DETAIL_SUCCESS, payload: { data: parseModelToData(resp.data) } });
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
				dispatch({ type: CHECK_GET_DETAIL_FAIL, payload: { message: errorMessages.join('\n') } });
			});
	};
}

export function getDoseAllocated(jobId) {
	return dispatch => {
		dispatch({ type: CHECK_GET_DOSE });
		HttpService.get(`/api/packjobs/${jobId}/checking`)
			.then(resp => {
				dispatch({ type: CHECK_GET_DOSE_SUCCESS, payload: { data: resp.data } });
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
				dispatch({ type: CHECK_GET_DOSE_FAIL, payload: { message: errorMessages.join('\n') } });
			});
	};
}

export function showPrescription(prescriptionId) {
	return dispatch => {
		dispatch({ type: CHECK_SHOW_PRESCRIPTION, payload: { prescriptionId } });
	};
}

export function searchByAPN(jobId, drugApn) {
	return dispatch => {
		dispatch({ type: CHECK_SEARCH_BY_APN });
		HttpService.get(`/api/packjobs/${jobId}/prescriptions/${drugApn}`)
			.then(resp => {
				dispatch({ type: CHECK_SEARCH_BY_APN_SUCCESS, payload: { data: resp.data } });
			})
			.catch(() => {
				dispatch(AppActions.createDialogMessage('error', 'Not found', 'job:SEARCH_APN_MESSAGE_NOT_FOUND'));
				dispatch({
					type: CHECK_SEARCH_BY_APN_FAIL,
					payload: { message: i18n.t('job:SEARCH_APN_MESSAGE_NOT_FOUND') }
				});
			});
	};
}

export function resetData() {
	return dispatch => {
		dispatch({ type: CHECK_RESET_DATA });
	};
}

export function rejectMedication(jobId, prescriptionId, data) {
	return dispatch => {
		dispatch({ type: CHECK_REJECT_MEDICATION });

		return HttpService.put(`/api/packjobs/${jobId}/checking-confirm/${prescriptionId}`, data)
			.then(() => {
				dispatch({ type: CHECK_REJECT_MEDICATION_SUCCESS, payload: { prescriptionId, data } });
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
				dispatch({ type: CHECK_REJECT_MEDICATION_FAIL, payload: { message: errorMessages.join('\n') } });
			});
	};
}

export function confirmPass(jobId) {
	return dispatch => {
		dispatch({ type: CHECK_CONFIRM_PASS });

		return HttpService.put(`/api/packjobs/${jobId}/complete-checking`)
			.then(() => {
				dispatch(AppActions.showMessage({ message: 'job:CONFIRM_PASSED_MESSAGE' }));
				dispatch({ type: CHECK_CONFIRM_PASS_SUCCESS });
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
				dispatch({ type: CHECK_CONFIRM_PASS_FAIL, payload: { message: errorMessages.join('\n') } });
			});
	};
}

export function correctionRequired(jobId) {
	return dispatch => {
		dispatch({ type: CHECK_CORRECTION_REQUIRED });

		return HttpService.put(`/api/packjobs/${jobId}/reject-checking`)
			.then(() => {
				dispatch({ type: CHECK_CORRECTION_REQUIRED_SUCCESS });
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
				dispatch({ type: CHECK_CORRECTION_REQUIRED_FAIL, payload: { message: errorMessages.join('\n') } });
			});
	};
}

export function parseModelToData(model) {
  return {
    jobId: model.jobId,
    jobNumber: model.jobNumber,
    patient: model.patient,
    patientGroup: model.patientGroup,
    numberOfWeek: model.numberOfWeek,
    packStartDate: model.packStartDate,
    prescriptions: model.prescriptions,
    checkChangePrescriptions: model.checkChangePrescriptions,
    headings: model.headings,
  };
}
