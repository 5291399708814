import * as PharmacyAction from '../actions/list.actions';

const defaultFilter = {
	textSearch: ''
};

const initialState = {
	error: null,
	message: 'LIST_EMPTY_MESSAGE',
	filter: defaultFilter,
	submitting: false
};

const list = (state = initialState, action) => {
	switch (action.type) {
		case PharmacyAction.SET_PHARMACIES_FILTER: {
			return { ...state, filter: action.payload.filter };
		}
		case PharmacyAction.RESET_PHARMACIES_FILTER: {
			return { ...state, filter: defaultFilter };
		}
		default: {
			return state;
		}
	}
};

export default list;
