const locale = {
  TAB_PRINT_FOIL: 'Print Foils',
  SECTION_SETTING: 'Settings',
  SECTION_PACK_START_DATE: 'Pack Start Date',
  SECTION_PRINT_ONLY: 'Print Only',
  PACK_START_DATE: 'Pack Start Date',
  PART_WEEK_PACK: 'Part Week Pack',
  WEEKS: 'Weeks',
  COLLATED_MULTIPLE_WEEKS_PACKS: 'Collated Multiple weeks packs',
  ADD_TO_DAA_VOLUME_DATA: 'Add to DAA volume data',
  BUTTON_OPTION: 'Options',
  BUTTON_PRINT: 'Print',
  BUTTON_CREATE_JOB: 'Create Pack',
  STANDARD: 'STND',
  SHORTCOURSE: 'Short Course',
  WARFARIN: 'Warfarin',
  SINEMET: 'Parkinson’s ',
  S8: 'S8',
  PRN: 'PRN',
  CYTOTOXIC: 'Cytotoxic',
  S4N: 'S4D',
  PRINT_DATE: 'Print Date',
  SEARCH_FOIL: 'Search...',
  LIST_HEADER_COL_FIRST_NAME: 'First Name',
  LIST_HEADER_COL_LAST_NAME: 'Last Name',
  LIST_HEADER_COL_FOIL: 'Pack',
  LIST_HEADER_COL_FREQUENCY: 'Frequency',
  LIST_HEADER_COL_ROOM: 'Room',
  LIST_HEADER_COL_NOTE: 'Note',
  LIST_HEADER_COL_PACK_TYPE: 'Pack Type',
  LIST_HEADER_COL_SELECT: 'Select',
  PATIENT_GROUP_NAME: 'Patient Group',
  GENERAL_SETTINGS_FOR_FOIL: 'General Settings for Foil',
  USE_SMALL_FONT: 'Use smaller font once max item per blister over',
  FOIL_SETTINGS: 'Foil Settings',
  DOSE_TIMES: 'Dose Times',
  BREAKFAST: 'Breakfast',
  LUNCH: 'Lunch',
  DINNER: 'Dinner',
  BEDTIME: 'Bedtime',
  SHOW_DATES_IN_WEEKLY_PPAK: 'Show dates in PPAK Weekly Multi-dose',
  FOIL_DRUG_NAME_LENGTH: 'Foil Drug Name Length (default 15)',
  ALPACA_FOIL_DRUG_DIR_LENGTH: 'Alpaca Foil Direction Length',
  PRINT_DOSE_TIME_HEADINGS: 'Print Dose Time Headings (mypak only)',
  PRINT_BARCODE_TRIFOLD: 'Print barcode on Trifold Label',
  PRINT_DRUG_WARNING: 'Print Drug Warning',
  PRINT_PACK_START_DATE: 'Print Pack Start Date',
  PRINT_OPTION_UPDATE_SUCCESS: 'Print option has been update successfully',
  PRINT_OPTION: 'Print Option',
  PRINT_PARTIAL_NOTE: 'Some drugs on this foil will not be printed.',
  SUSPENDED_NOTE: '{{firstName}} {{lastName}} IS SUSPENDED FROM {{startDate}} TO {{endDate}}',
  OTHERS: 'Others',
  NO_CONTENT_HEADER: 'No content',
  NO_CONTENT_MESSAGE: 'This foil have no content to print',
  FOIL_LABEL: 'Foil Label',
  SELECT_FOIL: 'Select Pack',
  SELECTED_FOIL: 'Selected Pack',
  LIST_HEADER_COL_WARD: 'Ward',
  FOIL_PACK_TYPE: 'Foil Pack Type',
  PRINT_PROFILE: 'Print profile',
  CLIENT: 'Client',
  PRINT_LABEL: 'Print Label',
  START_FROM_LABEL: 'Start from Label',
  MAX_FOIL_TITLE: 'Print Foil',
  MAX_FOIL_MESSAGE: 'Number of Foils should be less than {{ number }}',
  JOB_CREATE_SUCCESS: 'Job has been created successfully',
  INCLUDE_SUSPENDED_PATIENT: 'Include Suspended Patients',
  TOOLTIP_SUSPENDED_PATIENT: 'Suspended Patient',
  MEDICATION_SELECTION_MODAL_TITLE: '{{ title }} Medication Selection',
  MEDICATION_SELECTION_MODAL_IMAGE_PLACE_HOLDER: 'Patient Photo',
  MEDICATION_SELECTION_MODAL_PATIENT: 'Patient',
  MEDICATION_SELECTION_MODAL_GROUP: 'Group',
  MEDICATION_SELECTION_MODAL_FOIL: 'Foil',
  MEDICATION_SELECTION_MODAL_TABLE_PRINT: 'Print',
  MEDICATION_SELECTION_MODAL_TABLE_MEDICATION: 'Medication',
  MEDICATION_SELECTION_MODAL_TABLE_DIRECTION: 'Direction',
  MEDICATION_SELECTION_MODAL_MESSAGE_ERROR: 'No data to display',
  DRUG: 'Drug',
  DIRECTION: 'Directions',
  BFAST: "B'fast",
  BTIME: "B'time",
  FREQUENCY: 'Frequency',
  CATEGORY: 'Category',
  PACK_EXPIRY_DATE: 'Pack Expiry Date',
  PACK_FREQUENCY_LABEL: 'Selected Pack Frequencies',
  PACK_FREQUENCY_PLACEHOLDER: 'All Pack Frequencies',
};

export default locale;
