import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	separator: {
		width: 1,
		height: theme.spacing(7.5),
		backgroundColor: theme.palette.divider
	},
	title: {
		fontSize: 16,
		lineHeight: 1.2,
		paddingLeft: theme.spacing(6.25),
		fontWeight: 600,
		color: theme.palette.primary[800],
		cursor: 'default'
	},
	toolbar: {
		minHeight: theme.spacing(7.5),
		height: theme.spacing(7.5)
	}
}));

function ToolbarLayout1(props) {
	const nav = useSelector(({ fuse }) => fuse.settings.current.nav);
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const toolbarTheme = useSelector(({ fuse }) => fuse.settings.toolbarTheme);
	const classes = useStyles(props);
	const history = useHistory();

	return (
		<ThemeProvider theme={toolbarTheme}>
			<AppBar
				id="fuse-toolbar"
				className="flex relative z-10"
				color="default"
				style={{ backgroundColor: toolbarTheme.palette.background.default, borderBottom: 'solid 1px #ccc' }}
			>
				<Toolbar className={clsx('p-0', classes.toolbar)}>
					<div className="flex flex-1">
						<Typography variant="h5" className={clsx(classes.title)}>
							{nav?.title}
						</Typography>
					</div>

          <div className="flex items-center">
            <Icon className="cursor-pointer hidden" fontSize="large" onClick={() => history.push('/home')}>
              notifications
            </Icon>
            <UserMenu />
          </div>

					{config.navbar.display && config.navbar.position === 'right' && (
						<Hidden lgUp>
							<NavbarMobileToggleButton />
						</Hidden>
					)}
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default ToolbarLayout1;
