import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import MessageSkeleton from './MessageSkeleton';

const useStyles = makeStyles((theme) => ({
  content: {
    justifyContent: 'start',
    transition: 'all 1s',
    maxHeight: '100px',
    overflowY: 'hidden',
    '& .message': {
      maxHeight: '60px',
      overflowY: 'hidden',
    },
  },
  more: {
    maxHeight: '100%',
    '& .message': {
      maxHeight: '100%',
    },
  },
  buttonSeeMore: {
    color: theme.palette.primary.main,
    fontSize: '1.5rem',
    marginTop: theme.spacing(1),
  },
}));

export default function SyncMessage(props) {
  /** message schema
		{
			BatchId: string
			BatchNumber: string
			IsSuccess: boolean
			Message: string[]
			Type: number
		}
	*/
  const { message } = props;
  const classes = useStyles();
  const [more, setMore] = useState(false);

  const handleSeeMore = () => {
    setMore((v) => !v);
  };

  return (
    <MessageSkeleton
      renderMessage={() => (
        <>
          <div className="message">
            {JSON.parse(message?.Message)?.map((m, index) => (
              <p key={`message-${index}`}>{m}</p>
            ))}
          </div>
          {JSON.parse(message?.Message)?.length > 3 && (
            <button type="button" onClick={handleSeeMore} className={clsx(classes.buttonSeeMore, 'text-center')}>
              {more ? <FontAwesomeIcon icon="angle-double-up" /> : <FontAwesomeIcon icon="angle-double-down" />}
              &nbsp;See more
            </button>
          )}
        </>
      )}
      AlertProps={{ classes: { message: clsx(classes.content, more && classes.more) } }}
    />
  );
}
