import React from 'react';
import clsx from 'clsx';

function FusePageSimpleHeader(props) {
	return (
		// __fuse-header: easy to customize css
		<div className={clsx(props.classes.header, '__fuse-header')}>{props.header}</div>
	);
}

export default FusePageSimpleHeader;
